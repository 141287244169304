import React, { Fragment } from 'react';
import {
  FlexCell,
  BlankDealerBlock
} from '@packages/fdpq-common';

const BlankDealerList = () => {
  return (
    <Fragment>
      <FlexCell columnSpan={{ lg: 4, md: 4, sm: 12 }}>
        <BlankDealerBlock />
      </FlexCell>
      <FlexCell columnSpan={{ lg: 4, md: 4, sm: 12 }}>
        <BlankDealerBlock />
      </FlexCell>
      <FlexCell columnSpan={{ lg: 4, md: 4, sm: 12 }}>
        <BlankDealerBlock />
      </FlexCell>
    </Fragment>
  );
}

export default BlankDealerList;

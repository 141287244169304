/** @jsx jsx */
import { Fragment } from 'react';
import { jsx, css } from '@emotion/core';
import PropTypes from 'prop-types';
import styled from '@emotion/styled';
import { Helmet } from 'react-helmet';
import { VEHICLE_LISTING_TYPE } from '@packages/fdpq-core/constants/app';
import Heading from '@argo/ui-elements/Heading';
import Paragraph from '@argo/ui-elements/Paragraph';
import ContentBlock from '@argo/principles/ContentBlock';
import FlexContainer from '../layout/Container';
import { widgetSize } from '../constants/ui';
import { HEADER_TEXT_VALUES } from '../../fdpq-core/constants/app';

const StyledHeader = styled.div`
  border-radius: 4px 4px 0 0;
  width: 100%;
  ${({ theme, title }) => `
    background-color: ${title === HEADER_TEXT_VALUES.BLANK_HEADER ? theme.header.blankBackgroundColor
      : theme.header.backgroundColor};
    opacity: ${title === HEADER_TEXT_VALUES.BLANK_HEADER ? 0 : 1};
  `}
`;

const subHeadingStyling = css`
  margin-top: 10px;
  `;

const HeaderText = styled.div`
  color: #fff;
  font-weight: bold;
  ${({ hide }) => `
    display: ${hide ? 'none' : 'block'};;
  `}
  ${({ theme, title }) => `
    color: ${theme.header.color};
    text-transform: ${theme.header.textTransform};
    opacity: ${title === HEADER_TEXT_VALUES.BLANK_HEADER ? 0 : 1};
    ${(theme.widgetSize === widgetSize.SMALL)
      ? 'margin: 12px 0; font-size: 20px'
      : `margin: 18px 0; font-size: ${theme.header.fontSize}`
    }
  `}
`;

const HeaderSubText = styled.div`
  font-size: 16px;
  font-weight: 400;
  margin-top: 10px;
`;

const ArgoFdpqHeader = ({ title, subtitle, vehicleListingType }) => (
  <div>
    <Heading
      enableTitleCase={false}
      headingName="SectionHeading"
      headingTag="h2"
      textTransformation={true}
      id="header-text"
    >
      {title}
    </Heading>
    {(vehicleListingType === VEHICLE_LISTING_TYPE.TRADEIN && subtitle)
      ? <ContentBlock verticalSpacing="xs">
        <div>
          <Paragraph
            id="header-subtext"
          >
            {subtitle}
          </Paragraph>
        </div>
      </ContentBlock>
      : <Fragment />
    }
  </div>
);

const Header = ({ title, subtitle, hide, vehicleListingType, useArgoStyle = false }) => (
  <StyledHeader title={title} id="styled-header">
    <Helmet>
      <title>{title}</title>
      <meta name="description" content={`KBB ${title}`} />
    </Helmet>
    {useArgoStyle ? (
      <ArgoFdpqHeader title={title} subtitle={subtitle} vehicleListingType={vehicleListingType} />
    ) : (
        <FlexContainer>
          <HeaderText hide={hide} title={title} id="header-text">
            {title}
            {(vehicleListingType === VEHICLE_LISTING_TYPE.TRADEIN && subtitle)
              ? (<HeaderSubText id="header-subtext">{subtitle}</HeaderSubText>)
              : <Fragment />}
          </HeaderText>
        </FlexContainer>
      )}
  </StyledHeader>
);

Header.propTypes = {
  title: PropTypes.string,
};

Header.defaultProps = {
  title: '',
};

export default Header;

/* ***************************************
 * This Base State is served as default state for FDPQ App.
 * It should replace default state in all reducers.
 * ************************************** */
const initialState = {
  navigation: {
    currentPage: 'VEHICLE_SELECTOR',
  },
  app: {
    config: {
      origin: '',
      useCdn: true,
      hostPageName: '',
      contentConfig: {},
      listingTypeConfig: {
        vehicleListingType: '',
      },
    },
    theme: {},
    texts: {
      global: {
        headerText: 'Free Dealer Price Quote',
        description: {
          title: 'Get the best deal on a car!',
          text: 'Compare prices from three local dealers.',
        },
      },
      vehicleSelectorTradeInView: {},
      vehicleSelectorView: {},
      dealerSelectorView: {},
      contactFormView: {
        disclaimerText: 'By using this service, you accept the terms of our',
        linkText: 'Visitor&nbsp;Agreement',
      },
      thankYouPageView: {},
    },
    links: {
      agreementLink: '',
    },
    vehicleListingType: '',
  },
  vehicles: {
    selectedVehicle: {
      makeId: '',
      makeName: null,
      trimId: '',
      trimName: null,
      modelId: '',
      modelName: null
    },
    yearOptions: [],
    makeOptions: [],
    modelOptions: [],
    trimOptions: [],
    selectedTradeinVehicle: {
      yearId: '',
      yearName: null,
      makeId: '',
      makeName: null,
      trimId: '',
      trimName: null,
      modelId: '',
      modelName: null
    },
    tradeinYearOptions: [],
    tradeinMakeOptions: [],
    tradeinModelOptions: [],
    tradeinTrimOptions: [],
  },
  dealers: {
    selectedDealers: [],
    dealerList: [],
  },
  floodlightTagging: {},
  contact: {},
  pixall: {},
  location: {
    zipcode: '',
  },
  requests: {
    bycService: {
      getDealerList: {
        isLoading: false,
      },
      validateLead: {
        isLoading: false,
      },
      submitLead: {
        isLoading: false,
      },
    },
    researchableApiService: {
      getYears: {
        isLoading: false,
      },
      getMakes: {
        isLoading: false,
      },
      getModels: {
        isLoading: false,
      },
      getTrims: {
        isLoading: false,
      },
    },
  },
};

export default initialState;

/* eslint-disable no-use-before-define */
import { NAVIGATE_TO_PAGE } from '../action-types/navigation';
import { VEHICLE_SELECTOR_LOADED, TRADEIN_VEHICLE_SELECTOR_LOADED } from '../action-types/vehicles';
import { PAGE_NAME, VEHICLE_LISTING_TYPE, RESPONSE_TYPE } from '../constants/app';

const categories = {
  'new': 'ncpq',
  'used': 'ucpq',
  'cpo': 'cpopq',
  'tradein': 'tilp'
};

const buildVehicleObject = (storeState, hasImpression = false) => {
  let vehicleBody = {
    catalog: '',
    makeId: '',
    makeName: '',
    modelId: '',
    modelName: '',
    trimId: '',
    trimName: '',
    type: ''
  };

  if (storeState.vehicles && storeState.vehicles.selectedVehicle) {
    const selectedVehicle = storeState.vehicles.selectedVehicle;
    const listingType = storeState.app.config.listingTypeConfig.vehicleListingType;
    
    vehicleBody = hasImpression ? {
      makeName: selectedVehicle.makeName ? selectedVehicle.makeName : '',
      modelName: selectedVehicle.modelName ? selectedVehicle.modelName : '',
      trimName: selectedVehicle.trimName ? selectedVehicle.trimName : '',
    } : {
      catalog: 'kbb', 
      makeId: selectedVehicle.makeId ? String(selectedVehicle.makeId) : '',
      makeName: selectedVehicle.makeName ? selectedVehicle.makeName : '',
      modelId: selectedVehicle.modelId ? String(selectedVehicle.modelId) : '',
      modelName: selectedVehicle.modelName ? selectedVehicle.modelName : '',
      trimId: selectedVehicle.makeId ? String(selectedVehicle.makeId) : '',
      trimName: selectedVehicle.trimName ? selectedVehicle.trimName : '',
      type: listingType ? listingType : '', 
    };
  }
  return vehicleBody;
};

const getCategory = (storeState) => {
  const listingType = storeState.app.config.listingTypeConfig.vehicleListingType;
  return categories[listingType] ? categories[listingType] : '';
};

const buildEventObject = (payload) => {
  const obj = {
      event: payload.event ? payload.event : '',
      eventElement: payload.category ? payload.category : '',
      eventAction: payload.action ? payload.action : '',
      eventResult: payload.label ? payload.label : '',
      eventNonInteractive: payload.nonInteractive ? payload.nonInteractive : false,
      eventData: payload.customData ? payload.customData : {}
  };

  return obj;
};

const buildPostMessageObject = (eventObject) => {
  const postMessageObject = {
    type: 'csAnalyticsEvent',
    event: {
      type: eventObject.eventAction ? eventObject.eventAction : '',
      googleAnalytics: {
        props: eventObject
      }
    }
  };
  return postMessageObject;
}

export const middleWare = store => next => (action) => {
  const { navigation: { currentPage }, errors, app, app: { config: { listingTypeConfig: { vehicleListingType }, appType } } } = store.getState();
  const { type, data } = action;

  if (currentPage === PAGE_NAME.CONTACT_FORM && type == "SUBMIT_LEAD_SUCCESS") {
    // GA EVENT 3
    let payload = {
      event: 'analytics_event',
      category: getCategory(store.getState()),
      action: 'click',
      label: 'cta::send',
      nonInteractive: false,
      customData: {
        vehicle: buildVehicleObject(store.getState())
      }
    };

    let eventObj = buildEventObject(payload);
    window.dataLayer.push(eventObj);

    let postMessageObj = buildPostMessageObject(eventObj);
    window.parent.postMessage(JSON.stringify(postMessageObj), "*");

  }

  next(action);

  if (type === NAVIGATE_TO_PAGE) {
    if (currentPage === PAGE_NAME.VEHICLE_SELECTOR && data.pageName === PAGE_NAME.DEALER_SELECTOR) {
      // GA EVENT 1
      let payload = {
        event: 'analytics_event',
        category: getCategory(store.getState()),
        action: 'click',
        label: 'cta::find-dealers',
        nonInteractive: false,
        customData: {
          vehicle: buildVehicleObject(store.getState())
        }
      };

      let eventObj = buildEventObject(payload);
      window.dataLayer.push(eventObj);

      let postMessageObj = buildPostMessageObject(eventObj);
      window.parent.postMessage(JSON.stringify(postMessageObj), "*");
    }
    
    if (data.pageName === PAGE_NAME.CONTACT_FORM) {
      // GA EVENT 2
      let payload = {
        event: 'analytics_event',
        category: getCategory(store.getState()),
        action: 'click',
        label: 'cta::get-my-free-quotes',
        nonInteractive: false,
        customData: {
          vehicle: buildVehicleObject(store.getState())
        }
      };

      let eventObj = buildEventObject(payload);
      window.dataLayer.push(eventObj);

      let postMessageObj = buildPostMessageObject(eventObj);
      window.parent.postMessage(JSON.stringify(postMessageObj), "*");
    }

    if (currentPage === PAGE_NAME.CONTACT_FORM && data.pageName === PAGE_NAME.THANK_YOU_PAGE) {
      // GA EVENT 4
      const hasImpression = true;
      let category = getCategory(store.getState());
      let payload = {
        event: 'analytics_event',
        category: category,
        action: 'impression',
        label: 'lead::' + category,
        nonInteractive: true,
        customData: {
          vehicle: buildVehicleObject(store.getState(), hasImpression)
        }
      };

      let eventObj = buildEventObject(payload);
      window.dataLayer.push(eventObj);

      let postMessageObj = buildPostMessageObject(eventObj);
      window.parent.postMessage(JSON.stringify(postMessageObj), "*");
    }
  }
  if (type === 'CLICK_CHANGE_VEHICLE_LINK') {
    // GA EVENT 5
    let payload = {
      event: 'analytics_event',
      category: getCategory(store.getState()),
      action: 'click',
      label: 'text-link::change-vehicle',
      nonInteractive: false
    };

    let eventObj = buildEventObject(payload);
    window.dataLayer.push(eventObj);

    let postMessageObj = buildPostMessageObject(eventObj);
    window.parent.postMessage(JSON.stringify(postMessageObj), "*");
  }
};

const googleMiddleware = {
  middleWare,
};

export default googleMiddleware;

import { ZIPCODE_CHANGE } from '../../action-types/location';

const location = (state = {}, action) => {  
	switch (action.type) {
		case ZIPCODE_CHANGE:
			return {
				...state,
				...{ zipcode: action.data.zipcode }
			}
		default:
		 	return state;
  }
};

export default location;

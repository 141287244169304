/* eslint-disable no-undef */
import floodlightConfig from './floodlightConfig.json';

export const isQualified = (array, value) => {
  const found = array.find(element => {
    return element === '*' || element.toLowerCase() === value.toLowerCase();
  });
  return !!found;
}

export const fireFloodLightTag = () => (dispatch, getState) => {
  const { yearId, makeName, modelName, trimName } = getState().vehicles.selectedVehicle;
  const { navigation: { currentPage }, app: { config: { appType, listingTypeConfig: { vehicleListingType } } } } = getState();
  const psid = getState().app.config.queryParams?.psid || '';  
  const config = floodlightConfig;
  const colorName = "";
  const priceTotal = "";
  const psidWhitelist = ['atmt1002', 'mt080', 'mt1082', 'mt1083', 'mt988', 'mt913', 'mt914', 'mt871', 'mt870'];
  const validPsid = psidWhitelist.findIndex(element => {
    return element.toLowerCase() === psid.toLowerCase();
  });
  config.HOST_WEBSITES.forEach((rule) => {
    if (
      appType.toLowerCase().indexOf(rule.HOST_WEBSITE) > -1 &&
      isQualified(rule.VEHICLE_LISTING_TYPE, vehicleListingType) &&
      isQualified(rule.PAGE_NAME, currentPage) &&
      isQualified(rule.YEAR, yearId) &&
      isQualified(rule.MAKE, makeName) &&
      isQualified(rule.MODEL, modelName) &&
      isQualified(rule.TRIM, trimName)
    ) {
      const floodlightTaggingConfig = config.FLOODLIGHT_TAGGING.find(cf => cf.MAKE.toLowerCase() === makeName.toLowerCase());
      if (floodlightTaggingConfig) {
        loadGlobalSrcScript(floodlightTaggingConfig.ID).then(_ => {
          loadGlobalGtagScript(floodlightTaggingConfig.ID);
          const floodlightPage = floodlightTaggingConfig.SEND_TO_TEMPLATES.find(template => template.PAGE_NAME.toLowerCase() === currentPage.toLowerCase());
          if (floodlightPage) {
            const payLoad = { ...floodlightPage.PAY_LOAD };
            Object.keys(payLoad).forEach(key => {
              switch (payLoad[key]) {
                case "{YEAR}":
                  payLoad[key] = yearId;
                  break;
                case "{MODEL}":
                  payLoad[key] = modelName;
                  break;
                case "{TRIM}":
                  payLoad[key] = trimName;
                  break;
                case "{COLOR}":
                  payLoad[key] = colorName;
                  break;
                case "{PRICE}":
                  payLoad[key] = priceTotal;
                  break;
                case "{PSID}":
                    payLoad[key] = validPsid > -1 ? psid: '';
                  break;
                default:
                  break;
              }
            })
            gtag('event', 'conversion', payLoad);
          }
        });
      }
    }
  })
};

const loadGlobalGtagScript = id => {
  if (id) {
    const className = `gtagFunctionScript-${id}`;
    if (document.getElementsByClassName(className).length == 0) {
      const script = document.createElement('script');
      script.className = className;
      let gtagText = `window.dataLayer = window.dataLayer || []; function gtag(){dataLayer.push(arguments); console.log('DEBUGGER: Floodlight tag is fired');} gtag('js', new Date()); gtag('config', '${id}');`;
      script.appendChild(document.createTextNode(gtagText));
      document.body.appendChild(script);
    }
  }
};

const loadGlobalSrcScript = async id => {
  return new Promise((resolve, reject) => {
    if (id) {
      const className = `gtmSrc-${id}`;

      if (document.getElementsByClassName(className).length == 0) {
        const script = document.createElement('script');
        script.src = `https://www.googletagmanager.com/gtag/js?id=${id}`;
        script.className = className
        script.async = true;

        script.addEventListener('load', () => {
          resolve(true);
        });
        script.addEventListener('error', () => {
          reject(new Error(`${this.src} failed to load.`))
        });
        document.body.appendChild(script);
      } else {
        resolve(true);
      }
    } else {
      resolve(true);
    }
  });
};
export default fireFloodLightTag;


const fastDeepCompare = (objA, objB) => {
  return JSON.stringify(objA) === JSON.stringify(objB);
}

const useDealerValidation = (displayItems, selectedItems) => {
  let displaySelectedItems = [];
  if(displayItems && displayItems.length > 0) {
    displaySelectedItems = displayItems.map((displayItem) => {
      for(let key in selectedItems) {
        if(fastDeepCompare(displayItem, selectedItems[key])) {
          return {
            displayItem,
            isSelected: true
          }
        }
      }
      return {
        displayItem,
        isSelected: false
      };
    });
  }
  return displaySelectedItems;
}

export default useDealerValidation;

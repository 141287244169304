import { useMemo, useRef } from 'react';
import axios from 'axios';
import { css } from '@emotion/core';
import styled from '@emotion/styled';
import FlexBox from '@argo/principles/FlexBox';
import Button from '@argo/ui-elements/Button';
import { InnerLabelInputField } from '@argo/ui-elements/InputField';
import Heading from '@argo/ui-elements/Heading';
import Paragraph from '@argo/ui-elements/Paragraph';
import Link from '@argo/ui-elements/Link';
import { LoadingModal, VehicleInfoArgo } from '@packages/fdpq-common';
import { focusState } from '@argo/utils/styleUtils';
import ContentBlock from '@argo/principles/ContentBlock';
import { useAdaptiveRender } from '@argo/utils';
import Breakpoints from '@argo/principles/constants/breakpoints';
import Size from '@argo/principles/constants/size';
import colors from '@argo/principles/colors';
import { withTheme } from 'emotion-theming';
import { withFormik } from 'formik';
import { connect } from 'react-redux';
import { VEHICLE_LISTING_TYPE } from '@packages/fdpq-core/constants/app';
import { text, links } from '../../fdpq-core/config/vars';
import { getUrlParameter } from '../../fdpq-widget/utils/helperUtil';
import { mapStateToProps, mapDispatchToProps, mapValidationToProps } from '../containers/ContactForm';
import ReCAPTCHA from 'react-google-recaptcha';
import { RECAPTCHA_V2_CLIENT_SITE_KEY, RECAPTCHA_V3_CLIENT_SITE_KEY } from '../../fdpq-widget/utils/constants';

const ContactFormArgo = ({
  // formik props
  errors,
  handleChange,
  handleSubmit,
  touched,
  values,
  theme,
  // regular props
  onButtonPress,
  firstNameError,
  lastNameError,
  emailError,
  phoneError,
  listingTypeConfig,
  disclaimerLink,
}) => {
  const defaultStyles = css`
    ${focusState}
  `;

  const AlignedComponent = styled.div`
    ${({ align }) => `
      text-align: ${align};
      align-items: ${align};
    `}
  `;

  const Disclaimer = styled.div`
    color: ${colors.primary.charcoal};
  `;

  const loadingModalStyle = css`
    margin-top: -${Size.micro}px;
  `;

  const loadingModalSmallStyle = css`
    margin-top: -${Size.xs}px;
  `;

  const flexBoxDefault = [
    '0 0 100%',
    '0 0 100%',
    '0 0 100%',
    '0 0 100%',
    '0 0 100%',
    '0 0 100%',
    '0 0 50%',
    '0 0 50%',
    '0 0 100%',
  ];

  const flexBoxMobileTablet = [
    '0 0 100%',
    '0 0 100%',
    '0 0 100%',
    '0 0 100%',
    '0 0 100%',
    '0 0 100%',
    '0 0 50%',
    '0 0 50%',
    '0 0 100%',
  ];

  const flexBoxDesktop = [
    '0 0 100%',
    '0 0 100%',
    '0 0 100%',
    '0 0 50%',
    '0 0 50%',
    '0 0 50%',
    '0 0 25%',
    '0 0 25%',
    '0 0 100%',
  ];

  const { width } = useAdaptiveRender();
  const isSmallOrMediumViewPort = width < Breakpoints.lg;
  const isSmallViewPort = width < Breakpoints.md;
  const isTradeIn = listingTypeConfig.vehicleListingType === VEHICLE_LISTING_TYPE.TRADEIN;
  const pagename = getUrlParameter('pagename') || '';
  const recaptchaRef = useRef();

  async function isRecaptchaChecked() {
    if (pagename === 'fdpqselect') {
      // reCAPTCHA v3: the `grecaptcha` variable is loaded by the script tag in www/packages/fdpq-widget/components/FdpqWidgetArgo.js
      grecaptcha.ready(async function() {
        // get reCAPTCHA v3 token
        const v3Token = await grecaptcha.execute(RECAPTCHA_V3_CLIENT_SITE_KEY, { action: 'submit' });
      });

      // reCAPTCHA v2
      const v2Token = await recaptchaRef.current.executeAsync();
      return v2Token ? true : false;
    }
    return true;
  }

  function showRecaptchaError() {
    document.getElementById('reCaptchaError').style.display = 'block';
  }

  const reCaptcha = useMemo(
    () => (
      <AlignedComponent align="right">
        <ReCAPTCHA
          badge="inline"
          id="reCAPTCHA"
          ref={recaptchaRef}
          sitekey={RECAPTCHA_V2_CLIENT_SITE_KEY}
          size="invisible"
          onChange={() => {
            document.getElementById('reCaptchaError').style.display = 'none';
          }}
        />
        <div id="reCaptchaError" style={{ display: 'none', color: 'red' }}>
          reCAPTCHA verification is required
        </div>
      </AlignedComponent>
    ),
    []
  );

  function handleFormSubmit(event) {
    onButtonPress();
    isRecaptchaChecked() ? handleSubmit(event) : showRecaptchaError();
  }

  return (
    <FlexBox
      alignItems="flex-start"
      default={flexBoxDefault}
      xs={flexBoxMobileTablet}
      sm={flexBoxMobileTablet}
      md={flexBoxMobileTablet}
      lg={flexBoxDesktop}
      xl={flexBoxDesktop}
      max={flexBoxDesktop}
      flexWrap="wrap"
      horizontalSpacing={isSmallOrMediumViewPort ? 'sm' : 'md'}
      id="test-contact-form"
    >
      <LoadingModal
        loadingSubscriptions={['validateLead', 'submitLead']}
        useArgoStyle={true}
        loadingModalStyle={isSmallOrMediumViewPort ? loadingModalSmallStyle : loadingModalStyle}
      />
      <FlexBox
        alignItems="flex-start"
        horizontalSpacing="md"
        verticalSpacing="xs"
        flexWrap="wrap"
        default={['1 0 50%']}
        sm={['1 0 100%', '1 0 100%']}
        xs={['1 0 100%', '1 0 100%']}
      >
        <AlignedComponent align="left">
          <VehicleInfoArgo id="test-voi-vehicleinfo" heading={isTradeIn ? 'VOI' : ''} />
        </AlignedComponent>
        {isTradeIn ? (
          <AlignedComponent align={isSmallViewPort ? 'left' : 'right'}>
            <VehicleInfoArgo id="test-tradein-vehicleinfo" heading="TRADEIN" />
          </AlignedComponent>
        ) : (
          ''
        )}
      </FlexBox>
      <ContentBlock verticalSpacing="xs">
        <div>
          <Heading
            headingName="ContentHeadingExtra"
            headingTag="h2"
            textTransformation={true}
            enableTitleCase={false}
            id="description-title"
          >
            {text.global.contactFormViewDescription.titleText}
          </Heading>
        </div>
        <div>
          <Paragraph defaultMargin={true} textAlign="left" type="body" id="description-subtitle">
            {text.global.contactFormViewDescription.subtitleText}
          </Paragraph>
        </div>
      </ContentBlock>
      <div>
        <InnerLabelInputField
          disable={false}
          data-input-type="Default"
          error={(touched.firstName && errors.firstName) || firstNameError}
          id="firstName"
          labelText="First Name"
          mask={null}
          maskPlaceholder=""
          maskType={null}
          maxLength={500}
          type="text"
          placeholder=" "
          requiredText="*This text is required"
          validationType={null}
          width="auto"
          name="firstName"
          value={values.firstName}
          css={defaultStyles}
          onChange={handleChange}
        />
      </div>
      <div>
        <InnerLabelInputField
          disable={false}
          data-input-type="Default"
          error={(touched.lastName && errors.lastName) || lastNameError}
          id="lastName"
          labelText="Last Name"
          mask={null}
          maskPlaceholder=""
          maskType={null}
          maxLength={500}
          type="text"
          placeholder=" "
          requiredText="*This text is required"
          validationType={null}
          width="auto"
          name="lastName"
          value={values.lastName}
          css={defaultStyles}
          onChange={handleChange}
        />
      </div>
      <div>
        <InnerLabelInputField
          disable={false}
          data-input-type="Email"
          error={(touched.email && errors.email) || emailError}
          id="email"
          labelText="Email"
          mask={null}
          maskPlaceholder=""
          maskType={null}
          maxLength={500}
          type="email"
          placeholder=" "
          requiredText="*This text is required"
          validationType={null}
          width="auto"
          name="email"
          value={values.email}
          css={defaultStyles}
          onChange={handleChange}
        />
      </div>
      <div>
        <InnerLabelInputField
          disable={false}
          data-input-type="PhoneNumber"
          error={(touched.phoneNumber && errors.phoneNumber) || phoneError}
          id="phoneNumber"
          labelText="Phone"
          mask="0000000000"
          maskPlaceholder=""
          maskType={null}
          placeholder=" "
          requiredText="*This text is required"
          validationType={null}
          width="auto"
          name="phoneNumber"
          value={values.phoneNumber}
          css={defaultStyles}
          onChange={handleChange}
        />
      </div>
      <div>
        <Button
          name={'Step3Button'}
          id={'Step3Button'}
          buttonType="primary"
          onClick={handleFormSubmit}
          text={'Send'}
          width="100%"
          minimizeSize={false}
          disabled={false}
          href="#"
          target="_self"
          enableCapitalize={true}
          sizeType="regular"
        />
      </div>
      {pagename === 'fdpqselect' ? reCaptcha : ''}
      <Disclaimer>
        <Link href={disclaimerLink} target="_blank" type="small">
          {theme.contactFormView.disclaimer.linkText}
        </Link>
      </Disclaimer>
    </FlexBox>
  );
};

const ContactFormWithValidation = withFormik(mapValidationToProps)(withTheme(ContactFormArgo));
export default connect(mapStateToProps, mapDispatchToProps)(ContactFormWithValidation);

import { bindClientFunc } from './helpers';
import BycService from '@packages/fdpq-core/services/BycService';
import LscService from '@packages/fdpq-core/services/LscService';
import {
  GET_DEALER_LIST_REQUEST,
  GET_DEALER_LIST_SUCCESS,
  GET_DEALER_LIST_FAILURE,
  VALIDATE_LEAD_REQUEST,
  VALIDATE_LEAD_SUCCESS,
  VALIDATE_LEAD_FAILURE,
  SUBMIT_LEAD_REQUEST,
  SUBMIT_LEAD_SUCCESS,
  SUBMIT_LEAD_FAILURE,
  UPDATE_DEALER_LIST,
  GET_MAKE_LIST_REQUEST,
  GET_MAKE_LIST_FAILURE
} from '../action-types/dealers';
import { UPDATE_MAKE_FOR_FRANCHISE_OPTIONS } from '../action-types/vehicles';

/**
 *
 * @param {
 *  vehicleMake: String,
 *  vehicleModel: String,
 *  vehicleTrim: String,
 *  vehicleYear: String,
 *  zipCode: String
 *  vehicleListingType: String
 * } arg
 */
export const getDealerList = (arg) => bindClientFunc({
  clientFunc: BycService.getDealerList,
  onRequest: GET_DEALER_LIST_REQUEST,
  onSuccess: [UPDATE_DEALER_LIST, GET_DEALER_LIST_SUCCESS],
  onFailure: GET_DEALER_LIST_FAILURE,
  params: [arg],
});

export const getMakeList = (arg) => bindClientFunc({
  clientFunc: LscService.getMakeList,
  onRequest: GET_MAKE_LIST_REQUEST,
  onSuccess: [UPDATE_MAKE_FOR_FRANCHISE_OPTIONS],
  onFailure: GET_MAKE_LIST_FAILURE,
  params: [arg],
});

/**
 *
 * @param {
 *  firstName: String,
 *  lastName: String,
 *  email: String,
 *  phone: String,
 * } arg
 */
export const validateLead = (arg) => bindClientFunc({
  clientFunc: BycService.validateLead,
  onRequest: VALIDATE_LEAD_REQUEST,
  onSuccess: [VALIDATE_LEAD_SUCCESS],
  onFailure: VALIDATE_LEAD_FAILURE,
  params: [arg],
});

/**
 *
 * @param { FdpqLead } arg
 */
export const submitLead = (arg, coxAutoCorrelationId) => bindClientFunc({
  clientFunc: BycService.submitLead,
  onRequest: SUBMIT_LEAD_REQUEST,
  onSuccess: [SUBMIT_LEAD_SUCCESS],
  onFailure: SUBMIT_LEAD_FAILURE,
  params: [arg, coxAutoCorrelationId],
});

const Spacing = {
  Base: '8px',
  Medium: '16px',
  Large: '24px',
  Close: '16px',
  Separate: '40px',
  sidebarWidth: '324px', // 300px + Space.Large
};

export default Spacing;

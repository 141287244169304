/** @jsx jsx */
import { Fragment } from 'react';
import { jsx } from '@emotion/core';
import styled from '@emotion/styled';
import isDealerDetailsPage from '@packages/fdpq-core/utils/page';
import { widgetSize } from '../constants/ui';

const Description = styled.div`
  width: 100%;
`;

const DescriptionTitle = styled.div`
  ${({ isError, theme }) => `
    color: ${isError ? theme.box.errorGlowingColor : theme.description.title.color};
    line-height: 14px;
    font-weight: 900;
    font-size: ${(theme.widgetSize === widgetSize.SMALL) ? '14px' : theme.description.title.fontSize};
    margin-bottom: ${(theme.widgetSize === widgetSize.SMALL) ? '10px' : '13px'};
  `}
`;

const DescriptionSubtitle = styled.div`
  ${({ isError, theme }) => `
    color: ${isError ? theme.box.errorGlowingColor : theme.description.subtitle.color};
    line-height: 14px;
    font-size: ${(theme.widgetSize === widgetSize.SMALL) ? '12px' : '14px'};
    margin-bottom: ${(theme.widgetSize === widgetSize.SMALL) ? '5px' : '10px'};
  `}
`;

export default ({ title, subtitle, error }) => (
  <Fragment>
    <Description>
      <DescriptionTitle isError={error} id="description-title">{title}</DescriptionTitle>
      {
        (subtitle !== undefined && !isDealerDetailsPage)
          ? <DescriptionSubtitle isError={error} id="description-subtitle">{subtitle}</DescriptionSubtitle>
          : <Fragment />
      }
    </Description>
  </Fragment>
);

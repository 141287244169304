import { PAGE_NAME } from '@packages/fdpq-core/constants/app';
import { fromChangeVehicle } from '@packages/fdpq-core/actions/vehicles';
import { preselectDealers, clearSelectedDealers } from './dealers';
import { removeErrors } from './errors';
import {
  EMPTY_DEALERLIST,
  MORE_THAN_3_DEALER_SELECTED,
  NO_DEALER_SELECTED,
} from '../constants/errorCodes';
import {
  NAVIGATE_TO_PAGE,
  CLICK_CHANGE_VEHICLE_LINK,
  CLICK_CHANGE_TRADEIN_VEHICLE_LINK,
  CLICK_CHANGE_ZIPCODE_LINK,
} from '../action-types/navigation';

export const toTradeInVehicleSelector = () => (dispatch) => {
  dispatch(clearSelectedDealers());
  dispatch({
    type: NAVIGATE_TO_PAGE,
    data: {
      pageName: PAGE_NAME.TRADEIN_VEHICLE_SELECTOR,
    },
  });
  dispatch(fromChangeVehicle());
};

export const toVehicleSelector = () => (dispatch) => {
  dispatch(clearSelectedDealers());
  dispatch({
    type: NAVIGATE_TO_PAGE,
    data: {
      pageName: PAGE_NAME.VEHICLE_SELECTOR,
    },
  });
  dispatch(fromChangeVehicle());
};

export const toDealerSelector = () => (dispatch) => {
  try {
    dispatch(removeErrors([MORE_THAN_3_DEALER_SELECTED, NO_DEALER_SELECTED]));
    dispatch(preselectDealers());
    dispatch({
      type: NAVIGATE_TO_PAGE,
      data: {
        pageName: PAGE_NAME.DEALER_SELECTOR,
      },
    });
    // Need to dispatch this error removal separately so that we can detect that page
    // navigation was successful from error state for omniture tagging
    dispatch(removeErrors([EMPTY_DEALERLIST]));
  } catch (e) {
    console.error(e); // eslint-disable-line no-console
  }
};

export const toContactForm = () => ({
  type: NAVIGATE_TO_PAGE,
  data: {
    pageName: PAGE_NAME.CONTACT_FORM,
  },
});

export const toThankYouPage = () => ({
  type: NAVIGATE_TO_PAGE,
  data: {
    pageName: PAGE_NAME.THANK_YOU_PAGE,
  },
});

export const clickChangeTradeInVehicle = () => (dispatch) => {
  dispatch({
    type: CLICK_CHANGE_TRADEIN_VEHICLE_LINK,
  });
};

export const clickChangeVehicle = () => (dispatch) => {
  dispatch({
    type: CLICK_CHANGE_VEHICLE_LINK,
  });
};

export const clickChangeZip = () => (dispatch) => {
  dispatch({
    type: CLICK_CHANGE_ZIPCODE_LINK,
  });
};

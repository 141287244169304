import { combineReducers } from 'redux';

import app from './app';
import navigation from './navigation';
import requests from './requests';
import errors from './errors';

import vehicles from './entities/vehicles';
import dealers from './entities/dealers';
import contact from './entities/contact';
import location from './entities/location';
import pixall from './entities/pixall';

const floodlightTagging = (state = {}) => state;

export default combineReducers({
  app,
  navigation,
  vehicles,
  dealers,
  contact,
  location,
  requests,
  pixall,
  floodlightTagging,
  errors
});

import React from 'react';
import PropTypes from 'prop-types';
import styled from '@emotion/styled';
import Grid from './grid';
import Spacing from './spacing';
import { widgetSize } from '../constants/ui';

const getColumnSpan = (columnSpan, wSize) => {
  switch (wSize) {
    case widgetSize.SMALL:
      return columnSpan.sm;
    case widgetSize.MEDIUM:
      return columnSpan.md;
    case widgetSize.LARGE:
      return columnSpan.lg;
    default:
      return 1;
  }
}

const StyledCell = styled('div')`
  ${({ columnSpan, theme }) => {
    return columnSpan ? Grid.span({ base: getColumnSpan(columnSpan, theme.widgetSize) }) : '';
  }}

  ${props => (props.fullWidth ? Grid.fullWidth : null)}

  ${props => (props.textAlign ? `text-align: ${props.textAlign};` : null)}

  @media only screen and (-ms-high-contrast: none) {
    flex-grow: 1;
    margin-bottom: ${Spacing.Close};
  }

  > p:first-of-type,
    ul:first-of-type {
    margin-top: 0;
  }

  > p:last-of-type,
    ul:last-of-type {
    margin-bottom: 0;
  }
`;

/** //Cell Component
 * import { Layout } from '@kbbcomponentlibrary/principles';
 * const { Cell } = Layout;
 * */
const Cell = props => <StyledCell {...props}/>;

Cell.displayName = 'Cell';

Cell.defaultProps = {
  columnSpan: {},
  fullWidth: false,
  textAlign: 'left',
};

Cell.propTypes = {
  /** content */
  children: PropTypes.node,
  /** how many columns spanned on viewports. sm = 564, md = 768, lg = 948 */
  columnSpan: PropTypes.object,
  /** span ALL grid columns in a <Row/> */
  fullWidth: PropTypes.bool,
  /** align text */
  textAlign: PropTypes.oneOf(['left', 'center', 'right']),
};

export default Cell;

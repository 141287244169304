/** @jsx jsx */
import React, { useState, useRef } from 'react';
import PropTypes from 'prop-types';
import { jsx } from '@emotion/core';
import styled from '@emotion/styled';
import { colors } from '../../fdpq-core/config/vars';
import size from '@argo/principles/constants/size';
import breakpoints from '@argo/principles/constants/breakpoints';

const InputContainer = styled.div(
  ({ isActive, isFocused, isError, theme }) => `
  background-color: ${colors.global.white1};
  border: ${theme.box.border};
  border-radius: ${theme.box.borderRadius};
  height: ${theme.box.height};
  input {
    border: none;
    font-size: 14px;
    outline: 0;
    background: none;
    top: 50%;
    height: ${theme.box.height};
    color: ${theme.text.color};
    @media only screen and (max-width: ${breakpoints.lg}px) {
      font-size: ${size.sm}px;
    }
  }
  input:-webkit-autofill {
    -webkit-animation: autofill 0s forwards;
  }
  @keyframes autofill {
    100% {
      background: transparent;
      color: inherit;
    }
}

  padding: 0 8px;
  position: relative;
  label {
    cursor: text;
    font-size: 14px;
    top: 0%;
    position: absolute;
    transform-origin: top left;
    transition: all .1s ease-in-out;
    ${
  isError
    ? `color: ${theme.box.errorGlowingColor};`
    : isActive
      ? `color: ${isFocused ? theme.box.baseGlowingColor : theme.text.color};`
      : `color: ${theme.text.color};`
}
  }
  ${
  isError
    ? `border-color: ${theme.box.errorGlowingColor};`
    : isActive
      ? `border-color: ${isFocused ? theme.box.baseGlowingColor : theme.box.borderColor};`
      : ''
}
`,
);

const LabelStyle = styled.label`
  ${({ isActive, isError, theme }) => `
    ${
  isActive // Need to separate the transform and label border color into two different conditions, one for isFocused and one for is not focused but with input value
    ? `
        position: absolute;
        font-weight: 200;
        padding: 0 4px;
        transform: translate(0, -8px) scale(.75);
        background-color: ${colors.global.white1};
        color: ${theme.box.baseGlowingColor};
      `
    : `
        transform: translate(0, 10px) scale(1);
        height: ${theme.box.height};
        background: none;
        color: ${theme.text.color};
      `
}
    ${
  isError
    ? `color: ${theme.box.errorGlowingColor};`
    : `color: ${theme.text.color};`
}
  `};
`;

const FloatingLabelTextField = ({
  inputText,
  name,
  error,
  labelText,
  numeric,
  type = 'text',
  maxLength = 500,
  onChange,
}) => {
  const [isFocused, setIsFocused] = useState(false);
  const [isActive, setIsActive] = useState(!!inputText);
  const [inputValue, setInputValue] = useState(inputText || '');
  const inputEl = useRef(null);
  return (
    <InputContainer isFocused={isFocused} isActive={isActive} isError={error}>
      <LabelStyle
        isActive={isActive}
        isError={error}
        onClick={() => {
          inputEl.current.focus();
        }}
      >
        {labelText}
      </LabelStyle>
      <input
        name={name}
        type={type}
        maxLength={maxLength}
        value={inputValue}
        style={{ width: '100%' }}
        ref={inputEl}
        onFocus={() => {
          setIsActive(true);
          setIsFocused(true);
        }}
        onBlur={(e) => {
          setIsActive(inputValue !== '');
          setIsFocused(false);
        }}
        onChange={(e) => {
          e.target.value = e.target.value.substring(0, maxLength);
          if (numeric) {
            let regexZip = e.target.value.replace(/\D/g, '');
            if (e.target.value === regexZip) {
              setInputValue(e.target.value);
              onChange(e);
            }
          } else {
            setInputValue(e.target.value);
            onChange(e);
          }
        }}
      />
    </InputContainer>
  );
};

FloatingLabelTextField.propTypes = {
  error: PropTypes.bool,
  onChange: PropTypes.func,
  labelText: PropTypes.string,
};

export default FloatingLabelTextField;

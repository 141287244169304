/** @jsx jsx */
import { jsx, css } from '@emotion/core';
import {
  Button,
  Column,
  Description,
  FlexContainer,
  FlexRow,
  FlexCell,
  Footer,
  VehicleInfo,
  FloatingLabelTextField,
  LoadingModal,
} from '@packages/fdpq-common';
import Disclaimer from '@kbbcomponentlibrary/disclaimer';
import { widgetSize } from '@packages/fdpq-common/constants/ui';
import { withTheme } from 'emotion-theming';
import { Fragment } from 'react';
import { VEHICLE_LISTING_TYPE } from '@packages/fdpq-core/constants/app';
import { withFormik } from 'formik';
import { connect } from 'react-redux';
import { colors, text } from '../../fdpq-core/config/vars';
import KeylineContainer from '../../fdpq-common/components/KeylineContainer';
import { mapStateToProps, mapDispatchToProps, mapValidationToProps } from '../containers/ContactForm';

const ContactForm = ({
  // formik props
  errors,
  handleChange,
  handleSubmit,
  touched,
  values,
  theme,
  // regular props
  onButtonPress,
  firstNameError,
  lastNameError,
  emailError,
  phoneError,
  listingTypeConfig,
  disclaimerLink,
}) => {
  const isTradeIn = (listingTypeConfig.vehicleListingType === VEHICLE_LISTING_TYPE.TRADEIN);
  const isMobile = (theme.widgetSize === widgetSize.SMALL);
  const keyLineStyle = css`
  ${(isTradeIn)
    ? `
      padding-bottom: 0px;
    `
    : `
      padding-bottom: 16px;
    `
}
  `;

  return (
    <Column id='test-contact-form'>
      <LoadingModal loadingSubscriptions={['validateLead', 'submitLead']} />
      <FlexContainer>
        <KeylineContainer css={keyLineStyle}>
          <FlexRow fullWidth={true}>
            {(!isTradeIn)
              ? <FlexCell fullWidth={isTradeIn} columnSpan={{ lg: 6, md: 7, sm: 12 }}>
                <Description
                  title={text.global.contactFormViewDescription.titleText}
                  subtitle={text.global.contactFormViewDescription.subtitleText}
                />
              </FlexCell>
              : <Fragment />
            }
            {(isTradeIn)
              ? <Fragment>
                {(!isMobile)
                  ? <FlexCell id="test-voi-vehicleinfo" fullWidth={false} columnSpan={{ lg: 3, md: 6, sm: 3 }}>
                    <VehicleInfo theme={theme} heading='VOI' />
                  </FlexCell>
                  : <FlexCell id="test-voi-vehicleinfo" fullWidth={true}>
                    <VehicleInfo theme={theme} mobileStyling={true} heading='VOI' />
                  </FlexCell>
                }
              </Fragment>
              : <FlexCell id="test-voi-vehicleinfo" fullWidth={false} columnSpan={{ lg: 6, md: 5, sm: 3 }}>
                {(!isMobile)
                  ? <VehicleInfo theme={theme} />
                  : <Fragment />}
              </FlexCell>
            }
            {(isTradeIn)
              ? <Fragment>
                {(!isMobile)
                  ? <FlexCell id="test-tradein-vehicleinfo" style={{ justifyItems: 'end', gridColumn: 'end' }} fullWidth={false} columnSpan={{ lg: 3, md: 6, sm: 3 }}>
                    <VehicleInfo theme={theme} heading='TRADEIN' textAlign='right' />
                  </FlexCell>
                  : <FlexCell id="test-tradein-vehicleinfo" fullWidth={true}>
                    <VehicleInfo id="test-tradein-vehicleinfo" theme={theme} mobileStyling={true} heading='TRADEIN' />
                  </FlexCell>
                }
              </Fragment>
              : <Fragment columnSpan={{ lg: 3, md: 3, sm: 3 }} />
            }
            {(isTradeIn)
              ? <FlexCell fullWidth={isTradeIn} columnSpan={{ lg: 6, md: 7, sm: 12 }}>
                <Description
                  title={text.global.contactFormViewDescription.titleText}
                  subtitle={text.global.contactFormViewDescription.subtitleText}
                />
              </FlexCell>
              : <Fragment />
            }
          </FlexRow>
        </KeylineContainer>
        {(!isTradeIn) && (isMobile) ? (<VehicleInfo theme={theme} mobileStyling={true} heading='' />) : <Fragment />}
        <FlexRow>
          <FlexCell columnSpan={{ lg: 6, md: 6, sm: 12 }}>
            <FloatingLabelTextField
              name={'firstName'}
              error={(touched.firstName && errors.firstName) || firstNameError}
              labelText={'First Name'}
              inputText={values.firstName}
              onChange={handleChange}
            />
          </FlexCell>
          <FlexCell columnSpan={{ lg: 6, md: 6, sm: 12 }}>
            <FloatingLabelTextField
              name={'lastName'}
              error={(touched.lastName && errors.lastName) || lastNameError}
              labelText={'Last Name'}
              inputText={values.lastName}
              onChange={handleChange}
            />
          </FlexCell>
        </FlexRow>
        <FlexRow>
          <FlexCell columnSpan={{ lg: 6, md: 6, sm: 12 }}>
            <FloatingLabelTextField
              type="email"
              name={'email'}
              error={(touched.email && errors.email) || emailError}
              labelText={'Email'}
              inputText={values.email}
              onChange={handleChange}
            />
          </FlexCell>
          <FlexCell columnSpan={{ lg: 6, md: 6, sm: 12 }}>
            <FlexRow columnSpan={{ lg: 6, md: 6, sm: 12 }}>
              <FlexCell columnSpan={{ lg: 6, md: 6, sm: 6 }}>
                <FloatingLabelTextField
                  type="tel"
                  maxLength="10"
                  name={'phoneNumber'}
                  error={(touched.phoneNumber && errors.phoneNumber) || phoneError}
                  labelText={'Phone'}
                  inputText={values.phoneNumber}
                  numeric={true}
                  onChange={handleChange}
                />
              </FlexCell>
              <FlexCell columnSpan={{ lg: 6, md: 6, sm: 6 }}>
                <Button
                  name={'Step3Button'}
                  onClick={(e) => { onButtonPress(); handleSubmit(e); }}
                  type="submit"
                >
                  Send
              </Button>
              </FlexCell>
            </FlexRow>
          </FlexCell>
        </FlexRow>
      </FlexContainer>
      <Footer>
        <FlexContainer>
          <FlexRow>
            <FlexCell fullWidth={true}>
              <Disclaimer style={{ color: colors.global.blue4 }}>
                {theme.contactFormView.disclaimer.disclaimerText}{' '}
                <a href={disclaimerLink} target="_blank" style={{ color: colors.global.blue1, textDecoration: theme.contactFormView.disclaimer.linkTextDecoration }}>
                  {' '}
                  {theme.contactFormView.disclaimer.linkText}
                </a>
              </Disclaimer>
            </FlexCell>
          </FlexRow>
        </FlexContainer>
      </Footer>
    </Column>
  );
};

const ContactFormWithValidation = withFormik(mapValidationToProps)(withTheme(ContactForm));
export default connect(
  mapStateToProps,
  mapDispatchToProps,
)(ContactFormWithValidation);

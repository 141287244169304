/** @jsx jsx */
import React from 'react';
import PropTypes from 'prop-types';
import { LayoutContext, ColContext } from './grid-context';
import { jsx, css } from '@emotion/core';
import styled from '@emotion/styled';
import Grid from './grid';
import Spacing from './spacing';
import { widgetSize } from '../constants/ui';

const styledCol = (props, targetColumns) => {
  const { layout, column } = props;
  const firstCol = column === 1;

  const space = targetColumns === 1 ? Spacing.Close : Spacing.Large;

  if (layout === 2 && targetColumns === 2) {
    return `margin: ${firstCol ? `0 0 0 ${space}` : `0 ${space} 0 0`};`;
  }

  return `margin: 0 ${space};`;
};

const StyledContainer = styled.section((props) => {
  const { fullBleed, withPadding, theme } = props;

  return (css`
    ${Grid.grid}
    ${Grid.close}

    ${theme.widgetSize === widgetSize.SMALL ?
      fullBleed ? `
        margin: 0;
        background-color: ${props.color};
        padding: ${withPadding ? Spacing.Close : 0};
        ` : styledCol(props, 1)
      :
      fullBleed ? `
        padding: ${withPadding ? Spacing.Close : 0} ${withPadding ? Spacing.Large : 0};
      ` : styledCol(props, 2)
    }

    @media only screen and (-ms-high-contrast: none) {
      flex-direction: column;

      > div:last-child {
        margin-bottom: 0;
      }
    }

    > p {
      margin: 0;
    }
  `);
});

/** // Container Component
 * import { Layout } from '@kbbcomponentlibrary/principles';
 * const { Section } = Layout;
 * */

const Container = props => (
  <LayoutContext.Consumer>
    {layout => (
      <ColContext.Consumer>
        {column => (
          <StyledContainer layout={layout} column={column} {...props} />
        )}
      </ColContext.Consumer>
    )}
  </LayoutContext.Consumer>
);

Container.displayName = 'Container';

Container.propTypes = {
  children: PropTypes.node,
  fullBleed: PropTypes.bool,
  color: PropTypes.string,
};

Container.defaultProps = {
  withPadding: true,
  fullBleed: false,
  color: '#DDD',
};

export default Container;

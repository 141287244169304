import {
  YEAR_DROPDOWN_CHANGE,
  MAKE_DROPDOWN_CHANGE,
  MODEL_DROPDOWN_CHANGE,
  TRIM_DROPDOWN_CHANGE,
  TRADEIN_YEAR_DROPDOWN_CHANGE,
  TRADEIN_MAKE_DROPDOWN_CHANGE,
  TRADEIN_MODEL_DROPDOWN_CHANGE,
  TRADEIN_TRIM_DROPDOWN_CHANGE,
  CLEAR_SELECTED_TRADEIN_YEAR,
  CLEAR_SELECTED_TRADEIN_MAKE,
  CLEAR_SELECTED_TRADEIN_MODEL,
  CLEAR_SELECTED_TRADEIN_TRIM,
  CLEAR_SELECTED_YEAR,
  CLEAR_SELECTED_MAKE,
  CLEAR_SELECTED_MODEL,
  CLEAR_SELECTED_TRIM,
  UPDATE_MAKE_OPTIONS,
  UPDATE_MODEL_OPTIONS,
  UPDATE_TRIM_OPTIONS,
  TRADEIN_UPDATE_MAKE_OPTIONS,
  TRADEIN_UPDATE_MODEL_OPTIONS,
  TRADEIN_UPDATE_TRIM_OPTIONS,
  GET_MAKES_FAILURE,
  GET_MODELS_FAILURE,
  GET_TRIMS_FAILURE,
  GET_VEHICLE_FAILURE,
  TRADEIN_GET_YEARS_FAILURE,
  TRADEIN_GET_MAKES_FAILURE,
  TRADEIN_GET_MODELS_FAILURE,
  TRADEIN_GET_TRIMS_FAILURE,
  TRADEIN_GET_VEHICLE_FAILURE,
  CLICK_VEHICLE_SUBMIT_BUTTON,
  CLICK_TRADEIN_VEHICLE_SUBMIT_BUTTON,
} from '../action-types/vehicles';
import { PAGE_NAME, VEHICLE_LISTING_TYPE } from '@packages/fdpq-core/constants/app';

export const getVehicleState = (state) => {
  const { vehicles, app: { config: { listingTypeConfig } }, navigation: { currentPage } } = state;

  let selectedVehicle = vehicles.selectedVehicle;
  let vehicleListingType = listingTypeConfig.vehicleListingType;
  let vehicleSelectorDropDowns = listingTypeConfig.vehicleSelectorView.vehicleSelectorDropDowns;
  let trimOptions = vehicles.trimOptions;

  if (currentPage == PAGE_NAME.TRADEIN_VEHICLE_SELECTOR) {
    selectedVehicle = vehicles.selectedTradeinVehicle;
    vehicleSelectorDropDowns = listingTypeConfig.vehicleSelectorTradeInView.vehicleSelectorDropDowns;
    trimOptions = vehicles.tradeinTrimOptions;
    vehicleListingType = VEHICLE_LISTING_TYPE.USED;
  } else if (vehicleListingType === VEHICLE_LISTING_TYPE.TRADEIN) {
    vehicleListingType = VEHICLE_LISTING_TYPE.NEW;
  }

  const actionTypes = getActionTypes(currentPage);

  return { selectedVehicle, vehicleSelectorDropDowns, vehicleListingType, trimOptions, actionTypes };
};

export const getActionTypes = (currentPage) => {
  let actionTypes = {};
  if (currentPage === PAGE_NAME.TRADEIN_VEHICLE_SELECTOR) {
    actionTypes.yearDropDownChange = TRADEIN_YEAR_DROPDOWN_CHANGE;
    actionTypes.makeDropDownChange = TRADEIN_MAKE_DROPDOWN_CHANGE;
    actionTypes.modelDropDownChange = TRADEIN_MODEL_DROPDOWN_CHANGE;
    actionTypes.trimDropDownChange = TRADEIN_TRIM_DROPDOWN_CHANGE;
    actionTypes.clearSelectedYear = CLEAR_SELECTED_TRADEIN_YEAR;
    actionTypes.clearSelectedMake = CLEAR_SELECTED_TRADEIN_MAKE;
    actionTypes.clearSelectedModel = CLEAR_SELECTED_TRADEIN_MODEL;
    actionTypes.clearSelectedTrim = CLEAR_SELECTED_TRADEIN_TRIM;
    actionTypes.updateMakeOptions = TRADEIN_UPDATE_MAKE_OPTIONS;
    actionTypes.updateModelOptions = TRADEIN_UPDATE_MODEL_OPTIONS;
    actionTypes.updateTrimOptions = TRADEIN_UPDATE_TRIM_OPTIONS;
    actionTypes.getVehicleFailure = TRADEIN_GET_VEHICLE_FAILURE;
    actionTypes.getYearsFailure = TRADEIN_GET_YEARS_FAILURE;
    actionTypes.getMakesFailure = TRADEIN_GET_MAKES_FAILURE;
    actionTypes.getModelsFailure = TRADEIN_GET_MODELS_FAILURE;
    actionTypes.getTrimsFailure = TRADEIN_GET_TRIMS_FAILURE;
    actionTypes.clickVehicleSubmit = CLICK_TRADEIN_VEHICLE_SUBMIT_BUTTON;
  } else {
    actionTypes.yearDropDownChange = YEAR_DROPDOWN_CHANGE;
    actionTypes.makeDropDownChange = MAKE_DROPDOWN_CHANGE;
    actionTypes.modelDropDownChange = MODEL_DROPDOWN_CHANGE;
    actionTypes.trimDropDownChange = TRIM_DROPDOWN_CHANGE;
    actionTypes.clearSelectedYear = CLEAR_SELECTED_YEAR;
    actionTypes.clearSelectedMake = CLEAR_SELECTED_MAKE;
    actionTypes.clearSelectedModel = CLEAR_SELECTED_MODEL;
    actionTypes.clearSelectedTrim = CLEAR_SELECTED_TRIM;
    actionTypes.updateMakeOptions = UPDATE_MAKE_OPTIONS;
    actionTypes.updateModelOptions = UPDATE_MODEL_OPTIONS;
    actionTypes.updateTrimOptions = UPDATE_TRIM_OPTIONS;
    actionTypes.getVehicleFailure = GET_VEHICLE_FAILURE;
    actionTypes.getMakesFailure = GET_MAKES_FAILURE;
    actionTypes.getModelsFailure = GET_MODELS_FAILURE;
    actionTypes.getTrimsFailure = GET_TRIMS_FAILURE;
    actionTypes.clickVehicleSubmit = CLICK_VEHICLE_SUBMIT_BUTTON;
  }
  return actionTypes;
};
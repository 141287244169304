import React from 'react';
import styled from '@emotion/styled';
import { colors } from '../../fdpq-core/config/vars';

const ContainerDiv = styled.div`
  background-color: ${colors.global.gray2};
  font-size: 12px;
  height: 60px;
  width: 50%;
`;

const BlankDealerBlock = () => (
  <ContainerDiv>
  </ContainerDiv>
);

export default BlankDealerBlock;

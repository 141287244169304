import React, { Component } from 'react';
import styled from '@emotion/styled';
import { colors } from '../../fdpq-core/config/vars';

const DropdownSelect = styled.select`
  ${({ disabled, isError, isFocused, theme }) => `
    background-color: ${disabled ? `${theme.box.backgroundColorDisabled}` : `${colors.global.white1}`};
    border: ${theme.box.border};
    border-radius: ${theme.box.borderRadius};
    color: ${disabled ? 'colors.gray.light20' : `${theme.text.color}`};
    font-size: 14px;
    height: ${theme.box.height};
    margin: 1px 0;
    line-height: ${theme.box.lineHeight};
    position: relative;
    width: 100%;
    padding: 0 4px;

    &:focus {
      border-color: ${theme.box.baseGlowingColor};
      outline: 0;
    }
    ${
  isError
    ? `border-color: ${theme.box.errorGlowingColor};`
    : isFocused
      ? `border-color: ${theme.box.baseGlowingColor};`
      : ''
}
  `}
`;

export default class Dropdown extends Component {
  constructor(props) {
    super(props);
    this.state = { isDisabled: true };
  }

  handleChange = (event) => {
    const { handleDropdownChange, name, updateState } = this.props;
    const selectOptions = event.target.options;
    const selectedText = selectOptions[selectOptions.selectedIndex].text;
    const selectedValue = selectOptions[selectOptions.selectedIndex].value;
    if (handleDropdownChange) {
      handleDropdownChange(name, selectedValue);
      updateState({
        text: selectedText,
        value: selectedValue,
      });
    }
  };

  render() {
    const {
      customDataAttrs,
      disabled,
      error,
      items,
      name,
      onChange,
      placeholderText,
      value,
      withOptGroup,
    } = this.props;
    return (
      <DropdownSelect
        {...customDataAttrs}
        name={name}
        // defaultValue={'DEFAULT'}
        value={value || 'DEFAULT'}
        disabled={this.props.disabled && value !== 'DEFAULT'}
        isError={!disabled && error}
        onChange={this.handleChange}
      >
        {placeholderText && (
          <option value={'DEFAULT'} disabled>
            {placeholderText}
          </option>
        )}
        {withOptGroup
          ? items.map((item, i) => (
              <optgroup key={`${item.value}${i}`} label={item.label}>
                {item.options.map((option, j) => (
                  <option
                    key={`${item.value}${j}`}
                    value={option.value}
                    selected-item={`${value === item.value}`}
                  >
                    {option.text}
                  </option>
                ))}
              </optgroup>
          ))
          : items
            && items.map((item, i) => (
              <option
                key={`${item.value}${i}`}
                value={item.value}
                selected-item={`${value === item.value}`}
              >
                {item.text}
              </option>
            ))}
      </DropdownSelect>
    );
  }
}

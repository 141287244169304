/** @jsx jsx */
import { jsx, css } from '@emotion/core';
import styled from '@emotion/styled';
import Spinner from '@argo/ui-elements/Spinner';
import Size from '@argo/principles/constants/size';
import DefaultSpinner from './Spinner';

const LoadingModal = styled.div(({ isShowed }) => `
  display: ${isShowed ? 'block' : 'none'};
  height: 100%;
  opacity: .65;
  position: absolute;
  width: 100%;
  z-index: 100;
`);

const SpinnerWrapper = styled.div`
  position: absolute;
  left: 50%;
  top: 40%;
  width: 50%;
  ${({ radius }) => `
  transform: translate(-${radius}, -${radius});
  `}
`;

const spinnerStyles = css`
  position: absolute;
  left: 46%;
  top: 15%;
`;

export default ({ isShowed, useArgoStyle, loadingModalStyle }) => {
  // Define the horizonal spacing for the spinner
  const spinnerSize = 36;
  const radius = spinnerSize / 2;

  return (
    <LoadingModal isShowed={isShowed} css={loadingModalStyle}>
      {
        useArgoStyle ? (
          <Spinner dimension={{ size: `${Size.xl}px`, thickness: `${Size.micro}px` }} css={spinnerStyles} />
        ) : (
          <SpinnerWrapper radius = {`${radius}px`}>
            <DefaultSpinner size={`${spinnerSize}px`} />
          </SpinnerWrapper>
        )
      }
    </LoadingModal>
  );
};

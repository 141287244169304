/* eslint-disable no-nested-ternary */
/** @jsx jsx */
import { Fragment, useEffect } from 'react';
import { jsx } from '@emotion/core';
import { connect } from 'react-redux';
import styled from '@emotion/styled';
import { withTheme } from 'emotion-theming';

import {
  Button,
  ChangeZipButton,
  Description,
  FlexContainer,
  FlexCell,
  FlexRow,
  Footer,
  LoadingModal,
  Pagination,
  usePagination,
  VehicleInfo,
  Column,
} from '@packages/fdpq-common';
import { widgetSize } from '@packages/fdpq-common/constants/ui';
import { VEHICLE_LISTING_TYPE } from '@packages/fdpq-core/constants/app';
import DealerList from './DealerList';
import BlankDealerList from './BlankDealerList';
import useDealerValidation from './useDealerValidation';
import KeylineContainer from '../../fdpq-common/components/KeylineContainer';
import { colors } from '../../fdpq-core/config/vars';
import { mapStateToProps, mapDispatchToProps } from '../containers/DealerSelector';
import { getUrlHostName } from '../../fdpq-widget/utils/helperUtil'

const errorTextColor = colors.global.red1;

const PaginationWrapper = styled.div(({ isMobile, isTradeIn }) => `
  display: flex;
  width: 100%;
  ${!isMobile && isTradeIn ? 'justify-content: left;' : 'justify-content: center;'}
`);

const PageHeaderGroup = styled.div`
  display: flex;
  flex-direction: column;
  width: 100%;
`;

const ErrorText = styled.div`
  color: ${errorTextColor};
  font-size: 10px;
`;

const DealerSelector = ({
  dealerList,
  errorMessage,
  description,
  selectedDealers,
  initDealerSelectorPage,
  onNextPageSelected,
  onButtonPress,
  theme,
  listingTypeConfig,
  zipCode,
}) => {
  useEffect(() => {
    initDealerSelectorPage(selectedDealers, dealerList);
  }, [dealerList.length]);

  const { dataList, displayList, pageLimit, onPageChanged } = usePagination({
    dataList: dealerList,
    pageLimit: 3,
    onPageChanged: (selectedItems) => {
      console.log(selectedItems);
    },
  });
  const displaySelectedItems = useDealerValidation(
    displayList,
    selectedDealers,
  );
  const isTradeIn = (listingTypeConfig.vehicleListingType === VEHICLE_LISTING_TYPE.TRADEIN);
  const isMobile = (theme.widgetSize === widgetSize.SMALL);
  const hostName = getUrlHostName();
  const isKbb = hostName.includes(hostName);

  return (
    <Column id="test-dealer-selector">
      <LoadingModal loadingSubscriptions={['getDealerList']} />
      <FlexContainer>
        <KeylineContainer style={{ paddingBottom: isTradeIn ? '0px' : '16px' }}>
          <FlexRow fullWidth={true}>
            {(!isTradeIn)
              ? <FlexCell fullWidth={false} columnSpan={{ lg: 6, md: 6, sm: 9 }}>
                <Description title={description.text} subtitle={description.title} />
                {(!isMobile)
                  ? <ChangeZipButton />
                  : <Fragment />
                }
              </FlexCell>
              : <Fragment />
            }
            {(isTradeIn)
              ? <Fragment>
                {(!isMobile)
                  ? <FlexCell id="test-voi-vehicleinfo" fullWidth={false} columnSpan={{ lg: 3, md: 6, sm: 6 }}>
                    <VehicleInfo theme={theme} heading='VOI' />
                  </FlexCell>
                  : <FlexCell id="test-voi-vehicleinfo" fullWidth={true}>
                    <VehicleInfo theme={theme} mobileStyling={true} heading='VOI' />
                  </FlexCell>
                }
              </Fragment>
              : <FlexCell id="test-voi-vehicleinfo" style={{ textAlign: 'right' }} fullWidth={false} columnSpan={{ lg: 6, md: 6, sm: 3 }}>
                {(!isMobile)
                  ? <VehicleInfo theme={theme} />
                  : <ChangeZipButton />}
              </FlexCell>
            }
            {(isTradeIn)
              ? <Fragment>
                {(!isMobile)
                  ? <FlexCell id="test-tradein-vehicleinfo" fullWidth={false} columnSpan={{ lg: 3, md: 6, sm: 6 }} style={{ justifyItems: 'end', gridColumn: 'end' }}>
                    <VehicleInfo theme={theme} heading='TRADEIN' textAlign='right' />
                  </FlexCell>
                  : <FlexCell id="test-tradein-vehicleinfo" fullWidth={true}>
                    <VehicleInfo id="test-tradein-vehicleinfo" theme={theme} mobileStyling={true} heading='TRADEIN' />
                  </FlexCell>
                }
              </Fragment>
              : <Fragment />
            }
            {(isTradeIn)
              ? <FlexCell fullWidth={false} columnSpan={{ lg: 12, md: 12, sm: 12 }}>
                <Description title={description.title} />
              </FlexCell>
              : <Fragment />
            }
            {(isTradeIn)
              ? <FlexCell columnSpan={{ lg: 4, md: 4, sm: 4 }} style={{ textAlign: 'right', justifyItems: 'end', gridColumn: 'end' }}>
                <ChangeZipButton />
              </FlexCell>
              : <Fragment />
            }
          </FlexRow>
        </KeylineContainer>
        {(!isTradeIn) && (isMobile) ? (<VehicleInfo theme={theme} mobileStyling={true} heading='' />) : <Fragment />}
        {(errorMessage)
          ? (<FlexRow>
            <FlexCell fullWidth={true}>
              <PageHeaderGroup>
                <ErrorText>{errorMessage}</ErrorText>
              </PageHeaderGroup>
            </FlexCell>
          </FlexRow>)
          : <Fragment />
        }
        <FlexRow>
          {displaySelectedItems.length === 0
            ? (<BlankDealerList />)
            : (<DealerList displayDealerList={displaySelectedItems} vehicleListingTypeConfig={listingTypeConfig} />)
          }
        </FlexRow>
      </FlexContainer>
      <Footer>
        <FlexContainer>
          <FlexRow>
            <FlexCell columnSpan={{ lg: isTradeIn ? '6' : '12', md: isTradeIn ? '6' : '12', sm: 12 }}>
              <PaginationWrapper isMobile={isMobile} isTradeIn={isTradeIn}>
                <Pagination
                  dataList={dataList}
                  displayFullPageList={!isMobile}
                  pageLimit={pageLimit}
                  onPageChanged={onPageChanged}
                />
              </PaginationWrapper>
            </FlexCell>
            <FlexCell columnSpan={{ lg: 3, md: 3, sm: 12 }} style={{ justifyItems: 'end', gridColumn: !isMobile ? theme.button.desktopGridColumn : '' }}>
              <Button name={'Step2Button'} onClick={(e) => { onButtonPress(); onNextPageSelected(e, isKbb); }}>
                Get My Free Quotes
              </Button>
            </FlexCell>
          </FlexRow>
        </FlexContainer>
      </Footer>
    </Column>
  );
};

export default connect(mapStateToProps, mapDispatchToProps)(withTheme(DealerSelector));
/** @jsx jsx */
import { css, jsx } from '@emotion/core';
import { Header } from '@packages/fdpq-common';
import { VehicleSelectorArgo } from '@packages/fdpq-vehicle-selector';
import { DealerSelectorArgo } from '@packages/fdpq-dealer-selector';
import { ContactFormArgo } from '@packages/fdpq-contact-form';
import { ThankYouPageArgo } from '@packages/fdpq-thank-you-page';
import { PAGE_NAME } from '@packages/fdpq-core/constants/app';
import FlexBox from '@argo/principles/FlexBox';
import Box from '@argo/ui-elements/Box';
import { Helmet } from 'react-helmet';
import styled from '@emotion/styled';
import { getUrlParameter } from '../utils/helperUtil';
import { RECAPTCHA_V3_CLIENT_SITE_KEY } from '../utils/constants';

const showPage = (currentPage) => {
  switch (currentPage) {
    case PAGE_NAME.TRADEIN_VEHICLE_SELECTOR:
      return <VehicleSelectorArgo />;
    case PAGE_NAME.VEHICLE_SELECTOR:
      return <VehicleSelectorArgo />;
    case PAGE_NAME.DEALER_SELECTOR:
      return <DealerSelectorArgo />;
    case PAGE_NAME.CONTACT_FORM:
      return <ContactFormArgo />;
    case PAGE_NAME.THANK_YOU_PAGE:
      return <ThankYouPageArgo />;
    default:
      return <VehicleSelectorArgo />;
  }
};

const boxStyling = css`
  padding: 0px 0px 20px;
`;

const PageContent = styled.div`
${({ theme }) => `
  background-color: ${theme.global.pageContent.backgroundColor};
  border-radius: ${theme.global.pageContent.borderRadius}
  `}
`;

const getPageHeight = currentPage => (currentPage === PAGE_NAME.VEHICLE_SELECTOR || currentPage === PAGE_NAME.TRADEIN_VEHICLE_SELECTOR ? { height: 'auto' } : {});
const pageName = getUrlParameter('pagename') || '';

const FdpqWidgetArgo = ({ navigation, app: { texts, theme, config: { listingTypeConfig: { vehicleListingType }, queryParams: { hidepadding } } } }) => (
  <PageContent id="syn-fdpq">
  <Box contentPadding="small" backgroundColor={theme.global.pageContent.backgroundColor} css={hidepadding === 'true' ? boxStyling : ''}>
    <Helmet>
      <title>{texts.global.headerText}</title>
      <meta name="description" content={`KBB ${texts.global.headerText}`} />
      {pageName === 'fdpqselect' && (
        <script src={`https://www.google.com/recaptcha/api.js?render=${RECAPTCHA_V3_CLIENT_SITE_KEY}`}></script>
      )}
    </Helmet>
    <FlexBox
      alignItems="flex-start"
      default={[
        '1 0 100%',
        '1 0 100%',
      ]}
      flexWrap="wrap"
      horizontalSpacing="sm"
      verticalSpacing="md"
      style={getPageHeight(navigation.currentPage)}
    >
      <Header title={texts.global.headerText} subtitle={texts.global.headerSubText} vehicleListingType={vehicleListingType} useArgoStyle />
      <PageContent id='test-page-content' theme={theme}>
        {showPage(navigation.currentPage)}
      </PageContent>
    </FlexBox>
  </Box>
  </PageContent>
);

export default FdpqWidgetArgo;

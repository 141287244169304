import { connect } from 'react-redux';
import { toVehicleSelector, clickChangeZip } from '@packages/fdpq-core/actions/navigation';
import ChangeZipButton from '../components/ChangeZipButton';

const mapDispatchToProps = {
  onClickChangeZip: clickChangeZip,
  onChangeZipSelected: toVehicleSelector
};

export default connect(null, mapDispatchToProps)(ChangeZipButton);

/** @jsx jsx */
import { jsx } from '@emotion/core';
import PropTypes from 'prop-types';
import styled from '@emotion/styled';
import { widgetSize } from '../constants/ui';

const StyledFooter = styled.div(({ theme }) => `
  width: 100%;
`);

const Footer = ({ children = [], style = {} }) => (
  <StyledFooter style={style}>
    {children}
  </StyledFooter>
);

Footer.propTypes = {
  children: PropTypes.object,
  style: PropTypes.object
};

Footer.defaultProps = {
  children: {},
  style: {}
};

export default Footer;

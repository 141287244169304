/** @jsx jsx */
import { jsx } from '@emotion/core';
import styled from '@emotion/styled';
import PropTypes from 'prop-types';

const StyledKeylineContainer = styled.div`
  padding: 16px 0;
  ${({ theme }) => `
    box-shadow: ${theme.keylineShadow};
    border-bottom: 1px solid ${theme.keyline.borderColor};
  `}
`;

const KeylineContainer = ({ children = [], style = {} }) => (
    <StyledKeylineContainer style={style}>
      {children}
    </StyledKeylineContainer>
);

KeylineContainer.propTypes = {
  children: PropTypes.object,
  style: PropTypes.object
};

KeylineContainer.defaultProps = {
  children: {},
  style: {}
};

export default KeylineContainer;
import { toVehicleSelectorFromThankYou, onThankYouButtonClick } from '@packages/fdpq-core/actions/thankYou';

export const mapDispatchToProps = ({
  onNextPageSelected: toVehicleSelectorFromThankYou,
  onButtonPress: onThankYouButtonClick,
});

export const mapStateToProps = (state) => {
  const selectedDealers = state.dealers.selectedDealers.map(dealerData => ({
    displayItem: dealerData,
    isPreSelected: true,
  }));
  const leadpartnerfranchiseid = state.app.config.queryParams?.leadpartnerfranchiseid || '';
  const leadFranchiseId = state.app.config.queryParams?.leadfranchiseid || '';
  const zipCode = state.location?.zipcode || '';

  return { selectedDealers, listingTypeConfig: state.app.config.listingTypeConfig, leadpartnerfranchiseid, leadFranchiseId, zipCode };
};

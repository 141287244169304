export const fastDeepCompare = (objA, objB) => {
  for (let key in objA) {
    if (objA[key] !== Object(objA[key]) && objB[key] !== Object(objB[key])) {
      if (objA[key] !== objB[key]) {
        return false;
      }
    }
  }
  return true;
};

export const removeItemFromList = (removingItem, allItems) => {
  if (allItems && allItems.length > 0) {
    return allItems.filter((item) => {
      if (fastDeepCompare(removingItem, item)) {
        return false;
      }
      return true;
    });
  }
  return allItems;
};

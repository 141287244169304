/* eslint-disable no-console */
import axios from 'axios';

export default class LscService {
  static url;

  static apiKey;

  static configure(config, apiKey) {
    const baseUrl = config.useCdn ? config.fdpqCdnBaseUrl : config.fdpqApiBaseUrl;
    LscService.apiKey = config.apiKey || apiKey;
    LscService.url = `${baseUrl}/api/lsc`;
  }

  static async getMakeList({ leadFranchiseId, leadPartnerFranchiseId}) {
    const finalUrl = `${LscService.url}/makelist`;
    return new Promise((resolve, reject) => {
      axios
        .get(finalUrl, {
          params: {
            apikey: LscService.apiKey,
            leadFranchiseId: leadFranchiseId,
            leadPartnerFranchiseId: leadPartnerFranchiseId
          },
        })
        .then((response) => {
          resolve(response.data);
        })
        .catch((error) => {
          reject(error);
        });
    });
  }
}

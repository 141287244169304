/** @jsx jsx */
import { Fragment } from 'react';
import { jsx, css } from '@emotion/core';
import styled from '@emotion/styled';
import { VEHICLE_LISTING_TYPE } from '@packages/fdpq-core/constants/app';
import { widgetSize } from '../constants/ui';
import FlexRow from '../layout/Row';
import FlexCell from '../layout/Cell';
import { colors } from '../../fdpq-core/config/vars';
import { connect } from 'react-redux';
import { mapStateToProps, mapDispatchToProps } from '../containers/VehicleInfo';

const StyledVehicleInfo = styled.div(({ mobileStyling, isTradeIn }) => `
  display: flex;
  flex-shrink: 0;
  flex-direction: row;
  width: 100%;
  ${mobileStyling || isTradeIn
    ? `
      justify-content: flex-start;
      text-align: left;
    `
    : `
      justify-content: flex-end;
      text-align: right;
    `
}
`);

const VehicleModelInfo = styled.div`
  display: flex;
  flex-direction: column;
  width: 100%;
`;

const VehicleInfoHeading = styled.div`
  color: ${colors.global.black1};
  font-size: 14px;
  margin-bottom: 4px;
`;

const VehicleModel = styled.div(({ theme }) => `
  color:${theme.vehicleInfo.vehicleModel.color || colors.global.blue4} ;
  font-size: ${theme.vehicleInfo.vehicleModel.fontSize || '14px'};
  margin-bottom: ${theme.vehicleInfo.vehicleModel.marginBottom || '7px'};
  font-weight: ${theme.vehicleInfo.vehicleModel.fontWeight || '900'};
`);

const VehicleTrim = styled.div(({ theme }) => `
  color: ${theme.vehicleInfo.vehicleTrim.color};
  font-size: 12px;
  margin-bottom: 7px;
`);

const ChangeVehicleLink = styled.a`
  ${({ theme }) => `
    color: ${theme.vehicleInfo.changeVehicleLink.color};
  `}
  cursor: pointer;
  font-size: 12px;
`;

const VehicleInfo = ({
  ymm,
  vehicleTrimName,
  tradeInYmm,
  onClickChangeTradeInVehicleLink,
  onClickChangeVehicleLink,
  onChangeTradeInVehicleSelected,
  onChangeVehicleSelected,
  theme,
  mobileStyling,
  heading,
  listingType,
  textAlign,
}) => {
  const isMobile = theme.widgetSize === widgetSize.SMALL;
  const isTradeInListingType = listingType === VEHICLE_LISTING_TYPE.TRADEIN;
  let vehicleInfoHeading = '';
  if (heading === 'VOI') {
    vehicleInfoHeading = 'You\'re Interested In:';
  } else if (heading === 'TRADEIN') {
    vehicleInfoHeading = 'Your Trade-in:';
  }
  const textAlignment = css`
  ${mobileStyling || (isTradeInListingType && textAlign !== 'right')
    ? `
      text-align: left;
    `
    : `
      text-align: right;
   `
}
`;

  let ymmString = heading === 'TRADEIN' ? tradeInYmm : ymm;

  const handleChangeVehicleClick = () => {
    if (heading === 'TRADEIN') {
      onClickChangeTradeInVehicleLink();
      onChangeTradeInVehicleSelected();
    } else {
      onClickChangeVehicleLink();
      onChangeVehicleSelected();
    }
  };
  return (
    <Fragment>
      <StyledVehicleInfo theme={theme} mobileStyling={mobileStyling} isTradeIn={isTradeInListingType}>
        <VehicleModelInfo>
          <FlexRow fullWidth={true}>
            <FlexCell columnSpan={{ lg: 12, md: 12, sm: (isMobile && isTradeInListingType ? 12 : 8) }} css={textAlignment}>
              {(vehicleInfoHeading !== '') ? (<VehicleInfoHeading>{vehicleInfoHeading}</VehicleInfoHeading>) : <Fragment />}
              <VehicleModel id={heading === 'TRADEIN' ? 'test-tradein-vehicleymm' : 'test-vehicleymm'} mobileStyling={mobileStyling} >{ymmString}
              </VehicleModel>
              {(!isTradeInListingType ? <VehicleTrim id='test-vehicletrim' mobileStyling={mobileStyling}>{vehicleTrimName}</VehicleTrim> : <Fragment />)}
              {(isTradeInListingType || !isMobile) && typeof onChangeVehicleSelected === 'function' ? (
                <ChangeVehicleLink onClick={(e) => { handleChangeVehicleClick(); }}>
                  Change vehicle
                </ChangeVehicleLink>
              ) : ('')}
            </FlexCell>
            {(!isTradeInListingType && isMobile && typeof onChangeVehicleSelected === 'function') ? (
              <FlexCell columnSpan={{ lg: 12, md: 12, sm: 4 }} textAlign='right'>
                <ChangeVehicleLink onClick={(e) => { handleChangeVehicleClick(); }}>
                  Change vehicle
                </ChangeVehicleLink>
              </FlexCell>
            ) : <Fragment />}
          </FlexRow>
        </VehicleModelInfo>
      </StyledVehicleInfo>
    </Fragment>
  );
};

export default connect(mapStateToProps, mapDispatchToProps)(VehicleInfo);

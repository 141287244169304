import styled from '@emotion/styled';
import { colors } from '../../fdpq-core/config/vars';

const PageContent = styled.div`
  border: 1px solid ${colors.global.gray8};
  border-radius: 0 0 4px 4px;
  padding-bottom: 15px;
  width: 100%;
  ${({ theme }) => `
    background-color: ${theme.global.pageContent.backgroundColor};
    border: ${theme.global.pageContent.border};
    border-radius: ${theme.global.pageContent.borderRadius};
  `}
  border-top: none;
`;

export default PageContent;

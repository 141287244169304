export default class FdpqLead {
  constructor(state) {
    const {
      app: { config },
      vehicles: { selectedVehicle, selectedTradeinVehicle },
      dealers: { selectedDealers },
      contact,
      location,
      pixall
    } = state;

    const zipCode = location.zipcode;

    // page information
    this.path = config.hostPageName;

    // vehicleListingType
    this.vehicleListingType = config.listingTypeConfig.vehicleListingType;

    // lead type
    this.leadType = config.listingTypeConfig.contactFormView.leadType;

    // contact information
    this.entryZip = zipCode;
    this.postalCode = zipCode;
    this.firstName = contact.firstName;
    this.lastName = contact.lastName;
    this.email = contact.email;
    this.phone = contact.phone;

    // selected vehicle information
    this.year = selectedVehicle.yearId;
    this.makeName = selectedVehicle.makeName;
    this.modelName = selectedVehicle.modelName;
    this.trimName = selectedVehicle.trimName;
    this.vehicleId = selectedVehicle.trimId;

    // selected tradein vehicle information
    if (selectedTradeinVehicle) {
      let tradein = {};
      tradein.year = selectedTradeinVehicle.yearId;
      tradein.makeName = selectedTradeinVehicle.makeName;
      tradein.modelName = selectedTradeinVehicle.modelName;
      tradein.trimName = selectedTradeinVehicle.trimName;
      tradein.vehicleId = selectedTradeinVehicle.vehicleId;
      this.tradeInVehicle = tradein;
    }

    // dealer information
    this.recipients = new Array();
    for (let i = 0; i < selectedDealers.length; i++) {
      const curDealer = selectedDealers[i];
      const dealerInfo = {
        dealerPosition: curDealer.dealerPosition,
        franchiseId: curDealer.franchiseId,
        profileId: curDealer.profileId,
        trackingCode: '',
        vendorDealerId: curDealer.vendorDealerId,
        vendorDealerCode: curDealer.vendorDealerCode,
        vendorProgramId: curDealer.vendorProgramId,
        vendorTransactionId: curDealer.vendorTransactionId,
        vendorPingId: curDealer.vendorPingId,
        vendorReservationId: curDealer.vendorReservationId,
        vendorDealerName: curDealer.franchiseName,
        vendorDealerAddress: curDealer.franchiseAddress1,
        vendorDealerCity: curDealer.franchiseCity,
        vendorDealerState: curDealer.franchiseState,
        vendorDealerZip: curDealer.franchiseZip,
        detailVendorList: curDealer.detailVendorList
      };
      this.recipients.push(dealerInfo);
    }

    // pixall ids from query params or from post messages.
    this.pixallPrimaryId = config.queryParams.pixallprimaryid || (typeof pixall !== 'undefined' ? pixall.pixallPrimaryId : '');
    this.pixallSecondaryId = config.queryParams.pixallsecondaryid || (typeof pixall !== 'undefined' ? pixall.pixallSecondaryId : '');
  }
}

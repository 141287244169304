export const ADD_SELECTED_DEALER = 'ADD_SELECTED_DEALER';
export const REMOVE_SELECTED_DEALER = 'REMOVE_SELECTED_DEALER';
export const ADD_MULTIPLE_SELECTED_DEALER = 'ADD_MULTIPLE_SELECTED_DEALER';
export const CLEAR_SELECTED_DEALERS = 'CLEAR_SELECTED_DEALERS';

export const UPDATE_DEALER_LIST = 'UPDATE_DEALER_LIST';
export const CLEAR_DEALER_LIST = 'CLEAR_DEALER_LIST';

export const CLICK_DEALER_NEXT_BUTTON = 'CLICK_DEALER_NEXT_BUTTON';

export const GET_DEALER_LIST_REQUEST = 'GET_DEALER_LIST_REQUEST';
export const GET_DEALER_LIST_SUCCESS = 'GET_DEALER_LIST_SUCCESS';
export const GET_DEALER_LIST_FAILURE = 'GET_DEALER_LIST_FAILURE';

export const GET_MAKE_LIST_REQUEST = 'GET_MAKE_LIST_REQUEST';
export const GET_MAKE_LIST_SUCCESS = 'GET_MAKE_LIST_SUCCESS';
export const GET_MAKE_LIST_FAILURE = 'GET_MAKE_LIST_FAILURE';

export const VALIDATE_LEAD_REQUEST = 'VALIDATE_LEAD_REQUEST';
export const VALIDATE_LEAD_SUCCESS = 'VALIDATE_LEAD_SUCCESS';
export const VALIDATE_LEAD_FAILURE = 'VALIDATE_LEAD_FAILURE';

export const SUBMIT_LEAD_REQUEST = 'SUBMIT_LEAD_REQUEST';
export const SUBMIT_LEAD_SUCCESS = 'SUBMIT_LEAD_SUCCESS';
export const SUBMIT_LEAD_FAILURE = 'SUBMIT_LEAD_FAILURE';

import { combineReducers } from 'redux';
import { ADD_SELECTED_DEALER, REMOVE_SELECTED_DEALER, UPDATE_DEALER_LIST, CLEAR_DEALER_LIST, ADD_MULTIPLE_SELECTED_DEALER, CLEAR_SELECTED_DEALERS } from '../../action-types/dealers';
import { removeItemFromList } from '../../utils';

const selectedDealers = (state = {}, action) => {
  switch(action.type) {
    case ADD_SELECTED_DEALER:
      return [
        ...state,
        action.data.item
      ];
    case REMOVE_SELECTED_DEALER:
      return removeItemFromList(action.data.item, state);
    case CLEAR_SELECTED_DEALERS:
      return [];
    case ADD_MULTIPLE_SELECTED_DEALER:
      return [
        ...state,
        ...action.data
      ];
    default:
      return state;
  }
};

const dealerList = (state = [], action) => {
  switch (action.type) {
    case UPDATE_DEALER_LIST:
      return [ ...action.data ];
    case CLEAR_DEALER_LIST: // TODO Implement logic for error message when no dealers
      return [ ...state ];
    default:
      return state
  }
};

export default combineReducers({
  selectedDealers,
  dealerList,
});

/* eslint-disable no-console */
import axios from 'axios';
import { VEHICLE_LISTING_TYPE } from '@packages/fdpq-core/constants/app';

export default class BycService {
  static url;

  static apiKey;

  static configure(config, apiKey) {
    const baseUrl = config.useCdn ? config.fdpqCdnBaseUrl : config.fdpqApiBaseUrl;
    BycService.apiKey = config.apiKey || apiKey;
    BycService.url = `${baseUrl}/api/byc`;
  }

  static async getRecaptchaSiteKey() {
    const finalUrl = `${BycService.url}/recaptcha-sitekey`;
    return new Promise((resolve, reject) => {
      axios
        .get(finalUrl, {
          params: {
            apikey: BycService.apiKey,
          },
        })
        .then((response) => {
          if (response && response.data) {
            resolve(response.data);
          }
        })
        .catch((error) => {
          reject(error);
        });
    });
  }

  static async getDealerList({ vehicleMake, vehicleModel, vehicleTrim, vehicleYear, zipCode, vehicleListingType, hostPageName, leadFranchiseId, leadPartnerFranchiseId }) {
    const finalUrl = `${BycService.url}/dealerlist`;
    return new Promise((resolve, reject) => {
      axios
        .get(finalUrl, {
          params: {
            apikey: BycService.apiKey,
            entryzip: zipCode,
            vehicleyear: vehicleYear,
            vehiclemake: vehicleMake,
            vehiclemodel: vehicleModel,
            vehicletrim: vehicleTrim,
            vehiclelistingtype: vehicleListingType,
            pagename: hostPageName,
            leadPartnerFranchiseId: leadPartnerFranchiseId,
            leadFranchiseId: leadFranchiseId
          },
        })
        .then((response) => {
          if (response && response.data) {
            const dealerlist = [];
            for (let i = 0; i < response.data.length; i++) {
              dealerlist.push(response.data[i]);
            }
            resolve(dealerlist);
          }
        })
        .catch((error) => {
          reject(error);
        });
    });
  }

  static validateLead(data) {
    const finalUrl = `${BycService.url}/validatelead`;
    return new Promise((resolve, reject) => {
      axios
        .get(finalUrl, {
          params: {
            apikey: BycService.apiKey,
            firstname: data.firstName,
            lastname: data.lastName,
            email: data.email,
            phone: data.phone,
          },
        })
        .then((response) => {
          if (response && response.data) {
            resolve(response.data);
          }
        })
        .catch((error) => {
          reject(error);
        });
    });
  }

  static submitLead(leadInfo, coxAutoCorrelationId) {
    const finalUrl = `${BycService.url}/lead?apikey=${BycService.apiKey}`;
    const headers = {
      'x-coxauto-correlation-id': coxAutoCorrelationId,
    };

    return new Promise((resolve, reject) => {
      axios
        .post(finalUrl, JSON.stringify(leadInfo), { headers })
        .then((response) => {
          if (response && response.data) {
            console.log('success');
            console.log(response.data);
            resolve(response.data);
          }
        })
        .catch((error) => {
          console.error(error);
          reject(error);
        });
    });
  }
}

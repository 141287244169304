export const getQueryParamsFromUrl = () => {
  if (typeof window === 'undefined' || !window.location.search) {
    return null;
  }
  try {
    const url = new URL(window.location.href);
    const queryObject = {};
    for (const [key, value] of url.searchParams.entries()) {
      queryObject[key.toLowerCase()] = value;
    }
    return queryObject;
  } catch (e) {
    return null;
  }
};

export default getQueryParamsFromUrl;

/** @jsx jsx */
import { jsx, css } from '@emotion/core';
import Spacing from './spacing';

// const oneColMax = 12; //
const twoColMax = 8;

const Grid = {
  grid: css`
    @supports (display: grid) {
      align-items: start;
      display: grid;
      grid-auto-flow: inherit;
      grid-auto-columns: inherit;
      grid-template-columns: 1fr;
      grid-template-rows: auto;
    }
    @media only screen and (-ms-high-contrast: none) {
      display: flex;
    }
  `,
  one: css`
    @supports (display: grid) {
      grid-template-columns: 1fr;
    }
  `,
  two: css`
    @supports (display: grid) {
      grid-template-columns: 1fr ${Spacing.sidebarWidth};
    }
  `,
  close: css`
    @supports (display: grid) {
      grid-gap: ${Spacing.Close} ${Spacing.Medium};
    }
  `,
  related: css`
    @supports (display: grid) {
      grid-gap: ${Spacing.Large} ${Spacing.Medium};
    }
  `,
  separate: css`
    @supports (display: grid) {
      grid-gap: ${Spacing.Separate} ${Spacing.Medium};
    }
  `,
  span: ({ base = 1 }) => {
    const colspan = css`
      @supports (display: grid) {
        grid-column: span ${base};
      }

      @media only screen and (-ms-high-contrast: none) {
        ${base ? css`flex-basis: ${base / twoColMax * 100}%;` : ''}
      }
    `;
    return colspan;
  },
  fullWidth: function () {
    return css`
      @supports (display: grid) {
        grid-column: 1/ -1;
      }

      @media only screen and (-ms-high-contrast: none) {
        flex-basis: 100%;
      }
    `;
  },
};

export default Grid;

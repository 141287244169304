import React, { Component } from 'react';
import styled from '@emotion/styled';
import { colors } from '../../fdpq-core/config/vars';

const StyledButton = styled.button`
  ${({ theme }) => `
    height: 37px;
    width: 100%;
    padding: 0 10px;
    color: ${colors.global.white1};
    font-weight: ${theme.button.fontWeight};
    background: ${theme.button.background};
    font-size: ${theme.button.fontSize};
    border-radius: ${theme.button.borderRadius};
    border: ${theme.button.border};
    user-select: none;
    text-transform: ${theme.button.textTransform};
    :hover{
      background: ${theme.button.hover.background};
    }
    :focus {
      outline: none;
    }
  `}
`;

export default class Button extends Component {
  render() {
    const { type, style, onClick, onButtonPress, children, name } = this.props;

    return (
      <StyledButton
        name={name}
        onClick={onClick}
        style={{ ...style, cursor: 'pointer' }}
        type={type}
      >
        {children}
      </StyledButton>
    );
  }
}

import React from 'react';
import { connect } from 'react-redux';
import styled from '@emotion/styled';
import Checkbox from '@argo/ui-elements/Checkbox';
import CheckmarkIcon from '@argo/ui-elements/icons/CheckmarkIcon';
import FlexBox from '@argo/principles/FlexBox';
import Paragraph from '@argo/ui-elements/Paragraph';
import size from '@argo/principles/constants/size';
import { getMilesText, getAddressLine2Text, getNameWithEllipses } from '@packages/fdpq-common/containers/DealerInfoHelpers';
import { useAdaptiveRender } from '@argo/utils';
import Breakpoints from '@argo/principles/constants/breakpoints';
import { mapStateToProps, mapDispatchToProps } from '../containers/DealerList';
import { pageLimitDefault } from './dealerListArgoHelpers';

const DEALER_MIN_HEIGHT = '120px';

const DealerOption = styled.div`
  display: inline-block;
  margin-top: ${size.micro}px;
  ${({ minHeight }) => `
    min-height: ${minHeight};
  `}
`;

const CheckboxContainer = styled.div`
  display: flex;
  width: 15px;
`;

const flexBoxMobileTablet = [
  '1 0 100%',
  '1 0 100%',
  '1 0 100%',
];

const flexBoxDesktop = [
  '0 0 33%',
  '0 0 33%',
  '0 0 33%',
];

const DealerListArgo = ({ onDealerSelected, displayDealerList = [], selectedDealers = [], isSmallOrMediumViewPort = false }) => {
  const { width } = useAdaptiveRender();
  const minHeight = width >= Breakpoints.lg ? DEALER_MIN_HEIGHT : '';
  return (<FlexBox
    alignItems="flex-start"
    default={flexBoxMobileTablet}
    xs={flexBoxMobileTablet}
    sm={flexBoxMobileTablet}
    md={flexBoxMobileTablet}
    lg={flexBoxDesktop}
    xl={flexBoxDesktop}
    max={flexBoxDesktop}
    flexWrap="wrap"
    horizontalSpacing={isSmallOrMediumViewPort ? 'sm' : 'md'}
    verticalSpacing='sm'
  >
    {displayDealerList.map((dealerData, index) => (
      <FlexBox
        key={dealerData.displayItem.dealerPosition}
        alignItems="flex-start"
        id={`dealerInfo${index}`}
      >
        {
          dealerData.isPreSelected
            ? (<CheckmarkIcon color="brightBlue" size="16" />)
            : (<CheckboxContainer>
              <Checkbox
                id={`dealerkey${dealerData.displayItem.dealerPosition}`}
                name={`dealerkey${dealerData.displayItem.dealerPosition}`}
                isChecked={dealerData.isSelected}
                handleChange={(e) => {
                  const dealer = {
                    item: dealerData.displayItem,
                    isSelected: e.target.checked,
                  };

                  if (selectedDealers.length >= pageLimitDefault && !selectedDealers.includes(dealerData)) {
                    e.target.checked = false;
                  }

                  onDealerSelected(dealer);
                }}
              />
            </CheckboxContainer>
            )}
        <DealerOption minHeight={minHeight} data-lead-source={dealerData.displayItem.leadSource} data-vendor-list={dealerData.displayItem.vendorList}>
          <Paragraph
            defaultMargin={true}
            textAlign="left"
            type="body"
          >
            <b>{getNameWithEllipses(dealerData.displayItem.franchiseName)}</b>
          </Paragraph>
          <Paragraph
            defaultMargin={true}
            textAlign="left"
            type="body"
          >
            {dealerData.displayItem.franchiseAddress1}
          </Paragraph>
          <Paragraph
            defaultMargin={true}
            textAlign="left"
            type="body"
          >
            {
              getAddressLine2Text(dealerData.displayItem.franchiseCity, dealerData.displayItem.franchiseState, dealerData.displayItem.franchiseZip)
            }
          </Paragraph>
          <Paragraph
            defaultMargin={true}
            textAlign="left"
            type="body"
          >
            {getMilesText(dealerData.displayItem.distance)}
          </Paragraph>
          <Paragraph
            defaultMargin={true}
            textAlign="left"
            type="body"
            style={{ fontSize: '10px' }}
          >
          </Paragraph>
        </DealerOption>
      </FlexBox>
    ))}
  </FlexBox>
  );
};

export default connect(mapStateToProps, mapDispatchToProps)(DealerListArgo);

import { combineReducers } from 'redux';

const config = (state = {}, action) => state;

const theme = (state = {}, action) => (action.type === 'CHANGE_THEME' ? { ...state, ...action.data } : state);

const floodlightTagging = (state = {}, action) => state;

const texts = (state = {}, action) => state;

const links = (state = {}, action) => state;

export default combineReducers({
  config,
  theme,
  floodlightTagging,
  texts,
  links,
});

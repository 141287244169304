import { combineReducers } from 'redux';
import {
  GET_DEALER_LIST_REQUEST,
  GET_DEALER_LIST_SUCCESS,
  GET_DEALER_LIST_FAILURE,
  VALIDATE_LEAD_REQUEST,
  VALIDATE_LEAD_SUCCESS,
  VALIDATE_LEAD_FAILURE,
  SUBMIT_LEAD_REQUEST,
  SUBMIT_LEAD_SUCCESS,
  SUBMIT_LEAD_FAILURE,
} from '../../action-types/dealers';

const getDealerList = (state = {}, action) => {
  switch (action.type) {
    case GET_DEALER_LIST_REQUEST:
      return { ...state, isLoading: true };
    case GET_DEALER_LIST_SUCCESS:
    case GET_DEALER_LIST_FAILURE:
      return { ...state, isLoading: false };
    default:
      return state;
  }
};

const validateLead = (state = {}, action) => {
  switch (action.type) {
    case VALIDATE_LEAD_REQUEST:
      return { ...state, isLoading: true };
    case VALIDATE_LEAD_SUCCESS:
    case VALIDATE_LEAD_FAILURE:
      return { ...state, isLoading: false };
    default:
      return state;
  }
};

const submitLead = (state = {}, action) => {
  switch (action.type) {
    case SUBMIT_LEAD_REQUEST:
      return { ...state, isLoading: true };
    case SUBMIT_LEAD_SUCCESS:
    case SUBMIT_LEAD_FAILURE:
      return { ...state, isLoading: false };
    default:
      return state;
  }
};

export default combineReducers({
  getDealerList,
  validateLead,
  submitLead,
});

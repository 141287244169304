/** @jsx jsx */
import { jsx } from '@emotion/core';

import { Header, Page, PageContent } from '@packages/fdpq-common';
import { VehicleSelector } from '@packages/fdpq-vehicle-selector';
import { DealerSelector } from '@packages/fdpq-dealer-selector';
import { ContactForm } from '@packages/fdpq-contact-form';
import { ThankYouPage } from '@packages/fdpq-thank-you-page';
import { PAGE_NAME } from '@packages/fdpq-core/constants/app';

const showPage = (currentPage) => {
  switch (currentPage) {
    case PAGE_NAME.TRADEIN_VEHICLE_SELECTOR:
      return <VehicleSelector />;
    case PAGE_NAME.VEHICLE_SELECTOR:
      return <VehicleSelector />;
    case PAGE_NAME.DEALER_SELECTOR:
      return <DealerSelector />;
    case PAGE_NAME.CONTACT_FORM:
      return <ContactForm />;
    case PAGE_NAME.THANK_YOU_PAGE:
      return <ThankYouPage />;
    default:
      return <VehicleSelector />;
  }
};

const getPageHeight = currentPage => (currentPage === PAGE_NAME.VEHICLE_SELECTOR ? { height: 'auto' } : {});

const FdpqWidget = ({ navigation, app: { texts, config: { listingTypeConfig: { vehicleListingType } } } }) => (
  <Page id="syn-fdpq" style={getPageHeight(navigation.currentPage)}>
    <Header title={texts.global.headerText} subtitle={texts.global.headerSubText} vehicleListingType={vehicleListingType} />
    <PageContent id='test-page-content'>
      {showPage(navigation.currentPage)}
    </PageContent>
  </Page>
);

export default FdpqWidget;

import { SUMBIT_PIXALL_ID } from '../../action-types/pixall';

const pixall = (state = {}, action) => {
  switch (action.type) {
    case SUMBIT_PIXALL_ID:
      return {
        ...state,
        pixallPrimaryId: action.data.pixallPrimaryId,
        pixallSecondaryId: action.data.pixallSecondaryId
      }
    default:
      return state;
  }
};

export default pixall;
import { initDealerSelectorPage, submitDealerForm, clickDealerNextButton } from '@packages/fdpq-core/actions/dealers';
import { NO_DEALER_SELECTED, MORE_THAN_3_DEALER_SELECTED } from '@packages/fdpq-core/constants/errorCodes';
import { mapStateToProps as mapVehicleInfoStateToProps } from '@packages/fdpq-common/containers/VehicleInfo';
import { toVehicleSelector, clickChangeZip, clickChangeVehicle } from '@packages/fdpq-core/actions/navigation';

export const mapDispatchToProps = ({
  onButtonPress: clickDealerNextButton,
  onNextPageSelected: submitDealerForm,
  onClickChangeZip: clickChangeZip,
  onChangeZipSelected: toVehicleSelector,
  onClickVehicleLink: clickChangeVehicle,
  onChangeVehicleSelected: toVehicleSelector,
  initDealerSelectorPage,
});

export const mapStateToProps = (state) => {
  const error = state.errors[NO_DEALER_SELECTED] || state.errors[MORE_THAN_3_DEALER_SELECTED];
  const { vehicleTitle, vehicleTrimName } = mapVehicleInfoStateToProps(state);
  return {
    dealerList: state.dealers.dealerList,
    selectedDealers: state.dealers.selectedDealers,
    hasError: !!error,
    errorMessage: error ? error.errorMessage : '',
    description: state.app.texts.global.description,
    listingTypeConfig: state.app.config.listingTypeConfig,
    vehicleTitle,
    vehicleTrimName,
    zipCode: state.location?.zipcode || '',
  };
};
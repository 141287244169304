import ResearchableApiService from '@packages/fdpq-core/services/ResearchableApiService';
import { PAGE_NAME, VEHICLE_LISTING_TYPE } from '@packages/fdpq-core/constants/app';
import getListingTypeConfig from '@packages/fdpq-core/config/listingTypeConfig';
import { getPreselectedDealers } from '@packages/fdpq-core/utils/dealerUtil';
import { text } from '../../fdpq-core/config/vars';
import BycService from '../../fdpq-core/services/BycService';
import LscService from '../../fdpq-core/services/LscService';

export const getCurrentPage = async ({ apiKey, selectedVehicle, selectedTradeinVehicle, zipCode, vehicleListingType, leadFranchiseId, leadPartnerFranchiseId, dealerList }) => {
  const { makeName, modelName, trimName, yearId } = selectedVehicle;
  const tradein = selectedTradeinVehicle;
  let currentPage = '';

  if (vehicleListingType === VEHICLE_LISTING_TYPE.TRADEIN) {
    if (apiKey && makeName && modelName && trimName && yearId) {
      if (zipCode) {
        currentPage = PAGE_NAME.DEALER_SELECTOR;
      } else {
        currentPage = PAGE_NAME.VEHICLE_SELECTOR;
      }
    } else if (apiKey && tradein && tradein.makeName && tradein.modelName && tradein.trimName && tradein.yearId) {
      currentPage = PAGE_NAME.VEHICLE_SELECTOR;
    } else {
      currentPage = PAGE_NAME.TRADEIN_VEHICLE_SELECTOR;
    }
  } else if (apiKey && makeName && modelName && trimName && zipCode) {
    if (dealerList.length === 1) {
      if (leadFranchiseId.length > 0 || leadPartnerFranchiseId.length > 0) {
        currentPage = PAGE_NAME.CONTACT_FORM;
      } else {
        currentPage = PAGE_NAME.DEALER_SELECTOR;
      }
    } else {
      if (leadFranchiseId.length > 0 || leadPartnerFranchiseId.length > 0) {
        if (dealerList.length === 0) {
          currentPage = PAGE_NAME.VEHICLE_SELECTOR;
        } else {
          currentPage = PAGE_NAME.DEALER_SELECTOR;
        }
      } else {
        currentPage = PAGE_NAME.DEALER_SELECTOR;
      }

    }
  } else {
    currentPage = PAGE_NAME.VEHICLE_SELECTOR;
  }
  return currentPage;
};
// based on the query parameters, determine if we are getting atc or kbb vehicle query parameters
const getVehicleParameterFormat = (queryParams) => {
  let vehicleParameterFormat = '';

  const queryParameterKeys = Object.keys(queryParams);
  // eslint-disable-next-line no-restricted-syntax
  for (const queryParameterKey of queryParameterKeys) {
    if (queryParameterKey.toLowerCase().startsWith('kbb')) {
      vehicleParameterFormat = 'kbb';
      break;
    } else if (queryParameterKey.toLowerCase().startsWith('atc')) {
      vehicleParameterFormat = 'atc';
      break;
    }
  }

  if (vehicleParameterFormat === '') vehicleParameterFormat = 'kbb';

  return vehicleParameterFormat;
};

export const getSelectedVehicle = async ({ queryParams, listingTypeConfig }, initialData) => {
  const {
    apikey,
    atcmakename,
    atcmakecode,
    atcmodelcode,
    atctrimname,
    vehicleyear,
    kbbmakename,
    kbbmodelname,
    kbbmakeid,
    kbbmodelid,
    kbbtrimid,
    kbbvehicleid,
    vehiclelistingtype,
    leadpartnerfranchiseid,
    leadfranchiseid
  } = queryParams;

  // determine if the query parameters are for KBB vehicle data or ATC vehicle data
  const vehicleParameterFormat = getVehicleParameterFormat(queryParams);
  const leadPartnerFranchiseId = queryParams.leadpartnerfranchiseid || '';
  const leadFranchiseId = queryParams.leadfranchiseid || '';
  const resolvedListingType = listingTypeConfig || getListingTypeConfig(vehiclelistingtype || '');
  let vehicleDetails = {};
  if (vehicleParameterFormat.toLowerCase() === 'atc') {
    vehicleDetails = await ResearchableApiService.getAtcMappedVehicleData({
      apiKey: apikey,
      vehicleYearId: decodeURI(vehicleyear),
      atcMakeName: decodeURI(atcmakename),
      atcMakeCode: decodeURI(atcmakecode),
      atcModelCode: decodeURI(atcmodelcode),
      atcTrimName: decodeURI(atctrimname),
      vehicleListingType: resolvedListingType.vehicleListingType,
    });
  } else if (vehicleParameterFormat.toLowerCase() === 'kbb') {
    vehicleDetails = await ResearchableApiService.getKbbVehicleData({
      apiKey: apikey,
      kbbYearId: decodeURI(vehicleyear),
      kbbMakeId: decodeURI(kbbmakeid),
      kbbModelId: decodeURI(kbbmodelid),
      kbbMakeName: decodeURI(kbbmakename),
      kbbModelName: decodeURI(kbbmodelname),
      kbbTrimId: decodeURI(kbbtrimid),
      kbbVehicleId: decodeURI(kbbvehicleid),
      leadPartnerFranchiseId: decodeURI(leadpartnerfranchiseid),
      leadFranchiseId: decodeURI(leadfranchiseid),
      vehicleListingType: resolvedListingType.vehicleListingType,
    });
  }
  let makeSelections;
  if (leadFranchiseId.length > 0 || leadPartnerFranchiseId.length > 0) {
    LscService.configure({...initialData.config}, apikey);
    const getMakeListRequest = {
      leadFranchiseId,
      leadPartnerFranchiseId
    };
    var makeList = await LscService.getMakeList(getMakeListRequest);
    const mapMakeList = makeList.map(x => {
      const findMake = vehicleDetails.data.makeSelections.filter(y=>y.makeName.toLowerCase() === x.makeName.toLowerCase());
      if (findMake.length > 0) {
        return {
          isSelected: findMake[0].isSelected,
          makeId: findMake[0].makeId,
          makeName: findMake[0].makeName,
        }
      }
    });
    makeSelections = mapMakeList.filter(x=> x !== undefined) || [];
  } else {
    makeSelections = vehicleDetails.data.makeSelections || [];
  }
  const yearSelections = vehicleDetails.data.yearSelections || [];
  let modelSelections = vehicleDetails.data.modelSelections || [];
  const findMake = vehicleDetails.data.makeSelections.find(y=>y.makeName.toLowerCase() === atcmakename?.toLowerCase());
  if(findMake) {
    const models = await ResearchableApiService.getModels(findMake.makeId, vehicleyear, resolvedListingType.vehicleListingType);
    const results = modelSelections.filter(x => models.some(m => m.modelId === x.modelId));
    modelSelections = results;
  }
    
  const trimSelections = vehicleDetails.data.trimSelections || [];
  const selectedVehicle = {};

  const yearOptions = yearSelections.sort((a, b) => b.yearId - a.yearId).map(({ yearName, yearId, isSelected }) => {
    if (isSelected) {
      selectedVehicle.yearId = yearId;
      selectedVehicle.yearName = yearName;
    }
    return { yearName, yearId };
  });

  const makeOptions = makeSelections?.map(({ makeName, makeId, isSelected }) => {
    if (isSelected) {
      selectedVehicle.makeId = makeId;
      selectedVehicle.makeName = makeName;
    }
    return { makeName, makeId };
  });

  const modelOptions = modelSelections.map(
    ({ modelName, modelId, isSelected }) => {
      if (isSelected) {
        selectedVehicle.modelId = modelId;
        selectedVehicle.modelName = modelName;
      }
      return { modelName, modelId };
    },
  );

  const trimOptions = trimSelections.map((trim) => {
    const { trimName, trimId, yearId, isSelected } = trim;
    if (isSelected) {
      selectedVehicle.trimId = trimId;
      selectedVehicle.trimName = trimName;
      if (!selectedVehicle.yearId) {
        selectedVehicle.yearId = yearId;
      }
    }
    return { ...trim, trimName: trimName || 'Base' };
  });

  return {
    selectedVehicle,
    yearOptions,
    makeOptions,
    modelOptions,
    trimOptions,
  };
};

export const getSelectedTradeinVehicle = async ({ queryParams }) => {
  const {
    apikey,
    atcmakename,
    atcmakecode,
    atcmodelcode,
    atctrimname,
    vehicleyear,
    kbbmakename,
    kbbmodelname,
    kbbmakeid,
    kbbmodelid,
    kbbtrimid,
    kbbvehicleid,
    leadpartnerfranchiseid,
    leadfranchiseid,
  } = queryParams;

  // determine if the query parameters are for KBB vehicle data or ATC vehicle data
  const vehicleParameterFormat = getVehicleParameterFormat(queryParams);
  const resolvedListingType = VEHICLE_LISTING_TYPE.USED;

  let vehicleDetails = {};
  if (vehicleParameterFormat.toLowerCase() === 'atc') {
    vehicleDetails = await ResearchableApiService.getAtcMappedVehicleData({
      apiKey: apikey,
      vehicleYearId: decodeURI(vehicleyear),
      atcMakeName: decodeURI(atcmakename),
      atcMakeCode: decodeURI(atcmakecode),
      atcModelCode: decodeURI(atcmodelcode),
      atcTrimName: decodeURI(atctrimname),
      vehiclelistingtype: resolvedListingType,
    });
  } else if (vehicleParameterFormat.toLowerCase() === 'kbb') {
    vehicleDetails = await ResearchableApiService.getKbbVehicleData({
      apiKey: apikey,
      kbbYearId: decodeURI(vehicleyear),
      kbbMakeId: decodeURI(kbbmakeid),
      kbbModelId: decodeURI(kbbmodelid),
      kbbMakeName: decodeURI(kbbmakename),
      kbbModelName: decodeURI(kbbmodelname),
      kbbTrimId: decodeURI(kbbtrimid),
      kbbVehicleId: decodeURI(kbbvehicleid),
      leadPartnerFranchiseId: decodeURI(leadpartnerfranchiseid),
      leadFranchiseId: decodeURI(leadfranchiseid),
      vehicleListingType: resolvedListingType,
    });
  }

  const yearSelections = vehicleDetails.data.yearSelections || [];
  const makeSelections = vehicleDetails.data.makeSelections || [];
  const modelSelections = vehicleDetails.data.modelSelections || [];
  const trimSelections = vehicleDetails.data.trimSelections || [];
  const selectedTradeinVehicle = {};

  const tradeinYearOptions = yearSelections.sort((a, b) => b.yearId - a.yearId).map(({ yearName, yearId, isSelected }) => {
    if (isSelected) {
      selectedTradeinVehicle.yearId = yearId;
      selectedTradeinVehicle.yearName = yearName;
    }
    return { yearName, yearId };
  });

  const tradeinMakeOptions = makeSelections.map(({ makeName, makeId, isSelected }) => {
    if (isSelected) {
      selectedTradeinVehicle.makeId = makeId;
      selectedTradeinVehicle.makeName = makeName;
    }
    return { makeName, makeId };
  });

  const tradeinModelOptions = modelSelections.map(
    ({ modelName, modelId, isSelected }) => {
      if (isSelected) {
        selectedTradeinVehicle.modelId = modelId;
        selectedTradeinVehicle.modelName = modelName;
      }
      return { modelName, modelId };
    },
  );

  const tradeinTrimOptions = trimSelections.map((trim) => {
    const { trimName, trimId, yearId, isSelected } = trim;
    if (isSelected) {
      selectedTradeinVehicle.trimId = trimId;
      selectedTradeinVehicle.trimName = trimName;
      if (!selectedTradeinVehicle.yearId) {
        selectedTradeinVehicle.yearId = yearId;
      }
    }
    return { ...trim, trimName: trimName || 'Base' };
  });

  return {
    selectedTradeinVehicle,
    tradeinYearOptions,
    tradeinMakeOptions,
    tradeinModelOptions,
    tradeinTrimOptions,
  };
};

export const getInitialState = async ({ initialData, queryParams }) => {
  try {
    if (queryParams && queryParams.apikey) {
      const apiKey = queryParams.apikey || '';
      const zipCode = queryParams.zipcode || '';
      const leadPartnerFranchiseId = queryParams.leadpartnerfranchiseid || '';
      const leadFranchiseId = queryParams.leadfranchiseid || '';
      const hostPageName = queryParams.pagename || ''; // ATC needs to pass in query param with key of pageName
      const config = { ...initialData.config, apiKey, hostPageName };
      if (queryParams.usecdn || queryParams.useCdn) {
        config.useCdn = !((queryParams.usecdn === 'false' || queryParams.useCdn === 'false'));
      }
      if (queryParams.origin) {
        config.origin = queryParams.origin;
      }

      ResearchableApiService.configure(config, apiKey);
      let vehicleDetails = {};
      if (queryParams.kbbvehicleid) {
        vehicleDetails = await ResearchableApiService.getVehicleById(queryParams.kbbvehicleid);
      }
      const yearId = vehicleDetails ? vehicleDetails.yearId : '';
      const listingTypeConfig = getListingTypeConfig(queryParams.vehiclelistingtype || '', yearId);
      const { vehicleListingType } = listingTypeConfig;

      const vehicleOptions = await getSelectedVehicle({ queryParams, listingTypeConfig }, initialData);
      const { selectedVehicle } = vehicleOptions;

      var tradeinVehicleOptions = [];
      if (vehicleListingType === VEHICLE_LISTING_TYPE.TRADEIN){
        tradeinVehicleOptions = await getSelectedTradeinVehicle({ queryParams, listingTypeConfig });
      }
      const { selectedTradeinVehicle } = tradeinVehicleOptions;
      const {dealerList, preselectedDealers} = await initStateDealers(zipCode, selectedVehicle, vehicleListingType, hostPageName, initialData, apiKey, leadFranchiseId, leadPartnerFranchiseId);
      const currentPage = await getCurrentPage({
        apiKey,
        selectedVehicle,
        selectedTradeinVehicle,
        zipCode,
        vehicleListingType,
        leadFranchiseId,
        leadPartnerFranchiseId,
        dealerList
      });
      const initialState = {
        initialData: {
          ...initialData,
          config: {
            ...initialData.config,
            ...config,
            queryParams,
            listingTypeConfig,
          },
          texts: {
            global: {
              headerText: listingTypeConfig.global.headerText,
              headerSubText: listingTypeConfig.global.headerSubText,
              description: {
                title: listingTypeConfig.global.description.title,
                text: text.global.subtitleText,
              },
              vehicleSelectorView: text.global.vehicleSelectorView,
            },
          },
          vehicles: {
            selectedVehicle: vehicleOptions.selectedVehicle,
            yearOptions: vehicleOptions.yearOptions,
            makeOptions: vehicleOptions.makeOptions,
            modelOptions: vehicleOptions.modelOptions,
            trimOptions: vehicleOptions.trimOptions,
            selectedTradeinVehicle: tradeinVehicleOptions.selectedTradeinVehicle,
            tradeinYearOptions: tradeinVehicleOptions.tradeinYearOptions,
            tradeinMakeOptions: tradeinVehicleOptions.tradeinMakeOptions,
            tradeinModelOptions: tradeinVehicleOptions.tradeinModelOptions,
            tradeinTrimOptions: tradeinVehicleOptions.tradeinTrimOptions,
          },
          dealers: { dealerList: dealerList, selectedDealers: preselectedDealers },
          location: {
            zipcode: zipCode,
          },
          navigation: {
            currentPage,
          },
        },
        shouldRenderWidget: true,
      };
      if (initialState.initialData && initialState.initialData.config) {
        initialState.initialData.config.apiKey = apiKey;
      }
      return initialState;
    }
  } catch (e) {
    console.error(e); // eslint-disable-line no-console
    const apiKey = queryParams.apikey;
    const config = { ...initialData.config, apiKey };
    return {
      initialData: {
        ...initialData,
        config: {
          ...initialData.config,
          ...config,
          queryParams,
        },
      },
      shouldRenderWidget: false,
      hasError: true,
    };
  }
  return {};
};

/**
 * Init dealers state
 * @param {*} zipCode
 * @param {*} selectedVehicle
 * @param {*} vehicleListingType
 * @param {*} hostPageName
 * @param {*} initialData
 * @param {*} apiKey
 */
async function initStateDealers(zipCode, selectedVehicle, vehicleListingType, hostPageName, initialData, apiKey, leadFranchiseId, leadPartnerFranchiseId) {
  if (selectedVehicle.yearId > 0 && selectedVehicle.makeName?.length > 0 && selectedVehicle.modelName?.length > 0 && selectedVehicle.trimName?.length > 0 && zipCode > 0 && (leadPartnerFranchiseId.length > 0 || leadFranchiseId.length > 0)) {
    const requestGetDealerList = {
      zipCode: zipCode,
      vehicleYear: selectedVehicle.yearId,
      vehicleMake: selectedVehicle.makeName,
      vehicleModel: selectedVehicle.modelName,
      vehicleTrim: selectedVehicle.trimName,
      vehicleListingType,
      hostPageName,
      leadFranchiseId: leadFranchiseId,
      leadPartnerFranchiseId: leadPartnerFranchiseId
    };
    BycService.configure({ ...initialData.config }, apiKey);
    const dealerList = await BycService.getDealerList(requestGetDealerList);
    const preselectedDealers = getPreselectedDealers(dealerList);
    return { dealerList, preselectedDealers };
  }

  return { dealerList: [], preselectedDealers: [] };
}

import slugify from 'slugify';
import {
  submitContactInfo,
  clickContactSubmitButton,
  getPixallIdsPostMessage,
} from '@packages/fdpq-core/actions/contact';
import { mapStateToProps as mapVehicleInfoStateToProps } from '@packages/fdpq-common/containers/VehicleInfo';
import { CONTACT_FORM_ERRORS } from '../../fdpq-core/action-types/contact';

const specialCharacters = (input) => {
  var regx = /^[a-zA-Z0-9 '-]*$/;
  const isValid = input && input.trim().length > 0 && regx.test(input);
  const isRepeat  = (isValid && (input.split('-').length > 2 || input.split("'").length > 2));
  if (isRepeat) {
    return false;
  }
  return isValid;
}
const validateFirstName = firstNameInput => {
  const isValid = specialCharacters(firstNameInput);
  return isValid;
};

const validateLastName = lastNameInput => {
  const isValid = specialCharacters(lastNameInput);
  return isValid;
};

const validatePhone = (phoneInput) => {
  const regxPhone = /\d{10}/;
  const isValid = regxPhone.test(phoneInput);
  return isValid;
};

const validateEmail = (emailInput) => {
  let isValid = false;
  const regxEmail = /^[A-Za-z0-9._%+-]+@(?:[A-Za-z0-9-]+\.)+([A-Za-z]{2,4})$/;
  if (emailInput.trim.length <= 50 && regxEmail.test(emailInput)) {
    isValid = true;
  }
  return isValid;
};

export const mapValidationToProps = {
  mapPropsToValues: () => ({
    firstName: '',
    lastName: '',
    email: '',
    phoneNumber: '',
  }),
  validate: (values) => {
    console.log('validate');
    const errors = {};
    if (!validateFirstName(values.firstName)) {
      errors['firstName'] = true;
    }
    if (!validateLastName(values.lastName)) {
      errors['lastName'] = true;
    }
    if (!validateEmail(values.email)) {
      errors['email'] = true;
    }
    if (!validatePhone(values.phoneNumber)) {
      errors['phoneNumber'] = true;
    }
    return errors;
  },
  handleSubmit: async (values, state) => {
    console.log('handleSubmit');
    const {
      setErrors,
      setSubmitting,
      props: { onSubmit },
    } = state;
    const errors = {};
    if (!validateFirstName(values.firstName)) {
      errors['firstName'] = true;
    }
    if (!validateLastName(values.lastName)) {
      errors['lastName'] = true;
    }
    if (!validateEmail(values.email)) {
      errors['email'] = true;
    }
    if (!validatePhone(values.phoneNumber)) {
      errors['phoneNumber'] = true;
    }
    console.log('errorssubmit', errors);
    if (Object.keys(errors).length > 0) {
      setSubmitting(false);
      setErrors(errors);
    } else {
      // send contact info to action to handle backend validation and lead submission
      const data = {
        firstName: values.firstName,
        lastName: values.lastName,
        email: values.email,
        phone: values.phoneNumber,
      };
      onSubmit(data);
    }
  },
};

const disclaimerSelector = (state) => {
  const { selectedVehicle } = state.vehicles;
  const { config } = state.app;
  let disclaimerLink = state.app.theme.contactFormView.disclaimer.disclaimerLinkUrl;
  const year = selectedVehicle ? selectedVehicle.yearId : null;
  const make = selectedVehicle ? selectedVehicle.makeName : null;
  const model = selectedVehicle ? selectedVehicle.modelName : null;
  if (config.appType === 'KBB' && year && make && model) {
    disclaimerLink = `https://www.kbb.com/${slugify(make)}/${slugify(model)}/${year}/?section=privacypolicy`.toLowerCase();
  }
  return disclaimerLink;
};

export const mapDispatchToProps = {
  onSubmit: submitContactInfo,
  onButtonPress: clickContactSubmitButton,
};

export const mapStateToProps = (state) => {
  const { errors } = state;
  if (errors[CONTACT_FORM_ERRORS]) {
    return {
      firstNameError: errors[CONTACT_FORM_ERRORS].firstName,
      lastNameError: errors[CONTACT_FORM_ERRORS].lastName,
      emailError: errors[CONTACT_FORM_ERRORS].email,
      phoneError: errors[CONTACT_FORM_ERRORS].phone,
      listingTypeConfig: state.app.config.listingTypeConfig,
    };
  }
  const {
    vehicleTitle,
    vehicleTrimName,
  } = mapVehicleInfoStateToProps(state);
  const disclaimerLink = disclaimerSelector(state);

  return {
    listingTypeConfig: state.app.config.listingTypeConfig,
    vehicleTitle,
    vehicleTrimName,
    disclaimerLink,
  };
};

if (typeof window !== 'undefined') {
  window.addEventListener('message', getPixallIdsPostMessage);
}

import { SET_ERROR,
  REMOVE_ERROR,
} from '../action-types/errors';
import { EMPTY_DEALERLIST,
  MORE_THAN_3_DEALER_SELECTED,
  NO_DEALER_SELECTED,
} from '../constants/errorCodes';

export const removeErrors = errorList => (dispatch, getState) => {
  errorList.forEach((error) => {
    const { errors } = getState();
    if (error in errors) {
      dispatch({
        type: REMOVE_ERROR,
        data: {
          errorCode: error,
        },
      });
    }
  });
};

export const moreThan3DealerSelectedError = () => (dispatch) => {
  dispatch({
    type: SET_ERROR,
    data: {
      errorCode: MORE_THAN_3_DEALER_SELECTED,
      errorMessage: 'Please uncheck a dealer before choosing a new one.',
    },
  });
};

export const noDealerSelectedError = () => (dispatch) => {
  dispatch({
    type: SET_ERROR,
    data: {
      errorCode: NO_DEALER_SELECTED,
      errorMessage: 'Please choose at least one dealer.',
    },
  });
};

export const emptyDealerListError = (showError = true) => (dispatch) => {
  dispatch({
    type: SET_ERROR,
    data: {
      errorCode: EMPTY_DEALERLIST,
      showError,
      descriptionTitle: "We're Sorry!",
      descriptionDetail: "We couldn't find any participating dealers in that ZIP code. Please choose another ZIP code or another vehicle.",
    },
  });
};

import { UPDATE_CONTACT_INFO } from '../../action-types/contact';

const contact = (state = {}, action) => {
    switch (action.type) {
        case UPDATE_CONTACT_INFO:
        return {
            ...state,
            firstName: action.data.firstName,
            lastName: action.data.lastName,
            email: action.data.email,
            phone: action.data.phone
            }
        default:
        return state;
    }
};

export default contact;



import { VEHICLE_LISTING_TYPE } from '@packages/fdpq-core/constants/app';
import {
  colors,
  fonts,
  initialListingTypeConfig,
  links,
  text,
  pageContent,
} from '../vars';

const isTradeIn = initialListingTypeConfig.vehicleListingType === VEHICLE_LISTING_TYPE.TRADEIN;

const theme = {
  global: {
    fontFamily: fonts.kbb.fontFamily,
    pageContent: {
      backgroundColor: isTradeIn ? colors.global.gray10 : colors.global.white1,
      border: isTradeIn ? 'none' : pageContent.global.border,
      borderRadius: pageContent.global.borderRadius,
    },
  },
  header: {
    blankBackgroundColor: colors.global.gray10,
    backgroundColor: isTradeIn ? colors.global.gray10 : colors.kbb.blue1,
    color: isTradeIn ? colors.global.gray11 : colors.global.white1,
    fontSize: '24px',
    textTransform: 'initial',
  },
  keyline: {
    borderColor: isTradeIn ? colors.global.gray10 : colors.kbb.gray1,
  },
  button: {
    fontWeight: '400',
    fontSize: '16px',
    color: colors.global.white1,
    borderRadius: '4px',
    background: `linear-gradient(${colors.kbb.blue1},${colors.kbb.blue3})`,
    height: '37px',
    width: '100%',
    border: '1px solid transparent',
    cursor: 'pointer',
    justifyContent: 'center',
    desktopGridColumn: isTradeIn ? '9 / end' : '5 / 9',
    textTransform: 'initial',
    hover: {
      background: `linear-gradient(${colors.kbb.blue3},${colors.kbb.blue1});`,
    },
  },
  text: {
    color: colors.global.blue4,
    subtitle: colors.global.blue2,
  },
  box: {
    backgroundColorDisabled: colors.kbb.gray2,
    border: `1px solid ${colors.global.gray1}`,
    borderColor: `${colors.global.gray1}`,
    borderRadius: '3px',
    baseGlowingColor: colors.kbb.blue1,
    errorGlowingColor: colors.global.red1,
    height: '40px',
    lineHeight: '20px',
  },
  description: {
    title: {
      fontSize: '16px',
      color: colors.global.black1,
    },
    subtitle: {
      color: colors.global.black1,
    },
  },
  changeZipButton: {
    color: colors.kbb.blue1,
  },
  vehicleInfo: {
    vehicleModel: {
      color: colors.global.black1,
      fontWeight: isTradeIn ? '400' : '900',
      fontSize: '14px',
      marginBottom: isTradeIn ? '0px' : '7px',
    },
    vehicleTrim: {
      color: colors.global.black1,
    },
    changeVehicleLink: {
      color: colors.kbb.blue1,
    },
  },
  keylineShadow: 'none',
  // step 1
  // step 2
  dealerSelectorView: {
    dealerBlock: {
      checkboxBackgroundColor: colors.kbb.blue2,
      dealershipInfo: {
        color: colors.global.black1,
        distanceDiv: {
          color: colors.global.gray4,
        },
      },
    },
    pagination: {
      pageItem: {
        fontSize: '12px',
      },
      fontWeight: '900',
      selectedPage: {
        backgroundColor: 'transparent',
        color: colors.global.black1,
      },
      notSelectedPage: {
        backgroundColor: 'transparent',
        color: colors.kbb.blue1,
      },
    },
  },
  // step 3
  contactFormView: {
    disclaimer: {
      disclaimerText: text.kbb.contactFormView.disclaimer.disclaimerText,
      linkText: text.kbb.contactFormView.disclaimer.linkText,
      linkTextDecoration: 'none',
      disclaimerLinkUrl: links.kbb.disclaimerLinkUrl,
    },
  },
  // step 4
  thankYouPage: {
    checkMarkColor: colors.global.green1,
  },
};

export default theme;

import { connect } from 'react-redux';
import LoadingModalComponent from '../components/LoadingModal';

const mapStateToProps = ({ requests }, { loadingSubscriptions = [] }) => {
  const { bycService, researchableApiService } = requests;
  const loadingSubs = loadingSubscriptions.filter((sub) => {
    if (bycService[sub] && bycService[sub].isLoading) {
      return true;
    }
    if (researchableApiService[sub] && researchableApiService[sub].isLoading) {
      return true;
    }
    return false;
  });
  return (loadingSubs && loadingSubs.length > 0) ? { isShowed: true } : { isShowed: false };
};

const mapDispatchToProps = {};

export default connect(mapStateToProps, mapDispatchToProps)(LoadingModalComponent);

import React from 'react';
import styled from '@emotion/styled';
import { keyframes } from '@emotion/core';
import { colors } from './../../fdpq-core/config/vars';

const spin = keyframes`
0% {
  -webkit-transform: rotate(0deg);
  transform: rotate(0deg);
}

100% {
  -webkit-transform: rotate(359deg);
  transform: rotate(359deg);
}
`;

const StyledSvg = styled.svg`
  position: absolute;
  width: ${p => p.size};
  height: ${p => p.size};
  fill: ${colors.global.gray9};
  -webkit-animation: ${spin} 1.5s infinite linear;
  animation: ${spin} 1.5s infinite linear;
`;

const SpinningStyleSpinner = ({ size = '18px' }) => (
  <StyledSvg
    width={size}
    height={size}
    viewBox="0 0 1792 1792"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M526 1394q0 53-37.5 90.5t-90.5 37.5q-52 0-90-38t-38-90q0-53 37.5-90.5t90.5-37.5 90.5 37.5 37.5 90.5z
        m498 206q0 53-37.5 90.5t-90.5 37.5-90.5-37.5-37.5-90.5 37.5-90.5 90.5-37.5 90.5 37.5 37.5 90.5z
        m-704-704q0 53-37.5 90.5t-90.5 37.5-90.5-37.5-37.5-90.5 37.5-90.5 90.5-37.5 90.5 37.5 37.5 90.5z
        m1202 498q0 52-38 90t-90 38q-53 0-90.5-37.5t-37.5-90.5 37.5-90.5 90.5-37.5 90.5 37.5 37.5 90.5z
        m-964-996q0 66-47 113t-113 47-113-47-47-113 47-113 113-47 113 47 47 113z
        m1170 498q0 53-37.5 90.5t-90.5 37.5-90.5-37.5-37.5-90.5 37.5-90.5 90.5-37.5 90.5 37.5 37.5 90.5z
        m-640-704q0 80-56 136t-136 56-136-56-56-136 56-136 136-56 136 56 56 136z
        m530 206q0 93-66 158.5t-158 65.5q-93 0-158.5-65.5t-65.5-158.5q0-92 65.5-158t158.5-66q92 0 158 66t66 158z"
    />
  </StyledSvg>
);

export default SpinningStyleSpinner;

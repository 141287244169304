import { VEHICLE_SELECTOR_LOADED, TRADEIN_VEHICLE_SELECTOR_LOADED, GET_VEHICLE_FAILURE, TRADEIN_GET_VEHICLE_FAILURE } from '@packages/fdpq-core/action-types/vehicles';

// eslint-disable-next-line import/prefer-default-export
export const vehicleSelectorLoaded = () => ({
  type: VEHICLE_SELECTOR_LOADED,
});
export const tradeinVehicleSelectorLoaded = () => ({
  type: TRADEIN_VEHICLE_SELECTOR_LOADED,
});

export const getVehicleFailure = () => ({
  type: GET_VEHICLE_FAILURE,
});

export const tradeinGetVehicleFailure = () => ({
  type: TRADEIN_GET_VEHICLE_FAILURE,
});

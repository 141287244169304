import { ZIPCODE_CHANGE } from '../action-types/location';

export const zipCodeChange = (previousZipCode, NewZipCode) => {
  // post zip code change to the parent page
  window.parent.postMessage({
    type: 'userChangedZipCode',
    previousZipCode,
    NewZipCode,
  }, '*');

  return {
    type: ZIPCODE_CHANGE,
    data: {
      zipcode: NewZipCode,
    },
  };
};

export default zipCodeChange;

import { PAGE_NAME } from '@packages/fdpq-core/constants/app';
import {
  UPDATE_DEALER_LIST,
  CLEAR_DEALER_LIST,
} from '../action-types/dealers';
import { toContactForm, toDealerSelector, toVehicleSelector } from './navigation';
import { emptyDealerListError } from './errors';
import { getMakes, getModels, getTrims } from './researchableApiClient';
import { getDealerList, getMakeList } from './bycClient';
import { zipCodeChange } from './location';
import { getActionTypes, getVehicleState } from '../utils/vehicleStateUtil';
import { fireFloodLightTag } from '../utils/floodlightUtils';
import { preselectDealers } from './dealers';

const clearSelectedTrim = actionTypes => ({
  type: actionTypes.clearSelectedTrim,
});

const clearSelectedModel = actionTypes => (dispatch) => {
  dispatch(clearSelectedTrim(actionTypes));
  dispatch({
    type: actionTypes.clearSelectedModel,
  });
};

export const clearSelectedMake = actionTypes => (dispatch) => {
  if (!actionTypes) {
    actionTypes = getActionTypes();
  }
  dispatch(clearSelectedModel(actionTypes));
  dispatch({
    type: actionTypes.clearSelectedMake,
  });
};

export const clearSelectedYear = actionTypes => (dispatch) => {
  dispatch({
    type: actionTypes.clearSelectedYear,
  });
};

export const updateTrim = (actionTypes, trim) => (dispatch) => {
  try {
    dispatch({
      type: actionTypes.trimDropDownChange,
      data: {
        trimId: trim.trimId,
        trimName: trim.trimName,
        yearId: trim.yearId,
      },
    });
  } catch (e) {
    console.error(e); // eslint-disable-line no-console
  }
};

const getResearchableApiMakes = (actionTypes, yearId, vehicleListingType) => {
  const actions = { update: actionTypes.updateMakeOptions, failure: actionTypes.getMakesFailure };
  return getMakes(actions, yearId, vehicleListingType);
};

const getResearchableApiModels = (actionTypes, makeId, yearId, vehicleListingType) => {
  const actions = { update: actionTypes.updateModelOptions, failure: actionTypes.getModelsFailure };
  return getModels(actions, makeId, yearId, vehicleListingType);
};

const getResearchableApiTrims = (actionTypes, makeId, yearId, modelId, vehicleListingType) => {
  const actions = { update: actionTypes.updateTrimOptions, failure: actionTypes.getTrimsFailure };
  return getTrims(actions, makeId, yearId, modelId, vehicleListingType);
};

// If there is no selected year (it has been cleared), try to update it from the selected trim
const refreshYear = () => async (dispatch, getState) => {
  const { selectedVehicle: { yearId, trimId }, trimOptions, actionTypes } = getVehicleState(getState());
  if (!yearId && trimId && trimOptions && trimOptions.length > 0) {
    const trim = trimOptions.find(x => x.trimId === trimId);
    if (trim) {
      dispatch(updateTrim(actionTypes, trim));
    }
  }
};

const isYearClearable = (state) => {
  const { selectedVehicle, vehicleSelectorDropDowns } = getVehicleState(state);
  return selectedVehicle.yearId && !vehicleSelectorDropDowns.includes('year');
};

// navigation Action Creators
export const fromChangeVehicle = () => async (dispatch, getState) => {
  const leadPartnerFranchiseId = getState().app.config.queryParams.leadpartnerfranchiseid || '';
  const leadFranchiseId = getState().app.config.queryParams.leadfranchiseid || '';
 
  const actionTypes = getActionTypes(getState());
  if (isYearClearable(getState())) {
    dispatch(clearSelectedYear(actionTypes));

    const { selectedVehicle: { yearId, makeId, modelId }, vehicleListingType } = getVehicleState(getState());
    if (leadPartnerFranchiseId.length > 0 || leadFranchiseId.length > 0) {
      await dispatch(getMakeList({
        leadFranchiseId: leadFranchiseId,
        leadPartnerFranchiseId: leadPartnerFranchiseId,
      }));
    } else {
      dispatch(getResearchableApiMakes(actionTypes, yearId, vehicleListingType));
    }

    if (makeId) {
      dispatch(getResearchableApiModels(actionTypes, makeId, yearId, vehicleListingType));
    }
    if (modelId) {
      dispatch(getResearchableApiTrims(actionTypes, makeId, yearId, modelId, vehicleListingType));
    }
  }
};

// VehicleSelector Action Creators
export const yearDropDownChange = yearData => async (dispatch, getState) => {
  const { vehicleListingType, actionTypes } = getVehicleState(getState());
  dispatch({
    type: actionTypes.yearDropDownChange,
    data: {
      yearId: yearData.value,
      yearName: yearData.text,
    },
  });
  dispatch(clearSelectedMake(actionTypes));

  const makeOptions = await dispatch(getResearchableApiMakes(actionTypes, yearData.value, vehicleListingType));
  if (!makeOptions.data) {
    dispatch(clearSelectedYear(actionTypes));
  }
};

export const makeDropDownChange = makeData => async (dispatch, getState) => {
  const previousMakeId = getVehicleState(getState()).selectedVehicle.makeId;
  const actionTypes = getVehicleState(getState()).actionTypes;
  dispatch({
    type: actionTypes.makeDropDownChange,
    data: {
      makeId: makeData.value,
      makeName: makeData.text,
    },
  });
  dispatch(clearSelectedModel(actionTypes));

  const { selectedVehicle, vehicleListingType } = getVehicleState(getState());
  if (selectedVehicle) {
    let { yearId } = selectedVehicle;
    if (previousMakeId && isYearClearable(getState())) {
      dispatch(clearSelectedYear(actionTypes));
      yearId = undefined;
    }

    const modelOptions = await dispatch(getResearchableApiModels(actionTypes, makeData.value, yearId, vehicleListingType));
    if (!modelOptions.data) {
      dispatch(clearSelectedMake(actionTypes));
    }
  }
};

export const modelDropDownChange = modelData => async (dispatch, getState) => {
  const previousModelId = getVehicleState(getState()).selectedVehicle.modelId;
  const actionTypes = getVehicleState(getState()).actionTypes;
  dispatch({
    type: actionTypes.modelDropDownChange,
    data: {
      modelId: modelData.value,
      modelName: modelData.text,
    },
  });

  const { selectedVehicle, vehicleListingType } = getVehicleState(getState());
  if (selectedVehicle && selectedVehicle.makeId) {
    const { makeId } = selectedVehicle;
    let { yearId } = selectedVehicle;
    if (previousModelId && isYearClearable(getState())) {
      dispatch(clearSelectedYear(actionTypes));
      yearId = undefined;
    }

    // make api call to get trims
    const trimOptions = await dispatch(getResearchableApiTrims(actionTypes, makeId, yearId, modelData.value, vehicleListingType));
    if (trimOptions.data && trimOptions.data.length > 0) {
      dispatch(updateTrim(actionTypes, trimOptions.data[0]));
    } else {
      dispatch(clearSelectedMake(actionTypes));
    }
  }
};

export const trimDropDownChange = selectedTrim => (dispatch, getState) => {
  try {
    const { trimOptions, actionTypes } = getVehicleState(getState());
    const vehicleTrim = trimOptions.find(trim => +trim.trimId === +selectedTrim.value);
    dispatch(updateTrim(actionTypes, vehicleTrim));
  } catch (e) {
    console.error(e); // eslint-disable-line no-console
  }
};

export const submitVehicleInfo = values => async (dispatch, getState) => {
  try {
    const { navigation: { currentPage } } = getState();
    if (currentPage === PAGE_NAME.TRADEIN_VEHICLE_SELECTOR) {
      dispatch(toVehicleSelector());
      dispatch(refreshYear());
      dispatch(fireFloodLightTag());
    } else {
      dispatch(refreshYear());
      const { vehicles: { selectedVehicle }, location, app } = getState();
      const leadPartnerFranchiseId = app.config.queryParams.leadpartnerfranchiseid || '';
      const leadFranchiseId = app.config.queryParams.leadfranchiseid || '';
      const { zipInput: newZipCode } = values;
      const previousZipCode = location.zipcode;

      // update zip in redux
      dispatch(zipCodeChange(previousZipCode, newZipCode));

      // make api call to get dealer list
      const { makeName, modelName, trimName, yearId } = selectedVehicle;
      const { config: { hostPageName, listingTypeConfig: { vehicleListingType } } } = app;

      const dealerList = await dispatch(getDealerList({
        vehicleMake: makeName,
        vehicleModel: modelName,
        vehicleTrim: trimName,
        vehicleYear: yearId,
        zipCode: newZipCode,
        vehicleListingType,
        hostPageName,
        leadPartnerFranchiseId,
        leadFranchiseId
      }));

      if (dealerList && dealerList.data && dealerList.data.length > 0) {
        if ((leadPartnerFranchiseId.length > 0 || leadFranchiseId.length > 0) &&
          dealerList && dealerList.data && dealerList.data.length === 1) {
          dispatch(preselectDealers());
          dispatch(toContactForm());
          dispatch(fireFloodLightTag());
        } else {
          dispatch({
            type: UPDATE_DEALER_LIST,
            data: dealerList.data,
          });
          dispatch(toDealerSelector());
          dispatch(fireFloodLightTag());
        }
      } else if (dealerList && dealerList.data && dealerList.data.length === 0) {
        dispatch(emptyDealerListError());
        dispatch({
          type: CLEAR_DEALER_LIST,
        });
      }
    }
  } catch (e) {
    console.error(e); // eslint-disable-line no-console
  }
};

export const clickVehicleSubmitButton = () => (dispatch, getState) => {
  const actionTypes = getActionTypes(getState());
  dispatch({
    type: actionTypes.clickVehicleSubmit,
  });
};
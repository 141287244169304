import { getBakeTitle } from '@argo/utils/bakeTitle';

export const getMilesText = milesAway => `${milesAway} ${milesAway === '1' ? 'mile' : 'miles'} away `;

export const getAddressLine2Text = (city, state, zip) => {
  const maxChar = 19;
  const bakeCity = getBakeTitle(city.toLowerCase());
  const finalCity = city.length > maxChar ? `${bakeCity.substring(0, maxChar - 3)}...` : bakeCity;
  return `${finalCity}, ${state} ${zip}`;
};

export const getNameWithEllipses = (name) => {
  // split the words
  const words = name.split(' ');

  // count the letters in the words
  let remainingCharacters = 42;
  let finalName = '';
  for (let i = 0; i < words.length; i++) {
    if (words[i].length < remainingCharacters) {
      finalName += `${words[i]} `;
      remainingCharacters -= words[i].length + 1;
    } else {
      finalName = `${finalName.substring(0, finalName.length - 1)}...`;
      return finalName;
    }
  }
  return finalName;
};
import { Fragment } from 'react';
import styled from '@emotion/styled';
import { css } from '@emotion/core';
import Link from '@argo/ui-elements/Link';
import Paragraph from '@argo/ui-elements/Paragraph';
import Typography from '@argo/principles/typography';
import ContentBlock from '@argo/principles/ContentBlock';
import Breakpoints from '@argo/principles/constants/breakpoints';
import size from '@argo/principles/constants/size';
import { useAdaptiveRender } from '@argo/utils';
import { connect } from 'react-redux';
import { mapStateToProps, mapDispatchToProps } from '../containers/VehicleInfo';

const VehicleInlineComponent = styled.div`
  margin-top: 0;
  margin-bottom: 0;
`;

const VehicleTrimContainer = styled.div`
  margin-top: -${size.micro}px !important;
  margin-bottom: ${size.sm}px !important;
`;

const headingStyling = css`
  font-weight: ${Typography.weight.bold};
`;

const VehicleInfoArgo = ({
  ymm,
  vehicleTrimName,
  tradeInYmm,
  onClickChangeTradeInVehicleLink,
  onClickChangeVehicleLink,
  onChangeTradeInVehicleSelected,
  onChangeVehicleSelected,
  heading,
}) => {
  const { width } = useAdaptiveRender();
  const isSmallOrMediumViewPort = width < Breakpoints.lg;
  let vehicleInfoHeading;
  if (heading === 'VOI') {
    vehicleInfoHeading = 'You\'re Interested In: ';
  } else if (heading === 'TRADEIN') {
    vehicleInfoHeading = 'Your Trade-in: ';
  }

  const ymmString = heading === 'TRADEIN' ? tradeInYmm : ymm;

  const handleChangeVehicleClick = () => {
    if (heading === 'TRADEIN') {
      onClickChangeTradeInVehicleLink();
      onChangeTradeInVehicleSelected();
    } else {
      onClickChangeVehicleLink();
      onChangeVehicleSelected();
    }
  };

  return (
    <VehicleTrimContainer id={heading === 'TRADEIN' ? 'test-tradein-vehicleinfo' : 'test-voi-vehicleinfo'}>
      <ContentBlock verticalSpacing='micro' horizontalSpacing={isSmallOrMediumViewPort ? 'sm' : 'md'}>
        {vehicleInfoHeading
          ? (<VehicleInlineComponent css={headingStyling}>
            <Paragraph
              defaultMargin={true}
              textAlign="left"
              type="body"
            >
              {vehicleInfoHeading}
            </Paragraph>
          </VehicleInlineComponent>)
          : ''
        }
        <VehicleInlineComponent id={heading === 'TRADEIN' ? 'test-tradein-vehicleymm' : 'test-vehicleymm'}>
          <Paragraph
            defaultMargin={true}
            textAlign="left"
            type="body"
          >
            {ymmString}
          </Paragraph>
        </VehicleInlineComponent>
        {vehicleTrimName
          ? <VehicleInlineComponent>
            <Paragraph
              defaultMargin={true}
              textAlign="left"
              type="body"
            >
              {vehicleTrimName}
            </Paragraph>
          </VehicleInlineComponent>
          : <Fragment />
        }
        <VehicleInlineComponent>
          <Paragraph
            defaultMargin={true}
            textAlign="left"
            type="body"
          >
            <Link
              href="#"
              target="_self"
              type="regular"
              onClick={(e) => {
                e.preventDefault();
                handleChangeVehicleClick();
              }}
            >
              Change Vehicle
            </Link>
          </Paragraph>
        </VehicleInlineComponent>
      </ContentBlock>
    </VehicleTrimContainer>
  );
};

export default connect(mapStateToProps, mapDispatchToProps)(VehicleInfoArgo);

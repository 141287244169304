import React from 'react';
import { Provider, connect } from 'react-redux';
import { ThemeProvider } from 'emotion-theming';
import ReactResizeDetector from 'react-resize-detector';
import configureStore from '@packages/fdpq-core/store';
import ResearchableApiService from '@packages/fdpq-core/services/ResearchableApiService';
import BycService from '@packages/fdpq-core/services/BycService';
import LscService from '@packages/fdpq-core/services/LscService';
import { widgetSize as WIDGET_SIZE } from '@packages/fdpq-common/constants/ui';
import { vehicleSelectorLoaded, tradeinVehicleSelectorLoaded, getVehicleFailure, tradeinGetVehicleFailure } from '@packages/fdpq-core/actions/app';
import { VEHICLE_LISTING_TYPE } from '@packages/fdpq-core/constants/app';
import FdpqWidgetComponent from '../components/FdpqWidgetArgo';
import getListingTypeConfig from '../../fdpq-core/config/listingTypeConfig';
import getUrlParameter from '../utils/helperUtil';
import { fireFloodLightTag } from '@packages/fdpq-core/utils/floodlightUtils';

const getWidgetSize = (width) => {
  if (width < 654) {
    return WIDGET_SIZE.SMALL;
  }

  if (width < 948) {
    return WIDGET_SIZE.MEDIUM;
  }

  return WIDGET_SIZE.LARGE;
};

const updateWidgetHeight = (widgetHeight, origin, state) => {
  if (typeof window !== 'undefined' && widgetHeight && origin) {
    console.info(`Widget height: ${widgetHeight}`); // eslint-disable-line no-console
    window.parent.postMessage({
      type: (state.config.listingTypeConfig.vehicleListingType === VEHICLE_LISTING_TYPE.TRADEIN) ? 'onTilpWidgetHeightChanged' : 'onFdpqWidgetHeightChanged',
      height: widgetHeight,
    }, origin);
  }
};

const FdpqWidgetArgo = connect(state => state)(FdpqWidgetComponent);

const FdpqWidgetWithTheme = ({
  initialData, initialWidgetSize, width, height,
}) => {
  const { theme, config: { origin } } = initialData;
  let widgetSize = initialWidgetSize || WIDGET_SIZE.LARGE;

  if (typeof window !== 'undefined' && width) {
    widgetSize = getWidgetSize(window.innerWidth);
    updateWidgetHeight(height, origin, initialData);
  }

  return (
    <ThemeProvider theme={{ ...theme, widgetSize }}>
      <FdpqWidgetArgo />
    </ThemeProvider>
  );
};

export default ({ initialData, initialWidgetSize }) => {
  ResearchableApiService.configure(initialData.config);
  BycService.configure(initialData.config);
  LscService.configure(initialData.config);

  const store = configureStore(initialData);
  initialData.config.listingTypeConfig = getListingTypeConfig(getUrlParameter('vehicleListingType'));

  let isTradeIn = initialData.config.listingTypeConfig.vehicleListingType === VEHICLE_LISTING_TYPE.TRADEIN;
  if(!initialData.vehicles || !initialData.vehicles.makeOptions || initialData.vehicles.makeOptions.length === 0) {
    store.dispatch(isTradeIn ? tradeinGetVehicleFailure() :  getVehicleFailure());
  } else {
    store.dispatch(isTradeIn ? tradeinVehicleSelectorLoaded() : vehicleSelectorLoaded());
  }
  store.dispatch(fireFloodLightTag());
  
  if (typeof window !== 'undefined' && window.Cypress) {
    window.store = store;
  }

  return (
    <Provider store={store}>
      <ReactResizeDetector handleWidth handleHeight refreshMode="throttle" refreshRate={500}>
        <FdpqWidgetWithTheme initialData={initialData} initialWidgetSize={initialWidgetSize} />
      </ReactResizeDetector>
    </Provider>
  );
};

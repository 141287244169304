import {
  submitVehicleInfo,
  yearDropDownChange,
  makeDropDownChange,
  modelDropDownChange,
  trimDropDownChange,
  clickVehicleSubmitButton,
} from '@packages/fdpq-core/actions/vehicles';
import { PAGE_NAME } from '../../fdpq-core/constants/app';

export const mapStateToProps = ({ app, vehicles, errors, location, navigation }) => {
  const listingTypeConfig = app.config.listingTypeConfig;
  const stateErrors = errors;
  const { zipcode } = location;
  const theme = app.theme;
  const leadpartnerfranchiseid = app.config.queryParams.leadpartnerfranchiseid || '';
  const leadFranchiseId = app.config.queryParams.leadfranchiseid || '';
  return {
    listingTypeConfig,
    app,
    theme,
    zipcode,
    stateErrors,
    leadpartnerfranchiseid,
    leadFranchiseId,
    ...mapStateToPropsByPage(navigation.currentPage, listingTypeConfig, vehicles, app.texts.global)
  };
};

const mapStateToPropsByPage = (currentPage, listingTypeConfig, vehicles, globalText) => {
  let selectedVehicle = vehicles.selectedVehicle;
  let yearMap = vehicles.yearOptions;
  let makeMap = vehicles.makeOptions;
  let modelMap = vehicles.modelOptions;
  let trimMap = vehicles.trimOptions;
  let listingTypeView = listingTypeConfig.vehicleSelectorView;

  if (currentPage === PAGE_NAME.TRADEIN_VEHICLE_SELECTOR) {
    selectedVehicle = vehicles.selectedTradeinVehicle;
    yearMap = vehicles.tradeinYearOptions;
    makeMap = vehicles.tradeinMakeOptions;
    modelMap = vehicles.tradeinModelOptions;
    trimMap = vehicles.tradeinTrimOptions;
    listingTypeView = listingTypeConfig.vehicleSelectorTradeInView;
  }

  //combine listingTypeConfig view options with global options
  const viewOptions = {
    ...globalText.vehicleSelectorView,
    ...listingTypeView,
    description: {
      ...globalText.description,
      ...listingTypeView.description,
    }
  }

  const yearOptions = yearMap.map(year => ({
    text: year.yearId.toString(),
    value: year.yearId,
  }));
  const makeOptions = makeMap.map(make => ({
    text: make.makeName,
    value: make.makeId,
  }));
  const modelOptions = modelMap.map(model => ({
    text: model.modelName,
    value: model.modelId,
  }));
  const trimOptions = trimMap.map(trim => ({
    text: trim.trimName,
    value: trim.trimId,
  }));
  const selectedYear = selectedVehicle.yearId;
  const selectedMake = selectedVehicle.makeId;
  const selectedModel = selectedVehicle.modelId;
  const selectedTrim = selectedVehicle.trimId;
  return { yearOptions, makeOptions, modelOptions, trimOptions, selectedYear, selectedMake, selectedModel, selectedTrim, viewOptions };
};

export const mapDispatchToProps = dispatch => ({
  onSubmit: values => dispatch(submitVehicleInfo(values)),
  onButtonPress: () => dispatch(clickVehicleSubmitButton()),
  onYearDropDownChange: year => dispatch(yearDropDownChange(year)),
  onMakeDropDownChange: make => dispatch(makeDropDownChange(make)),
  onModelDropDownChange: model => dispatch(modelDropDownChange(model)),
  onTrimDropDownChange: trim => dispatch(trimDropDownChange(trim)),
});

const validateVehicleSelector = ({
  viewOptions,
  zipInput,
  selectedMake,
  selectedModel,
  selectedTrim,
}) => {
  const errors = {};
  const zipRegEx = /\d{5}/;

  if (!viewOptions.disableZip && (!zipInput || zipInput.length === 0 || (zipInput && !zipRegEx.test(zipInput)))
  ) {
    errors.zipInput = true;
  }
  if (!selectedMake) {
    errors.makeDropdown = true;
  }
  if (!selectedModel) {
    errors.modelDropdown = true;
  }
  if (!selectedTrim) {
    errors.trimDropdown = true;
  }
  return errors;
};

export const formikProps = {
  mapPropsToValues: props => ({
    zipInput: props.zipcode,
    viewOptions: props.viewOptions
  }),
  validate: (values) => {
    const { zipInput, viewOptions } = values;
    const errors = {};
    const zipRegEx = /\d{5}/;

    if (!viewOptions.disableZip && (!zipInput || zipInput.length === 0 || (zipInput && !zipRegEx.test(zipInput)))) {
      errors.zipInput = true;
    }
    return errors;
  },
  validateOnChange: true,
  handleSubmit: (values, state) => {
    const { zipInput } = values;
    const {
      setErrors,
      setSubmitting,
      props: { viewOptions, onSubmit, selectedMake, selectedModel, selectedTrim },
    } = state;

    const errors = validateVehicleSelector({
      viewOptions,
      zipInput,
      selectedMake,
      selectedModel,
      selectedTrim,
    });
    if (Object.keys(errors).length > 0) {
      setSubmitting(false);
      setErrors(errors);
    } else {
      setSubmitting(true);
      onSubmit(values);
    }
  },
};

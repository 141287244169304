import React from 'react';
import { connect } from 'react-redux';
import {
  FlexCell,
  DealerBlock
} from '@packages/fdpq-common';
import { mapStateToProps, mapDispatchToProps } from '../containers/DealerList';


const DealerList = ({ onDealerSelected, displayDealerList=[], vehicleListingTypeConfig }) => {
  return displayDealerList.map((dealerData, index) => {
    return (
      <FlexCell key={index} columnSpan={{ lg: 4, md: 4, sm: 12 }}>
        <DealerBlock
          dealerKey={`dealerkey${index}`}
          data={dealerData.displayItem}
          isChecked={dealerData.isSelected}
          onCheckBoxSelected={onDealerSelected}
          isPreSelected = {dealerData.isPreSelected}
          vehicleListingTypeConfig = {vehicleListingTypeConfig}
        />
      </FlexCell>
      )
  });
}

export default connect(mapStateToProps, mapDispatchToProps)(DealerList);

import { combineReducers } from 'redux';
import { SET_ERROR,
  REMOVE_ERROR,
} from '../action-types/errors';
import { GET_DEALER_LIST_FAILURE,
  VALIDATE_LEAD_FAILURE,
  SUBMIT_LEAD_FAILURE,
} from '../action-types/dealers';
import { API_ERROR } from '../constants/errorCodes';

const errors = (state = {}, action) => {
  let error = {};
  switch (action.type) {
    case SET_ERROR:
      error[action.data.errorCode] = action.data;
      return { ...state, ...error };
    case REMOVE_ERROR:
      const errorCode = action.data.errorCode;
      // Remove property in object immutably
      const errorState = Object.keys(state).reduce((acc, key) => {
        if(errorCode === key) {
          return acc;
        } else {
          acc[key] = state[key];
          return acc;
        }
      }, {});
      return errorState;
    case GET_DEALER_LIST_FAILURE:
    case VALIDATE_LEAD_FAILURE:
    case SUBMIT_LEAD_FAILURE:
      error = {};
      error[API_ERROR] = {
        errorCode: API_ERROR,
        descriptionTitle: 'There was a technical issue with this request. Please try again later.',
        apiService: 'byc',
      };
      return { ...state, ...error };
    case 'GET_MAKES_FAILURE':
    case 'GET_MODELS_FAILURE':
    case 'GET_TRIMS_FAILURE':
    case 'GET_VEHICLE_FAILURE':
    case 'TRADEIN_GET_YEARS_FAILURE':
    case 'TRADEIN_GET_MAKES_FAILURE':
    case 'TRADEIN_GET_MODELS_FAILURE':
    case 'TRADEIN_GET_TRIMS_FAILURE':
    case 'TRADEIN_GET_VEHICLE_FAILURE':
      error = {};
      error[API_ERROR] = {
        errorCode: API_ERROR,
        descriptionTitle: 'There was a technical issue with this request. Please try again later.',
        apiService: 'researchableApi',
      };
      return { ...state, ...error };
    default:
      return state;
  }
};


export default errors;

import { combineReducers } from 'redux';
import {
  YEAR_DROPDOWN_CHANGE,
  MAKE_DROPDOWN_CHANGE,
  MODEL_DROPDOWN_CHANGE,
  TRIM_DROPDOWN_CHANGE,
  UPDATE_MAKE_OPTIONS,
  UPDATE_MODEL_OPTIONS,
  UPDATE_TRIM_OPTIONS,
  CLEAR_SELECTED_YEAR,
  CLEAR_SELECTED_MAKE,
  CLEAR_SELECTED_MODEL,
  CLEAR_SELECTED_TRIM,
  GET_YEARS_FAILURE,
  GET_MAKES_FAILURE,
  GET_MODELS_FAILURE,
  GET_TRIMS_FAILURE,
  GET_VEHICLE_FAILURE,
  TRADEIN_YEAR_DROPDOWN_CHANGE,
  TRADEIN_MAKE_DROPDOWN_CHANGE,
  TRADEIN_MODEL_DROPDOWN_CHANGE,
  TRADEIN_TRIM_DROPDOWN_CHANGE,
  CLEAR_SELECTED_TRADEIN_YEAR,
  TRADEIN_UPDATE_MAKE_OPTIONS,
  TRADEIN_UPDATE_MODEL_OPTIONS,
  TRADEIN_UPDATE_TRIM_OPTIONS,
  CLEAR_SELECTED_TRADEIN_MAKE,
  CLEAR_SELECTED_TRADEIN_MODEL,
  CLEAR_SELECTED_TRADEIN_TRIM,
  TRADEIN_GET_YEARS_FAILURE,
  TRADEIN_GET_MAKES_FAILURE,
  TRADEIN_GET_MODELS_FAILURE,
  TRADEIN_GET_TRIMS_FAILURE,
  TRADEIN_GET_VEHICLE_FAILURE,
  UPDATE_MAKE_FOR_FRANCHISE_OPTIONS,
} from '../../action-types/vehicles';
// TODO: fill in reducers on state change from API data retrieval
// TODO: convert action types into constants
const selectedVehicle = (state = {}, action) => {
  switch (action.type) {
    case YEAR_DROPDOWN_CHANGE:
      return {
        ...state,
        yearId: action.data.yearId,
        yearName: action.data.yearName,
      };
    case MAKE_DROPDOWN_CHANGE:
      return {
        ...state,
        makeId: action.data.makeId,
        makeName: action.data.makeName,
      };
    case MODEL_DROPDOWN_CHANGE:
      return {
        ...state,
        modelId: action.data.modelId,
        modelName: action.data.modelName,
      };
    case TRIM_DROPDOWN_CHANGE:
      return {
        ...state,
        trimId: action.data.trimId,
        trimName: action.data.trimName,
        yearId: action.data.yearId,
      };
    case CLEAR_SELECTED_YEAR:
      return {
        ...state,
        yearId: '',
        yearName: null,
      };
    case CLEAR_SELECTED_MAKE:
      return {
        ...state,
        makeId: '',
        makeName: null,
      };
    case CLEAR_SELECTED_MODEL:
      return {
        ...state,
        modelId: '',
        modelName: null,
      };
    case CLEAR_SELECTED_TRIM:
      return {
        ...state,
        trimId: '',
        trimName: null,
      };
    default:
      return state;
  }
};

const yearOptions = (state = [], action) => {
  switch (action.type) {
    case GET_YEARS_FAILURE:
    case GET_MAKES_FAILURE:
    case GET_MODELS_FAILURE:
    case GET_TRIMS_FAILURE:
    case GET_VEHICLE_FAILURE:
      return [];
    default:
      return state;
  }
};
const makeOptions = (state = [], action) => {
  switch (action.type) {
    case GET_YEARS_FAILURE:
    case GET_MAKES_FAILURE:
    case GET_MODELS_FAILURE:
    case GET_TRIMS_FAILURE:
    case GET_VEHICLE_FAILURE:
      return [];
    case UPDATE_MAKE_FOR_FRANCHISE_OPTIONS:
    case UPDATE_MAKE_OPTIONS:
      return action.data;
    default:
      return state;
  }
};

const modelOptions = (state = [], action) => {
  switch (action.type) {
    case UPDATE_MODEL_OPTIONS:
      return action.data;
    case CLEAR_SELECTED_MODEL:
      return [];
    default:
      return state;
  }
};

const trimOptions = (state = [], action) => {
  switch (action.type) {
    case UPDATE_TRIM_OPTIONS:
      return action.data;
    case CLEAR_SELECTED_TRIM:
      return [];
    default:
      return state;
  }
};

const selectedTradeinVehicle = (state = {}, action) => {
  switch (action.type) {
    case TRADEIN_YEAR_DROPDOWN_CHANGE:
      return {
        ...state,
        yearId: action.data.yearId,
        yearName: action.data.yearName,
      };
    case TRADEIN_MAKE_DROPDOWN_CHANGE:
      return {
        ...state,
        makeId: action.data.makeId,
        makeName: action.data.makeName,
      };
    case TRADEIN_MODEL_DROPDOWN_CHANGE:
      return {
        ...state,
        modelId: action.data.modelId,
        modelName: action.data.modelName,
      };
    case TRADEIN_TRIM_DROPDOWN_CHANGE:
      return {
        ...state,
        trimId: action.data.trimId,
        trimName: action.data.trimName,
        yearId: action.data.yearId,
      };
    case CLEAR_SELECTED_TRADEIN_YEAR:
      return {
        ...state,
        yearId: '',
        yearName: null,
      };
    case CLEAR_SELECTED_TRADEIN_MAKE:
      return {
        ...state,
        makeId: '',
        makeName: null,
      };
    case CLEAR_SELECTED_TRADEIN_MODEL:
      return {
        ...state,
        modelId: '',
        modelName: null,
      };
    case CLEAR_SELECTED_TRADEIN_TRIM:
      return {
        ...state,
        trimId: '',
        trimName: null,
      };
    default:
      return state;
  }
};

const tradeinYearOptions = (state = [], action) => {
  switch (action.type) {
    case TRADEIN_GET_YEARS_FAILURE:
    case TRADEIN_GET_MAKES_FAILURE:
    case TRADEIN_GET_MODELS_FAILURE:
    case TRADEIN_GET_TRIMS_FAILURE:
    case TRADEIN_GET_VEHICLE_FAILURE:
      return [];
    default:
      return state;
  }
};

const tradeinMakeOptions = (state = [], action) => {
  switch (action.type) {
    case TRADEIN_GET_YEARS_FAILURE:
    case TRADEIN_GET_MAKES_FAILURE:
    case TRADEIN_GET_MODELS_FAILURE:
    case TRADEIN_GET_TRIMS_FAILURE:
    case TRADEIN_GET_VEHICLE_FAILURE:
      return [];
    case TRADEIN_UPDATE_MAKE_OPTIONS:
      return action.data;
    default:
      return state;
  }
};

const tradeinModelOptions = (state = [], action) => {
  switch (action.type) {
    case TRADEIN_UPDATE_MODEL_OPTIONS:
      return action.data;
    case CLEAR_SELECTED_TRADEIN_MODEL:
      return [];
    default:
      return state;
  }
};

const tradeinTrimOptions = (state = [], action) => {
  switch (action.type) {
    case TRADEIN_UPDATE_TRIM_OPTIONS:
      return action.data;
    case CLEAR_SELECTED_TRADEIN_TRIM:
      return [];
    default:
      return state;
  }
};

export default combineReducers({
  selectedVehicle,
  yearOptions,
  makeOptions,
  modelOptions,
  trimOptions,
  selectedTradeinVehicle,
  tradeinYearOptions,
  tradeinMakeOptions,
  tradeinModelOptions,
  tradeinTrimOptions,
});

export const TRADEIN_VEHICLE_SELECTOR_LOADED = 'TRADEIN_VEHICLE_SELECTOR_LOADED';
export const VEHICLE_SELECTOR_LOADED = 'LOAD_VEHICLE_SELECTOR';


export const TRADEIN_YEAR_DROPDOWN_CHANGE = 'TRADEIN_YEAR_DROPDOWN_CHANGE';
export const TRADEIN_MAKE_DROPDOWN_CHANGE = 'TRADEIN_MAKE_DROPDOWN_CHANGE';
export const TRADEIN_MODEL_DROPDOWN_CHANGE = 'TRADEIN_MODEL_DROPDOWN_CHANGE';
export const TRADEIN_TRIM_DROPDOWN_CHANGE = 'TRADEIN_TRIM_DROPDOWN_CHANGE';
export const YEAR_DROPDOWN_CHANGE = 'YEAR_DROPDOWN_CHANGE';
export const MAKE_DROPDOWN_CHANGE = 'MAKE_DROPDOWN_CHANGE';
export const MODEL_DROPDOWN_CHANGE = 'MODEL_DROPDOWN_CHANGE';
export const TRIM_DROPDOWN_CHANGE = 'TRIM_DROPDOWN_CHANGE';

export const UPDATE_MAKE_OPTIONS = 'UPDATE_MAKE_OPTIONS';
export const UPDATE_MAKE_FOR_FRANCHISE_OPTIONS = 'UPDATE_MAKE_FOR_FRANCHISE_OPTIONS';
export const UPDATE_MODEL_OPTIONS = 'UPDATE_MODEL_OPTIONS';
export const UPDATE_TRIM_OPTIONS = 'UPDATE_TRIM_OPTIONS';
export const TRADEIN_UPDATE_MAKE_OPTIONS = 'TRADEIN_UPDATE_MAKE_OPTIONS';
export const TRADEIN_UPDATE_MODEL_OPTIONS = 'TRADEIN_UPDATE_MODEL_OPTIONS';
export const TRADEIN_UPDATE_TRIM_OPTIONS = 'TRADEIN_UPDATE_TRIM_OPTIONS';


export const CLEAR_SELECTED_TRADEIN_YEAR = 'CLEAR_SELECTED_TRADEIN_YEAR';
export const CLEAR_SELECTED_TRADEIN_MAKE = 'CLEAR_SELECTED_TRADEIN_MAKE';
export const CLEAR_SELECTED_TRADEIN_MODEL = 'CLEAR_SELECTED_TRADEIN_MODEL';
export const CLEAR_SELECTED_TRADEIN_TRIM = 'CLEAR_SELECTED_TRADEIN_TRIM';
export const CLEAR_SELECTED_YEAR = 'CLEAR_SELECTED_YEAR';
export const CLEAR_SELECTED_MAKE = 'CLEAR_SELECTED_MAKE';
export const CLEAR_SELECTED_MODEL = 'CLEAR_SELECTED_MODEL';
export const CLEAR_SELECTED_TRIM = 'CLEAR_SELECTED_TRIM';

export const CLICK_TRADEIN_VEHICLE_SUBMIT_BUTTON = 'CLICK_TRADEIN_VEHICLE_SUBMIT_BUTTON';
export const CLICK_VEHICLE_SUBMIT_BUTTON = 'CLICK_VEHICLE_SUBMIT_BUTTON';

export const GET_YEARS_REQUEST = 'GET_YEARS_REQUEST';
export const GET_YEARS_SUCCESS = 'GET_YEARS_SUCCESS';
export const GET_YEARS_FAILURE = 'GET_YEARS_FAILURE';
export const GET_MAKES_REQUEST = 'GET_MAKES_REQUEST';
export const GET_MAKES_SUCCESS = 'GET_MAKES_SUCCESS';
export const GET_MAKES_FAILURE = 'GET_MAKES_FAILURE';
export const GET_MODELS_REQUEST = 'GET_MODELS_REQUEST';
export const GET_MODELS_SUCCESS = 'GET_MODELS_SUCCESS';
export const GET_MODELS_FAILURE = 'GET_MODELS_FAILURE';
export const GET_TRIMS_REQUEST = 'GET_TRIMS_REQUEST';
export const GET_TRIMS_SUCCESS = 'GET_TRIMS_SUCCESS';
export const GET_TRIMS_FAILURE = 'GET_TRIMS_FAILURE';
export const GET_VEHICLE_FAILURE = 'GET_VEHICLE_FAILURE';

export const TRADEIN_GET_YEARS_FAILURE = 'TRADEIN_GET_YEARS_FAILURE';
export const TRADEIN_GET_MAKES_FAILURE = 'TRADEIN_GET_MAKES_FAILURE';
export const TRADEIN_GET_MODELS_FAILURE = 'TRADEIN_GET_MODELS_FAILURE';
export const TRADEIN_GET_TRIMS_FAILURE = 'TRADEIN_GET_TRIMS_FAILURE';
export const TRADEIN_GET_VEHICLE_FAILURE = 'TRADEIN_GET_VEHICLE_FAILURE';
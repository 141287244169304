import { globalHistory } from '@reach/router';
import { getUrlParameter } from '../../fdpq-widget/utils/helperUtil';
import { VEHICLE_LISTING_TYPE, DROPDOWN_TYPE } from '../constants/app';

const path = globalHistory.location.pathname;

export const newVehicleConfig = {
  vehicleListingType: VEHICLE_LISTING_TYPE.NEW,
  global: {
    headerText: 'New Car Price Quote',
    description: {
      title: 'Get the best deal on a new car!',
    },
  },
  vehicleSelectorView: {
    vehicleSelectorDropDowns: [DROPDOWN_TYPE.MAKE, DROPDOWN_TYPE.MODEL, DROPDOWN_TYPE.TRIM],
    disableMake: false,
  },
  dealerSelectorView: {},
  contactFormView: {
    leadType: 1,
  },
  thankYouPageView: {},
};

export const usedVehicleConfig = {
  vehicleListingType: VEHICLE_LISTING_TYPE.USED,
  global: {
    headerText: 'Used Car Price Quote',
    description: {
      title: 'Get the best deal on a used car!',
    },
  },
  vehicleSelectorView: {
    vehicleSelectorDropDowns: [DROPDOWN_TYPE.YEAR, DROPDOWN_TYPE.MAKE, DROPDOWN_TYPE.MODEL],
    disableMake: true,
  },
  dealerSelectorView: {},
  contactFormView: {
    leadType: 14,
  },
  thankYouPageView: {},
};

export const cpoVehicleConfig = {
  vehicleListingType: VEHICLE_LISTING_TYPE.CPO,
  global: {
    headerText: 'Certified Pre-Owned Price Quote',
    description: {
      title: 'Get the best deal on a certified pre-owned car!',
    },
  },
  vehicleSelectorView: {
    vehicleSelectorDropDowns: [DROPDOWN_TYPE.YEAR, DROPDOWN_TYPE.MAKE, DROPDOWN_TYPE.MODEL],
    disableMake: true,
  },
  dealerSelectorView: {},
  contactFormView: {
    leadType: 15,
  },
  thankYouPageView: {},
};

export const tilpVehicleConfig = {
  vehicleListingType: VEHICLE_LISTING_TYPE.TRADEIN,
  global: {
    headerText: 'Start the Trade-in Process Online',
    headerSubText: 'Plus, get a no-obligation quote for your next car.',
    description: {
      title: 'Choose up to 3 dealers',
    },
  },
  vehicleSelectorTradeInView: {
    description: {
      title: 'Tell Us About Your Trade-In',
    },
    submitVehicleButton: {
      name: 'Step0Button',
      text: 'Next',
    },
    id: 'test-tradeinvehicle-selector',
    vehicleSelectorDropDowns: [DROPDOWN_TYPE.YEAR, DROPDOWN_TYPE.MAKE, DROPDOWN_TYPE.MODEL],
    disableMake: true,
    disableZip: true,
    hideSubtitle: true,
  },
  vehicleSelectorView: {
    description: {
      title: 'What Vehicle Are You Shopping For?',
    },
    vehicleSelectorDropDowns: [DROPDOWN_TYPE.MAKE, DROPDOWN_TYPE.MODEL],
    disableMake: false,
    showVehicleInfo: true,
    hideSubtitle: true,
  },
  dealerSelectorView: {},
  contactFormView: {
    leadType: 1,
  },
  thankYouPageView: {},
};

export const getListingTypeConfig = (listingType, yearId) => {
  const paramYear = yearId || getUrlParameter('vehicleYear');
  const paramWidgetTitle = getUrlParameter('widgetTitle');
  const path = globalHistory.location.pathname;
  const currentYear = new Date().getFullYear();

  switch (listingType.toLowerCase()) {
    case VEHICLE_LISTING_TYPE.NEW:
      if (paramWidgetTitle.length > 0) {
        newVehicleConfig.global.headerText = paramWidgetTitle;
      }
      return newVehicleConfig;
    case VEHICLE_LISTING_TYPE.USED: 
      if (paramWidgetTitle.length > 0) {
        usedVehicleConfig.global.headerText = paramWidgetTitle;
      }
      return usedVehicleConfig;
    case VEHICLE_LISTING_TYPE.CPO:
      if (paramYear && paramYear.toString().length > 0) {
        if (paramYear >= currentYear - 7) {
          if (paramWidgetTitle.length > 0) {
            cpoVehicleConfig.global.headerText = paramWidgetTitle;
          }
          return cpoVehicleConfig;
        }
        if (paramWidgetTitle.length > 0) {
          usedVehicleConfig.global.headerText = paramWidgetTitle;
        }
        return usedVehicleConfig;
      }
      if (paramWidgetTitle.length > 0) {
        cpoVehicleConfig.global.headerText = paramWidgetTitle;
      }
      return cpoVehicleConfig;
    case VEHICLE_LISTING_TYPE.TRADEIN:
      if (paramWidgetTitle.length > 0) {
        tilpVehicleConfig.global.headerText = paramWidgetTitle;
      }
      return tilpVehicleConfig;
    default:
      if (paramYear && paramYear.toString().length > 0) {
        if (paramYear >= currentYear) {
          if (paramWidgetTitle.length > 0) {
            newVehicleConfig.global.headerText = paramWidgetTitle;
          }
          return newVehicleConfig;
        }
        if (paramWidgetTitle.length > 0) {
          usedVehicleConfig.global.headerText = paramWidgetTitle;
        }
        return usedVehicleConfig;
      }
      if (path.toLowerCase().includes('atc')) {
        if (paramWidgetTitle.length > 0) {
          usedVehicleConfig.global.headerText = paramWidgetTitle;
        }
        return usedVehicleConfig;
      }
      if (paramWidgetTitle.length > 0) {
        newVehicleConfig.global.headerText = paramWidgetTitle;
      }
      return newVehicleConfig;
  }
};

export default getListingTypeConfig;

import FlexBox from '@argo/principles/FlexBox';
import { BlankDealerBlock } from '@packages/fdpq-common';

const BlankDealerListArgo = () => {
  return (
    <FlexBox
      alignItems="flex-start"
      default=
      {[
        "0 0 33%",
        "0 0 33%",
        "0 0 33%"
      ]}
      flexWrap="wrap"
      horizontalSpacing='md'
    >
      <BlankDealerBlock />
      <BlankDealerBlock />
      <BlankDealerBlock />
    </FlexBox>
  );
}

export default BlankDealerListArgo;

import { getUrlParameter } from '../../fdpq-widget/utils/helperUtil';
import { getListingTypeConfig } from './listingTypeConfig';

const vehicleListingType = getUrlParameter('vehicleListingType');
export const initialListingTypeConfig = getListingTypeConfig(vehicleListingType);

export const colors = {
  global: {
    black1: '#111111',
    blue1: '#505fbb',
    blue2: '#2d363e',
    blue3: '#0F3485',
    blue4: '#414c58',
    blue5: '#4b6ed2',
    red1: '#E14545',
    white1: '#ffffff',
    gray1: '#cccccc',
    gray2: '#dfdfdf',
    gray3: '#eeeeee',
    gray4: '#6D7782',
    gray5: '#5e5e5e',
    gray6: '#cdcdcd',
    gray7: '#dddddd',
    gray8: '#c2c2c2',
    gray9: '#555555',
    gray10: '#F1F2F4',
    gray11: '#333333',
    green1: '#559c56',
    green2: '#0a4c58',
    green3: '#63b000',
  },
  atc: {
    blue1: '#2e3877',
    orange1: '#f76100',
    orange2: '#e85b00',
    orange3: '#de5700',
  },
  kbb: {
    blue1: '#3666BD',
    blue2: '#4A90E2',
    blue3: '#1f3b6e',
    gray1: '#aaaaaa',
    gray2: '#EAEAEA',
    gray3: '#F2F2F2',
  },
};

export const buttons = {
  atc: {
    background: `linear-gradient(${colors.atc.orange1},${colors.atc.orange2},${colors.atc.orange3})`,
    hoverBackground: `linear-gradient(${colors.atc.orange3},${colors.atc.orange2},${colors.atc.orange1});`,
  },
  kbb: {

  },
};

export const text = {
  global: {
    subtitleText: 'Compare prices from three local dealers.',
    contactFormViewDescription: {
      titleText: 'Where should we send your quotes?',
      subtitleText: 'We need your email to send your price quotes.',
    },
    thankYouViewDescription: {
      titleText: 'Thank You!',
      subtitleText: "We've sent your request to these dealers.",
    },
    vehicleSelectorView: {
      submitVehicleButton: {
        name: 'Step1Button',
        text: 'Find Dealers',
        textHaveFranchiseId: 'Get My Free Quote',
      },
      id: 'test-vehicle-selector',
    }
  },
  atc: {
    contactFormView: {
      disclaimer: {
        disclaimerText: 'By using this service, you accept the terms of our',
        linkText: 'Visitor Agreement',
      },
    },
  },
  kbb: {
    contactFormView: {
      disclaimer: {
        disclaimerText: '',
        linkText: 'Privacy Policy',
      },
    },
  },
};

export const links = {
  atc: {
    disclaimerLinkUrl: 'https://www.autotrader.com/legal/visitor-agreement.xhtml',
  },
  kbb: {
    disclaimerLinkUrl: 'https://www.kbb.com/company/privacy-policy/',
  },
};

export const fonts = {
  atc: {
    fontFamily: 'Roboto, Roboto Light, -apple-system, BlinkMacSystemFont, Segoe UI, Oxygen, Ubuntu, Cantarell, Fira Sans, Droid Sans, Helvetica Neue, sans-serif, Apple Color Emoji, Segoe UI Emoji, Segoe UI Symbol !important;',
  },
  kbb: {
    fontFamily: "'Open Sans',sans-serif,Tahoma,Arial",
  },
};

export const pageContent = {
  global: {
    border: `1px solid ${colors.global.gray8}`,
    borderRadius: '0 0 4px 4px',
  },
};

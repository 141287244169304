/** @jsx jsx */
import React from 'react';
import PropTypes from 'prop-types';
import { LayoutContext, ColContext } from './grid-context';
import { jsx, css } from '@emotion/core';
import styled from '@emotion/styled';
import { widgetSize } from '../constants/ui';
import Grid from './grid';
import Spacing from './spacing';

const gridColumns = props => css`
  @supports (display: grid) {
    grid-template-columns: repeat(${props.layout === 1 ? '12' : '8'}, 1fr);
  }
`;

const StyledRow = styled.div`
  ${Grid.grid} ${Grid.close}
  & {
    ${({ theme }) => (theme.widgetSize === widgetSize.SMALL) ? 'flex-wrap: wrap;' : '' }
  }

  & > div {
    @media only screen and (-ms-high-contrast: none) {
      margin-right: ${({ theme }) => (theme.widgetSize === widgetSize.SMALL) ? '0' : Spacing.Large};
    }

    &:last-child {
      margin-right: 0;
      margin-bottom: 0;
    }
  }

  ${props => props.column === 1 ? gridColumns(props) : null}

  @media only screen and (-ms-high-contrast: none) {
    margin-bottom: ${Spacing.Close};
  }
`;

/** // Row Component
 * import { Layout } from '@kbbcomponentlibrary/principles';
 * const { Row } = Layout;
 * */

const Row = props => (
  <LayoutContext.Consumer>
    {layout => (
      <ColContext.Consumer>
        {(column) => {
          return <StyledRow layout={layout} column={column} {...props} />;
        }}
      </ColContext.Consumer>
    )}
  </LayoutContext.Consumer>
);

Row.displayName = 'Row';

Row.propTypes = {
  children: PropTypes.node,
};

export default Row;

import { createStore, applyMiddleware } from 'redux';
import thunk from 'redux-thunk';
import baseState from './baseState';
import rootReducer from '../reducers';
import googleTaggingMiddleWare from '../middlewares/googleTagging';

export default ({
  config,
  theme,
  floodlightTagging,
  texts,
  links,
  navigation,
  vehicles,
  dealers,
  location,
}) => {
  const preloadedState = {
    ...baseState,
    navigation: { ...baseState.navigation, ...navigation },
    app: {
      config: { ...baseState.app.config, ...config },
      theme: theme || baseState.app.theme,
      texts: texts || baseState.app.texts,
      links: links || baseState.app.links,
    },
    vehicles: {
      yearOptions:
        vehicles && vehicles.yearOptions
          ? vehicles.yearOptions
          : baseState.vehicles.yearOptions,
      makeOptions:
        vehicles && vehicles.makeOptions
          ? vehicles.makeOptions
          : baseState.vehicles.makeOptions,
      modelOptions:
        vehicles && vehicles.modelOptions
          ? vehicles.modelOptions
          : baseState.vehicles.modelOptions,
      trimOptions:
        vehicles && vehicles.trimOptions
          ? vehicles.trimOptions
          : baseState.vehicles.trimOptions,
      selectedVehicle:
        vehicles && vehicles.selectedVehicle
          ? vehicles.selectedVehicle
          : baseState.vehicles.selectedVehicle,
      tradeinYearOptions:
        vehicles && vehicles.tradeinYearOptions
          ? vehicles.tradeinYearOptions
          : baseState.vehicles.tradeinYearOptions,
      tradeinMakeOptions:
        vehicles && vehicles.tradeinMakeOptions
          ? vehicles.tradeinMakeOptions
          : baseState.vehicles.tradeinMakeOptions,
      tradeinModelOptions:
        vehicles && vehicles.tradeinModelOptions
          ? vehicles.tradeinModelOptions
          : baseState.vehicles.tradeinModelOptions,
      tradeinTrimOptions:
        vehicles && vehicles.tradeinTrimOptions
          ? vehicles.tradeinTrimOptions
          : baseState.vehicles.tradeinTrimOptions,
      selectedTradeinVehicle:
        vehicles && vehicles.selectedTradeinVehicle
          ? vehicles.selectedTradeinVehicle
          : baseState.vehicles.selectedTradeinVehicle,
    },
    dealers: {
      selectedDealers:
        dealers && dealers.selectedDealers
          ? dealers.selectedDealers
          : baseState.dealers.selectedDealers,
      dealerList:
        dealers && dealers.dealerList
          ? dealers.dealerList
          : baseState.dealers.dealerList,
    },
    floodlightTagging: floodlightTagging || baseState.floodlightTagging,
    location: {
      zipcode: location ? location.zipcode : '',
    },
  };
  return createStore(
    rootReducer,
    preloadedState,
    applyMiddleware(thunk, googleTaggingMiddleWare.middleWare),
  );
};

import { combineReducers } from 'redux';
import {
  GET_YEARS_REQUEST,
  GET_YEARS_SUCCESS,
  GET_YEARS_FAILURE,
  GET_MAKES_REQUEST,
  GET_MAKES_SUCCESS,
  GET_MAKES_FAILURE,
  GET_MODELS_REQUEST,
  GET_MODELS_SUCCESS,
  GET_MODELS_FAILURE,
  GET_TRIMS_REQUEST,
  GET_TRIMS_SUCCESS,
  GET_TRIMS_FAILURE,
  TRADEIN_GET_YEARS_FAILURE,
  TRADEIN_GET_MAKES_FAILURE,
  TRADEIN_GET_MODELS_FAILURE,
  TRADEIN_GET_TRIMS_FAILURE,

} from '../../action-types/vehicles';

const getYears = (state = {}, action) => {
  switch (action.type) {
    case GET_YEARS_REQUEST:
      return { ...state, isLoading: true };
    case GET_YEARS_SUCCESS:
    case GET_YEARS_FAILURE:
    case TRADEIN_GET_YEARS_FAILURE:
      return { ...state, isLoading: false };    
    default:
      return state;
  }
};

const getMakes = (state = {}, action) => {
  switch (action.type) {
    case GET_MAKES_REQUEST:
      return { ...state, isLoading: true };
    case GET_MAKES_SUCCESS:
    case GET_MAKES_FAILURE:
    case TRADEIN_GET_MAKES_FAILURE:
      return { ...state, isLoading: false };
    default:
      return state;
  }
};

const getModels = (state = {}, action) => {
  switch (action.type) {
    case GET_MODELS_REQUEST:
      return { ...state, isLoading: true };
    case GET_MODELS_SUCCESS:
    case GET_MODELS_FAILURE:
    case TRADEIN_GET_MODELS_FAILURE:
      return { ...state, isLoading: false };
    default:
      return state;
  }
};

const getTrims = (state = {}, action) => {
  switch (action.type) {
    case GET_TRIMS_REQUEST:
      return { ...state, isLoading: true };
    case GET_TRIMS_SUCCESS:
    case GET_TRIMS_FAILURE:
    case TRADEIN_GET_TRIMS_FAILURE:
      return { ...state, isLoading: false };
    default:
      return state;
  }
};

export default combineReducers({
  getYears,
  getMakes,
  getModels,
  getTrims,
});

import React, { Fragment, useState, useEffect } from 'react';
import { ThemeProvider } from 'emotion-theming';
import { BlankPage } from '@packages/fdpq-common';
import { getQueryParamsFromUrl } from '@packages/fdpq-core/utils/url';
import FdpqWidgetArgo from './FdpqWidgetArgo';
import { getInitialState } from '../utils/VehicleUtil';

const queryParams = getQueryParamsFromUrl();
const shouldRenderWidget = (params) => {
  if (typeof window === 'undefined' || params) {
    return false;
  }

  return true;
};

const FdpqWidgetWrapperArgo = ({ initialData, initialWidgetSize, texts }) => {
  const [state, setState] = useState({
    initialData,
    shouldRenderWidget: shouldRenderWidget(queryParams),
    texts,
  });
  useEffect(() => {
    if (!shouldRenderWidget(queryParams)) {
      (async () => {
        if (queryParams && queryParams.apikey) {
          const initialState = await getInitialState({ initialData, queryParams, texts });
          setState(initialState);
          window.fdpqWidget = { initialData: initialState.initialData }; // This is for debugging purpose
        }
      })();
    }
  }, []);
  return (
    <Fragment>
      {(state.shouldRenderWidget ? (
        <FdpqWidgetArgo initialData={state.initialData} initialWidgetSize={initialWidgetSize}/>
      ) : (
        <ThemeProvider theme={initialData.theme}>
          <BlankPage initialWidgetSize={'LARGE'} hasError={state.hasError} texts={state.texts} useArgoStyle={true} hasOrigin={ typeof initialData.config.origin !== 'undefined' }/>
        </ThemeProvider>
      ))}
    </Fragment>
  );
};

export default FdpqWidgetWrapperArgo;

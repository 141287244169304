import { toVehicleSelector } from './navigation';
import { clearSelectedMake } from './vehicles';
import { CLICK_THANK_YOU_BUTTON } from '../action-types/thankYou';
import { fireFloodLightTag } from '../utils/floodlightUtils';

export const toVehicleSelectorFromThankYou = () => dispatch => {
  dispatch(clearSelectedMake());
  dispatch(toVehicleSelector());
};

export const onThankYouButtonClick = () => {
  return (dispatch) => {
    dispatch({
      type: CLICK_THANK_YOU_BUTTON,
    });
  }
};
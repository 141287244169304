export const sendEventPixall = (guid, eventData, vehicleData) => {
    const contentEvent = {
      pxamMessageType: 'pxamContentEvent',
      eventType: eventData?.eventType,
      formEventId: guid,
      eventSource: 'dealership',
      eventFields: {
        ...eventData?.eventFields,
        feature: 'FDPQ Lead',
        label: eventData?.label,
        assistChannel: eventData?.assistChannel,
        assistProduct: 'FDPQ Lead',
        assistSubchannel: 'FDPQ Lead',
        pageId: eventData?.pageId,
        pageType: eventData?.pageType,
        cdl: {
            vehicle: {
              bodyStyleGroup: vehicleData?.bodyStyle || '',
              driveTrain: vehicleData?.driveTrain || '',
              fuelType: vehicleData?.fuelType || '',
              descriptionExists: vehicleData?.descriptionExists || 'no',
              legacyConfigurationId: vehicleData?.legacyConfigurationId || '',
              productConfiguration: vehicleData?.productConfiguration || '',
              seriesId: vehicleData?.seriesId || '',
              subCategory: vehicleData?.subCategory || '',
              sellerType: vehicleData?.sellerType ||'dealer',
              transmission: vehicleData?.transmission || '',
              trimId: vehicleData?.chromeStyleId || '',   
            },
            eventProperties: {
              event: {
                valueEventCode: vehicleData?.valueEventCode || '',
              }
            },
        },
        ...vehicleData,
      }, 
    };

    let url = (window.location != window.parent.location) ? document.referrer : document.location.href;
    var parentWindow = window.opener || top;
    parentWindow.postMessage(JSON.stringify(contentEvent), url);
}

/**
 * Create CoxAuto Correlation Id
 */
export const createCoxAutoCorrelationId = () => {
    return 'xxxxxxxx-xxxx-4xxx-yxxx-xxxxxxxxxxxx'.replace(/[xy]/g, function(c) {
      const r = (Math.random() * 16) | 0,
        v = c === 'x' ? r : (r & 0x3) | 0x8;
      return v.toString(16);
    });
};
  
  
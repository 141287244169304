/** @jsx jsx */
import { jsx } from '@emotion/core';
import styled from '@emotion/styled';

const ChangeZipLink = styled.a(({ theme }) => `
  color: ${theme.changeZipButton.color};
  cursor: pointer;
  font-size: 12px;
`);

const ChangeZipButton = ({ onClickChangeZip, onChangeZipSelected, style, theme }) => (
    <ChangeZipLink style={style} theme={theme} onClick={(e) => { onClickChangeZip(); onChangeZipSelected(e);}}>
      Change ZIP
    </ChangeZipLink>
);

export default ChangeZipButton;

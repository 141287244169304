/** @jsx jsx */
import React from 'react';
import PropTypes from 'prop-types';
import { ColContext } from './grid-context';
import { jsx, css } from '@emotion/core';
import styled from '@emotion/styled';
import Grid from './grid';
import Spacing from './spacing';

const column = {
  fluid: css`
    @media only screen and (-ms-high-contrast: none) {
      flex-grow: 1;
      flex-shrink: 1;
      margin-right: 24px;
      width: 100%;
    }
  `,
  fixed: css`
    @media only screen and (-ms-high-contrast: none) {
      flex-grow: 0;
      flex-shrink: 0;
      width: ${Spacing.sidebarWidth};
      /* Not sure why but this is required for IE in some cases or it shrinks it */
      min-width: ${Spacing.sidebarWidth};
    }
  `,
};

const StyledColumn = styled('div')`
  ${Grid.grid}
  ${Grid.related}
  @media only screen and (-ms-high-contrast: none) {
    flex-direction: column;
    > section {
      margin-bottom: ${Spacing.Large};
    }
    > section:last-child {
      margin-bottom: 0;
    }
  }
  ${props => props.fixedWidth ? column.fixed : column.fluid}
`;

/** // Column Component
 * import { Layout } from '@kbbcomponentlibrary/principles';
 * const { Column } = Layout;
 * */
const Column = props => (
  <ColContext.Provider value={props.fixedWidth ? 2 : 1}>
    <StyledColumn {...props}/>
  </ColContext.Provider>
);

Column.displayName = 'Column';

Column.defaultProps = {
  fixedWidth: false,
};

Column.propTypes = {
  children: PropTypes.node,
  fixedWidth: PropTypes.bool,
};

export default Column;
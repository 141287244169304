import React, { Component } from 'react';
import styled from '@emotion/styled';
import IconCheck from '@kbbcomponentlibrary/icon-check';
import { VEHICLE_LISTING_TYPE } from '@packages/fdpq-core/constants/app';
import { colors } from '../../fdpq-core/config/vars';
import { getMilesText, getAddressLine2Text, getNameWithEllipses } from '@packages/fdpq-common/containers/DealerInfoHelpers';

const DistanceDiv = styled.div`
  ${({ theme }) => `
    color: ${theme.dealerSelectorView.dealerBlock.dealershipInfo.distanceDiv.color};
  `}
  font-size: 10px;
`;
const DealershipName = styled.div`
  font-size: 14px;
  font-weight: 900;
`;
const ContainerDiv = styled.div`
  ${({ vehicleListingType }) => `
    background-color: ${vehicleListingType === VEHICLE_LISTING_TYPE.TRADEIN ? colors.global.gray10 : colors.global.white1};
  `}
  display: flex;
  font-size: 12px;
  flex-direction: row;
  line-height: 18px;
  padding: 5px;
  position: relative;
`;
const LeftDiv = styled.div`
  width: 15px;
  margin-right: 3px;
`;
const DealershipInfo = styled.div`
  ${({ theme }) => `
    color: ${theme.dealerSelectorView.dealerBlock.dealershipInfo.color};
  `}
`;
const CheckBoxContainer = styled.label`
  display: block;
  position: relative;
  padding-left: 35px;
  margin-bottom: 12px;
  cursor: pointer;
  user-select: none;

  input {
    position: absolute;
    opacity: 0;
    cursor: pointer;
    height: 0;
    width: 0;
  }

  :hover input ~ span {
    background-color: ${colors.global.gray1};
  }

  input:checked ~ span {
    border: none;
    ${({ theme }) => `
      background-color: ${theme.dealerSelectorView.dealerBlock.checkboxBackgroundColor};
    `}
  }

  input:checked ~ span:after {
    display: block;
  }

  span {
    border: 1px ${colors.global.gray5} solid;
    position: absolute;
    margin-top: 3px;
    top: 0;
    left: 0;
    height: 14px;
    width: 14px;
    background-color: ${colors.global.gray3};
    border-radius: 3px;

    :after {
      content: '';
      position: absolute;
      display: none;
    }
  }

  span:after {
    left: 4px;
    top: 1px;
    width: 3px;
    height: 7px;
    border: solid ${colors.global.white1};
    border-width: 0 2.2px 2.2px 0;
    transform: rotate(45deg);
  }
`;

export default class DealerBlock extends Component {
  onCheckBoxSelected(event) {
    const isSelected = event.target.checked;
    if (typeof this.props.onCheckBoxSelected === 'function') {
      this.props.onCheckBoxSelected({
        item: this.props.data,
        isSelected,
      });
    }
  }

  render() {
    const { data, isChecked, isPreSelected } = this.props;
    const dealer = this.props.dealerKey;
    const name = data.franchiseName;
    const addressLine1 = data.franchiseAddress1;
    const city = data.franchiseCity;
    const state = data.franchiseState;
    const zip = data.franchiseZip;
    const milesAway = data.distance;
    const leadSourceAttr = data.leadSource;
    const vendorListAttr = data.vendorList;
    const vehicleListingType = data.vehicleListingTypeConfig || this.props.vehicleListingTypeConfig.vehicleListingType;

    return (
      <ContainerDiv data-lead-source={leadSourceAttr} data-vendor-list={vendorListAttr} vehicleListingType={vehicleListingType} className="dealer-container">
        <LeftDiv>
            {
             isPreSelected === true ? (<div><IconCheck
             colorFill={colors.global.green1}
             size={16}
             viewBox="0 0 1792 1792"
             /></div>)
               : (<CheckBoxContainer><input
               type="checkbox"
               value={!!isChecked}
               checked={isChecked}
               onChange={this.onCheckBoxSelected.bind(this)}
             />
             <span className="checkmark" value={!!isChecked}></span>
             </CheckBoxContainer>
               )}
        </LeftDiv>
        <DealershipInfo id={dealer}>
          <DealershipName>{getNameWithEllipses(name)}</DealershipName>
          <div style={{ fontSize: '12px' }}>{addressLine1}</div>
          <div style={{ fontSize: '12px' }}>
            {getAddressLine2Text(city, state, zip)}
          </div>
          <DistanceDiv>{getMilesText(milesAway)}</DistanceDiv>
        </DealershipInfo>
      </ContainerDiv>
    );
  }
}

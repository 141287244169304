export const getUrlParameter = (queryParamKey) => {
  if (typeof window === 'undefined'){
    return '';
  }
  const queryParamValue = new URLSearchParams(window.location.search).get(queryParamKey);
  return queryParamValue || '';
};

export default getUrlParameter;

export const getUrlHostName = () => {
  if (typeof window === 'undefined'){
    return '';
  }

  const hostNameValue = window.location.hostname;
  return hostNameValue || '';
}

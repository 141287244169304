/** @jsx jsx */
import { jsx } from "@emotion/core";
import { useState, useEffect } from "react";
import styled from "@emotion/styled";
import IconChevronLeft from "@kbbcomponentlibrary/icon-chevron-left";
import IconChevronRight from "@kbbcomponentlibrary/icon-chevron-right";
import { colors } from './../../fdpq-core/config/vars';
import theme from "../../fdpq-core/config/kbb/theme";

const chevronDisableColor = colors.global.gray6;

const StyledPagination = styled.div`
  display: flex;
  flex-direction: row;
  ${({ theme }) => `
    font-weight: ${theme.dealerSelectorView.pagination.fontWeight};
  `}
`;

const StyledPageItem = styled.div`
  align-items: center;
  display: flex;
  height: 36px;
  text-align: center;
  vertical-align: middle;
  width: 36px;
  ${({ theme, pageNumber, currentPage }) => (pageNumber === currentPage
    ? `
    background-color: ${theme.dealerSelectorView.pagination.selectedPage.backgroundColor};
    color: ${theme.dealerSelectorView.pagination.selectedPage.color};
  `
    : `
    background-color: ${theme.dealerSelectorView.pagination.notSelectedPage.backgroundColor};
    color: ${theme.dealerSelectorView.pagination.notSelectedPage.color};
  `)}
`;

const StyledChevron = styled.div`
  align-items: center;
  display: flex;
  height: 36px;
  margin: 0 10px;
  width: 36px;
  ${({ theme, defaultCursor }) => `
    fill: ${theme.box.baseGlowingColor};
    cursor: ${defaultCursor ? 'default' : 'pointer'};
  `}
`;

const PageItem = ({ pageNumber, currentPage, onClick, shouldDisplay = true }) => {
  return (
    <StyledPageItem
      style={{ display: shouldDisplay ? '' : 'none' }}
      pageNumber={pageNumber}
      currentPage={currentPage}
      onClick={() => (typeof onClick === 'function' ? onClick(pageNumber) : '')}
    >
      <span style={{cursor: 'pointer', margin: 'auto', userSelect: 'none', fontSize: theme.dealerSelectorView.pagination.pageItem.fontSize}} id={pageNumber}>{pageNumber}</span>
    </StyledPageItem>
  );
};

const ChevronLeft = ({ onClick, currentPage }) => {
  const colorFill = currentPage === 1 ? chevronDisableColor : ''; // Indicate enable/disable chevron
  return (
    <StyledChevron defaultCursor={colorFill}>
      <IconChevronLeft
        currentPage = {currentPage}
        style={{ margin: 'auto' }}
        onClick={onClick}
        colorFill={colorFill}
        size={20}
        viewBox="0 0 50 50"
        id="test-leftarrow"
      />
    </StyledChevron>
  );
};

const ChevronRight = ({ onClick, currentPage, totalPages }) => {
  const colorFill = currentPage === totalPages ? chevronDisableColor : ''; // Indicate enable/disable chevron
  return (
    <StyledChevron defaultCursor={colorFill}>
      <IconChevronRight
        currentPage = {currentPage}
        style={{margin: 'auto' }}
        onClick={onClick}
        colorFill={colorFill}
        size={20}
        viewBox="0 0 50 50"
        id="test-rightarrow"
      />
    </StyledChevron>
  );
};

const shouldPageItemDisplay = (pageIndex, currentPageIndex, totalPages, displayFullPageList) => {
  if (displayFullPageList) {
    return true;
  }
  if (currentPageIndex === pageIndex) {
    return true;
  }
  if (currentPageIndex + 1 === pageIndex || currentPageIndex - 1 === pageIndex) {
    return true;
  }
  if (currentPageIndex + 2 === pageIndex && currentPageIndex === 1) {
    return true;
  }
  if (currentPageIndex - 2 === pageIndex && currentPageIndex === totalPages) {
    return true;
  }
  return false;
};

const onChevronLeftSelected = ({ currentPage, setCurrentPage }) => {
  if (currentPage > 1) {
    const previousPageNumber = currentPage - 1;
    setCurrentPage(previousPageNumber);
  }
}

const onChevronRightSelected = ({ currentPage, setCurrentPage, totalPages, onClick }) => {
  if (currentPage < totalPages) {
    const nextPageNumber = currentPage + 1;
    setCurrentPage(nextPageNumber);
  }
}

const renderPageItems = ({ children, totalRecords, pageLimit, totalPages, currentPage, setCurrentPage, displayFullPageList = true }) => {
  if (typeof children === 'function') {
    return children({ totalRecords, totalPages, pageLimit });
  }
  const pageItems = [];
  for (let i = 0; i < totalPages; i++) {
    pageItems.push(
      <PageItem
        key={i}
        pageNumber={i + 1}
        currentPage={currentPage}
        shouldDisplay={shouldPageItemDisplay(i + 1, currentPage, totalPages, displayFullPageList)}
        onClick={e => setCurrentPage(e)}
      />,
    );
  }
  return pageItems;
}

const Pagination = ({ displayFullPageList, children, pageLimit, dataList = [], onPageChanged }) => {
  const totalRecords = dataList.length;
  const paginationPageLimit = (pageLimit < totalRecords) ? pageLimit : totalRecords;
  const totalPages = Math.ceil(totalRecords / pageLimit) || 0;

  const [currentPage, setCurrentPage] = useState(1);

  useEffect(() => {
    if (typeof onPageChanged === 'function') {
      const start = (currentPage - 1) * pageLimit;
      const end = start + pageLimit;
      const selectedDataSet = dataList.slice(start, end);
      onPageChanged({currentPage, selectedDataSet })
    }
  }, [currentPage]);

  return (
    <StyledPagination>
      <ChevronLeft
        currentPage={currentPage}
        onClick={() => onChevronLeftSelected({ currentPage, setCurrentPage })}
      />
      {renderPageItems({ children, displayFullPageList, dataList, totalRecords, pageLimit: paginationPageLimit, totalPages, currentPage, setCurrentPage })}
      <ChevronRight
        totalPages={totalPages}
        currentPage={currentPage}
        onClick={() => onChevronRightSelected({ currentPage, setCurrentPage, totalPages })}
      />
    </StyledPagination>
  );
};

export default Pagination;

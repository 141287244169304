/** @jsx jsx */
import { useEffect  } from 'react';
import { jsx, css } from '@emotion/core';
import FlexBox from '@argo/principles/FlexBox';
import { withTheme } from 'emotion-theming';
import Button from '@argo/ui-elements/Button';
import ContentBlock from '@argo/principles/ContentBlock';
import Heading from '@argo/ui-elements/Heading';
import Paragraph from '@argo/ui-elements/Paragraph';
import { connect } from 'react-redux';
import { useAdaptiveRender } from '@argo/utils';
import Size from '@argo/principles/constants/size';
import Breakpoints from '@argo/principles/constants/breakpoints';
import DealerList from '../../fdpq-dealer-selector/components/DealerListArgo';

import { text } from '../../fdpq-core/config/vars';
import { mapStateToProps, mapDispatchToProps } from '../containers/ThankYouPage';
import { getPixallEventData } from '@packages/fdpq-core/constants/app';
import { getUrlHostName, getUrlParameter } from '../../fdpq-widget/utils/helperUtil';
import { sendEventPixall, createCoxAutoCorrelationId } from '@packages/fdpq-common/analytics/pixall';

const ThankYouPageArgo = ({
  onNextPageSelected,
  onButtonPress,
  selectedDealers,
  listingTypeConfig,
  leadpartnerfranchiseid,
  leadFranchiseId,
  zipCode,
}) => {
  // const defaultStyles = css`
  //   ${focusState}
  // `;

  const loadingModalStyle = css`
    margin-top: -${Size.micro}px;
  `;

  const loadingModalSmallStyle = css`
    margin-top: -${Size.xs}px;
  `;

  const flexBoxDefault = [
    '0 0 100%',
    '0 0 100%',
    '0 0 100%',
    '0 0 100%',
    '0 0 100%',
    '0 0 100%',
    '0 0 50%',
    '0 0 50%',
    '0 0 100%',
  ];

  const flexBoxMobileTablet = [
    '0 0 100%',
    '0 0 100%',
    '0 0 100%',
    '0 0 100%',
    '0 0 100%',
    '0 0 100%',
    '0 0 50%',
    '0 0 50%',
    '0 0 100%',
  ];

  const flexBoxDesktop = [
    '0 0 100%',
    '0 0 100%',
    '0 0 100%',
    '0 0 50%',
    '0 0 50%',
    '0 0 50%',
    '0 0 25%',
    '0 0 25%',
    '0 0 100%',
  ];

  const { width } = useAdaptiveRender();
  const isSmallOrMediumViewPort = width < Breakpoints.lg;
  const hostName = getUrlHostName();
  const isKbb = hostName.includes(hostName);
  const pageId = getUrlParameter('pageid') || '';
  const pageType = getUrlParameter('pagetype') || '';
  const coxAutoCorrelationId = createCoxAutoCorrelationId();

  const pixallData = getPixallEventData(listingTypeConfig.vehicleListingType);
  const eventData = {
    eventType: pixallData.EVENT_TYPE_IMPRESSION,
    label: pixallData.LABEL_STEP_IMPRESSION,
    assistChannel: isKbb ? pixallData.KBB_BRAND_NAME : pixallData.ATC_BRAND_NAME,
    pageType,
    pageId,
  }

  const vehicleData = { 
    zipCode,
  };

  const getAnotherQuoteButton = (
    <Button
      type="submit"
      onClick={(e) => {
        onButtonPress();
        onNextPageSelected(e);
      }}
      text={'Get Another Quote'}
      sizeType='regular'
      style={!isSmallOrMediumViewPort ? { float: 'right' } : { float: 'center' }}
    />
  )

  useEffect(() => {
    sendEventPixall(coxAutoCorrelationId, eventData, vehicleData);
  }, []);

  return (
    <FlexBox
      alignItems="flex-start"
      default={flexBoxDefault}
      xs={flexBoxMobileTablet}
      sm={flexBoxMobileTablet}
      md={flexBoxMobileTablet}
      lg={flexBoxDesktop}
      xl={flexBoxDesktop}
      max={flexBoxDesktop}
      flexWrap="wrap"
      horizontalSpacing={isSmallOrMediumViewPort ? 'sm' : 'md'}
      id="test-thank-you-page"
    >
      <ContentBlock verticalSpacing="xs">
        <div>
          <Heading
            enableTitleCase
            headingName="ContentHeadingExtra"
            headingTag="h2"
            textTransformation={true}
            enableTitleCase={false}
            id="description-title"
          >
            {text.global.thankYouViewDescription.titleText}
          </Heading>
        </div>
        <div>
          <Paragraph
            defaultMargin={true}
            textAlign="left"
            type="body"
            id="description-subtitle"
          >
            {text.global.thankYouViewDescription.subtitleText}
          </Paragraph>
        </div>
      </ContentBlock>
      <DealerList displayDealerList={selectedDealers} />
      <div style={{ textAlign: 'center' }}>
        {(leadFranchiseId.length === 0 && leadpartnerfranchiseid.length === 0) ? getAnotherQuoteButton : ''}
      </div>
    </FlexBox>
  );
};

// export default withTheme(ThankYouPageArgo);
const ThankYou = withTheme(ThankYouPageArgo);
export default connect(mapStateToProps, mapDispatchToProps)(ThankYou);

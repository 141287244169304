/** @jsx jsx */
import { jsx, css } from '@emotion/core';
import styled from '@emotion/styled';
import { ThemeProvider } from 'emotion-theming';
import { widgetSize } from '@packages/fdpq-common/constants/ui';
import { Layout } from '@kbbcomponentlibrary/principles';
import Spinner from '@argo/ui-elements/Spinner';
import Size from '@argo/principles/constants/size';
import Header from './Header';
import Page from './Page';
import PageContent from './PageContent';
import DefaultSpinner from './Spinner';
import { HEADER_TEXT_VALUES } from '../../fdpq-core/constants/app';

const { Column } = Layout;

const getWidgetSize = (width) => {
  if (width < 654) {
    return widgetSize.SMALL;
  } if (width < 948) {
    return widgetSize.MEDIUM;
  }
  return widgetSize.LARGE;
};

const backgroundStyles = css`
  box-sizing: border-box
  flex: none;
  width: 100%;
`;

const HeaderWrapper = styled.div`
  display: flex;
  flex-flow: row nowrap;
  justify-content: center;
  align-items: center;
`;

const SpinnerWrapper = styled.div`
  box-sizing: border-box
  flex: none;
  width: 100%;
  margin-left: -100%;
  text-align: center;
`;

const DefaultSpinnerWrapper = styled.div`
  position: absolute;
  left: 50%;
  top: 40%;
  width: 50%;
  ${({ radius }) => `
  transform: translate(-${radius}, -${radius});
  `}  
`;

const LoadingModal = styled.div`
  height: 100%;
  opacity: .65;
  position: absolute;
  width: 100%;
  z-index: 100;
  justify-content: center;
  align-items: center;
}
`;

const updateWidgetHeightWithError = (widgetHeight, origin, hasError) => {
  if (typeof window !== 'undefined' && origin) {
    window.parent.postMessage({
      type: 'onFdpqWidgetHeightChanged',
      height: widgetHeight,
      hasError,
    }, origin);
  }
};

export default ({ hasError, initialWidgetSize, texts, useArgoStyle, hasOrigin }) => {
  let componentWidth = 0;
  if (typeof window !== 'undefined' && !componentWidth) {
    componentWidth = window.innerWidth;
  }
  if (hasError) {
    updateWidgetHeightWithError(0, '*', hasError);
  }

  const pageWidgetSize = initialWidgetSize || getWidgetSize(componentWidth);

  // Define the horizonal spacing for the spinner
  const spinnerSize = 36;
  const radius = spinnerSize / 2;

  // Define height and width of page
  const defaultHeight = '416px';
  const defaultWidth = '100%';

  let pageHeight = defaultHeight;
  if (hasError) {
    pageHeight = '0px';
  } else if (pageWidgetSize === 'SMALL') {
    pageHeight = '516px';
  }

  let pageWidth = defaultWidth;
  if (hasError) {
    pageWidth = '0%';
  }

  const headerText = texts ? texts.global.headerText : HEADER_TEXT_VALUES.BLANK_HEADER;

  return (
    <ThemeProvider theme={{ pageWidgetSize }}>
      <Page noBorder={hasError} style={{ height: pageHeight, width: pageWidth }}>
        <HeaderWrapper>
          <Header hide={hasError} title={headerText} useArgoStyle={useArgoStyle} hasOrigin={hasOrigin} css={backgroundStyles} />
          {
            useArgoStyle ? (
              <SpinnerWrapper>
                <Spinner dimension={{ size: `${Size.xl}px`, thickness: `${Size.micro}px` }} />
              </SpinnerWrapper>
            ) : (
                <DefaultSpinnerWrapper radius={`${radius}px`}>
                  <DefaultSpinner size={`${spinnerSize}px`} />
                </DefaultSpinnerWrapper>
              )
          }
        </HeaderWrapper>
        <PageContent style={{ display: hasError ? 'none' : 'block' }}>
          <Column id="test-dealer-selector">
            <LoadingModal style={{ display: hasError ? 'none' : 'flex' }}>
              {
                useArgoStyle ? (
                  <Spinner dimension={{ size: `${Size.xl}px`, thickness: `${Size.micro}px` }} />
                ) : (
                    <DefaultSpinnerWrapper radius={`${radius}px`}>
                      <DefaultSpinner size={`${spinnerSize}px`} />
                    </DefaultSpinnerWrapper>
                  )
              }
            </LoadingModal>
          </Column>
        </PageContent>
      </Page>
    </ThemeProvider>
  );
};
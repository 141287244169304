import axios from 'axios';

export default class ResearchableApiService {
  static url;
  static apiKey;

  static configure(config, apiKey) {
    const baseUrl = config.useCdn ? config.fdpqCdnBaseUrl : config.fdpqApiBaseUrl;
    ResearchableApiService.apiKey = config.apiKey || apiKey;
    ResearchableApiService.url = `${baseUrl}/api/researchable-api`;
    // ResearchableApiService.url = 'https://use1-YOUR_BRANCH_PREFIX.awskbbfdpqnp.kbb.com/api/researchable-api'; use this line for local test instead of the above

    axios.interceptors.request.use(
      function(config) {
        config.headers['x-api-key'] = ResearchableApiService.apiKey;
        return config;
      },
      function(error) {
        return Promise.reject(error);
      }
    );
  }

  static getYears(vehicleListingType) {
    return new Promise((resolve, reject) => {
      const finalUrl = `${ResearchableApiService.url}/years`;
      axios
        .get(finalUrl, {
          params: {
            vehiclelistingtype: vehicleListingType,
          },
        })
        .then((response) => {
          if (response && response.data) {
            const years = response.data.map(year => {
              return year;
            });
            resolve(years);
          }
        })
        .catch((error) => {
          reject(error);
        });
    });
  }

  static getMakes(kbbYearId, vehicleListingType) {
    return new Promise((resolve, reject) => {
      const finalUrl = `${ResearchableApiService.url}/makes`;
      axios
        .get(finalUrl, {
          params: {
            vehicleYear: kbbYearId,
            vehicleListingType,
          },
        })
        .then((response) => {
          if (response && response.data) {
            const makes = response.data.map(make => {
              return make;
            });
            resolve(makes);
          }
        })
        .catch((error) => {
          reject(error);
        });
    });
  }

  static getModels(kbbMakeId, kbbYearId, vehicleListingType) {
    return new Promise((resolve, reject) => {
      const finalUrl = `${ResearchableApiService.url}/models`;
      axios
        .get(finalUrl, {
          params: {
            kbbmakeid: kbbMakeId,
            vehicleYear: kbbYearId,
            vehicleListingType,
          },
        })
        .then((response) => {
          if (response && response.data) {
            const models = response.data.map((models) => {
              return models;
            });
            resolve(models);
          }
        })
        .catch((error) => {
          reject(error);
        });
    });
  }

  static getTrims(kbbMakeId, kbbYearId, kbbModelId, vehicleListingType) {
    const finalUrl = `${ResearchableApiService.url}/trims`;
    return new Promise((resolve, reject) => {
      axios
        .get(finalUrl, {
          params: {
            kbbmakeid: kbbMakeId,
            kbbmodelid: kbbModelId,
            vehicleYear: kbbYearId,
            vehicleListingType,
          },
        })
        .then((response) => {
          if (response && response.data) {
            const trims = response.data.map((trim) => {
              return trim;
            });
            resolve(trims);
          }
        })
        .catch((error) => {
          reject(error);
        });
    });
  }

  static async getVehicleById(kbbVehicleId) {
    const finalUrl = `${ResearchableApiService.url}/vehicle`;
    const vehicleId = (kbbVehicleId && kbbVehicleId !== 'undefined') ? kbbVehicleId : '';
    return new Promise((resolve, reject) => {
      axios
        .get(finalUrl, {
          params: {
            kbbVehicleId: vehicleId
          },
        })
        .then((response) => {
          if (response && response.data) {
            resolve(response.data);
          }
        })
        .catch((error) => {
          if (error?.response?.status === 500) {
            resolve(null);
          }
          else {
            reject(error)
          }
        });
    });
  }

  static async getAtcMappedVehicleData(params) {
    try {
      const { vehicleYearId, atcMakeName, atcMakeCode, atcModelCode, atcTrimName, vehicleListingType } = params;
      const apiKey = ResearchableApiService.apiKey;
      const vehicleMappingUrl = `${ResearchableApiService.url}/atcmappeddata`;
      const yearId = (vehicleYearId && vehicleYearId !== 'undefinied') ? vehicleYearId : '';
      const vehicleMakeName = (atcMakeName && atcMakeName !== 'undefined') ? atcMakeName : '';
      const vehicleMakeCode = (atcMakeCode && atcMakeCode !== 'undefined') ? atcMakeCode : '';
      const vehicleModelCode = (atcModelCode && atcModelCode !== 'undefined') ? atcModelCode : '';
      const vehicleTrimName = (atcTrimName && atcTrimName !== 'undefined') ? atcTrimName : '';
      const responseData = await axios.get(vehicleMappingUrl, {
        params: {
          vehicleYear: yearId,
          atcmakename: vehicleMakeName,
          atcmakecode: vehicleMakeCode,
          atcmodelcode: vehicleModelCode,
          atctrimname: vehicleTrimName,
          vehicleListingType
        },
      });
      // console.log('responseData', responseData);
      return responseData;
    } catch (e) {
      console.error(e); // eslint-disable-line no-console
    }
  }

  static async getKbbVehicleData(params) {
    try {
      const { kbbYearId, kbbMakeId, kbbModelId, kbbMakeName, kbbModelName, kbbTrimId, kbbVehicleId, vehicleListingType } = params;
      const apiKey = ResearchableApiService.apiKey;
      const vehicleMappingUrl = `${ResearchableApiService.url}/kbbvehicledata`;
      const yearId = (kbbYearId && kbbYearId !== 'undefined') ? kbbYearId : '';
      const makeId = (kbbMakeId && kbbMakeId !== 'undefined') ? kbbMakeId : '';
      const modelId = (kbbModelId && kbbModelId !== 'undefined') ? kbbModelId : '';
      const makeName = (kbbMakeName && kbbMakeName !== 'undefined') ? kbbMakeName : '';
      const modelName = (kbbModelName && kbbModelName !== 'undefined') ? kbbModelName : '';
      const trimId = (kbbTrimId && kbbTrimId !== 'undefined') ? kbbTrimId : '';
      const vehicleId = (kbbVehicleId && kbbVehicleId !== 'undefined') ? kbbVehicleId : '';
      const responseData = await axios.get(vehicleMappingUrl, {
        params: {
          vehicleYear: yearId,
          kbbMakeId: makeId,
          kbbModelId: modelId,
          kbbMakeName: makeName,
          kbbModelName: modelName,
          kbbTrimId: trimId,
          kbbVehicleId: vehicleId,
          vehicleListingType,
        },
      });
      // console.log('responseData', responseData);
      return responseData;
    } catch (e) {
      console.error(e); // eslint-disable-line no-console
    }
  }
}

import styled from '@emotion/styled';

const Page = styled.div`
  border-radius: 4px;
  display: flex;
  flex-direction: column;
  margin: 0px;
  padding: 0px;
  position: relative;
  width: 100%;
  * {
    box-sizing: border-box;
  }
  height: auto;
  ${({ theme }) => `
    background-color: ${theme.global.pageContent.backgroundColor};
    * {
      font-family: ${theme.global.fontFamily};
    }
  `}
`;

export default Page;

function dispatcher(type, data, dispatch, getState) {
  if (type.indexOf('SUCCESS') === -1) {
    // we don't want to pass the data for the request types
    dispatch(requestSuccess(type, data), getState);
  } else {
    dispatch(requestData(type), getState); 
  }
}

export const requestData = type => ({
  type,
  data: null,
});

export const requestSuccess = (type, data) => ({
  type,
  data,
});

export const requestFailure = (type, error) => ({
  type,
  error,
});

export function bindClientFunc({
  clientFunc,
  onRequest,
  onSuccess,
  onFailure,
  params = [],
}) {
  return async (dispatch, getState) => {
    if (onRequest) {
      dispatch(requestData(onRequest), getState);
    }

    let data = null;
    try {
      data = await clientFunc(...params);
    } catch (error) {
      dispatch(requestFailure(onFailure, error));
      return { error };
    }

    if (Array.isArray(onSuccess)) {
      onSuccess.forEach(s => {
        dispatcher(s, data, dispatch, getState);
      });
    } else if (onSuccess) {
      dispatcher(onSuccess, data, dispatch, getState);
    }

    return { data };
  };
}

import ResearchableApiService from '@packages/fdpq-core/services/ResearchableApiService';
import { bindClientFunc } from './helpers';
import {
  GET_YEARS_REQUEST,
  GET_YEARS_SUCCESS,
  GET_MAKES_REQUEST,
  GET_MAKES_SUCCESS,
  GET_MODELS_REQUEST,
  GET_MODELS_SUCCESS,
  GET_TRIMS_REQUEST,
  GET_TRIMS_SUCCESS,
} from '../action-types/vehicles';

export const getYears = (actions, arg) => bindClientFunc({
  clientFunc: ResearchableApiService.getYears,
  onRequest: GET_YEARS_REQUEST,
  onSuccess: [actions.update, GET_YEARS_SUCCESS],
  onFailure: actions.failure,
  params: [arg],
});

export const getMakes = (actions, yearId, vehicleListingType) => bindClientFunc({
  clientFunc: ResearchableApiService.getMakes,
  onRequest: GET_MAKES_REQUEST,
  onSuccess: [ GET_MAKES_SUCCESS],
  onFailure: actions.failure, 
  params: [yearId, vehicleListingType],
});

export const getModels = (actions, makeId, yearId, vehicleListingType) => bindClientFunc({
  clientFunc: ResearchableApiService.getModels,
  onRequest: GET_MODELS_REQUEST,
  onSuccess: [actions.update, GET_MODELS_SUCCESS],
  onFailure: actions.failure,
  params: [makeId, yearId, vehicleListingType],
});

export const getTrims = (actions, makeId, yearId, modelId, vehicleListingType) => bindClientFunc({
  clientFunc: ResearchableApiService.getTrims,
  onRequest: GET_TRIMS_REQUEST,
  onSuccess: [actions.update, GET_TRIMS_SUCCESS],
  onFailure: actions.failure,
  params: [makeId, yearId, modelId, vehicleListingType],
});

export const breakpoints = [400, 768, 992, 1200];

export const mq = {
  sm: `@media (min-width: ${breakpoints[0]}px)`,
  md: `@media (min-width: ${breakpoints[2]}px)`,
  lg: `@media (min-width: ${breakpoints[3]}px)`,
};

export const widgetSize = {
  SMALL: 'SMALL',
  MEDIUM: 'MEDIUM',
  LARGE: 'LARGE',
};

export const spacing = {
  LARGE: '24px',
  SMALL: '16px'
}

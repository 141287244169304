import useDealerValidation from './useDealerValidation';

const newFlexBoxLarge = [
  ['0 0 70%', '0 0 0', '0 0 30%'],
  ['0 0 70%', '0 0 0', '0 0 30%'],
  ['0 0 40%', '0 0 30%', '0 0 30%'],
  ['0 0 50%', '0 0 20%', '0 0 30%'],
  ['0 0 50%', '0 0 20%', '0 0 30%'],
  ['0 0 60%', '0 0 10%', '0 0 30%'],
  ['0 0 65%', '0 0 5%', '0 0 30%'],
];

const newFlexBoxDesktop = [
  ['0 0 60%', '0 0 10%', '0 0 30%'],
  ['0 0 70%', '0 0 0', '0 0 30%'],
  ['0 0 30%', '0 0 40%', '0 0 30%'],
  ['0 0 35%', '0 0 35%', '0 0 30%'],
  ['0 0 45%', '0 0 25%', '0 0 30%'],
  ['0 0 45%', '0 0 25%', '0 0 30%'],
  ['0 0 50%', '0 0 20%', '0 0 30%'],
];

const updateFlexBoxConfig = (flexBoxConfig = [], newConfig = []) => flexBoxConfig = [...flexBoxConfig.slice(0, 3), ...newConfig];

export const pageLimitDefault = 3;

export const printSelectedDealerInfo = (selectedDealers = [], selectedPage) => {
  if (selectedDealers.length > 0) {
    console.log({
      currentPage: selectedPage,
      selectedDataSet: selectedDealers,
    });
  }
};

export const getDealerDisplayList = (currentPage, dealerList = [], selectedDealers = []) => {
  const filteredList = dealerList.slice((currentPage * pageLimitDefault) - pageLimitDefault, pageLimitDefault * currentPage);

  return useDealerValidation(
    filteredList,
    selectedDealers,
  );
};

export const updatePaginationAndButtonWidth = (pages, flexBoxLarge, flexBoxDesktop) => {
  if (pages <= 6) {
    updateFlexBoxConfig(flexBoxLarge, newFlexBoxLarge[pages]);
    updateFlexBoxConfig(flexBoxDesktop, newFlexBoxDesktop[pages]);
  } else {
    updateFlexBoxConfig(flexBoxLarge, newFlexBoxLarge[0]);
    updateFlexBoxConfig(flexBoxDesktop, newFlexBoxDesktop[0]);
  }
};

import {
  toTradeInVehicleSelector,
  toVehicleSelector,
  clickChangeVehicle,
  clickChangeTradeInVehicle,
} from '@packages/fdpq-core/actions/navigation';
import { VEHICLE_LISTING_TYPE } from '@packages/fdpq-core/constants/app';

export const mapStateToProps = (state) => {
  const listingType = state.app.config.listingTypeConfig.vehicleListingType;
  const vehicle = state.vehicles.selectedVehicle;
  const tradeinVehicle = state.vehicles.selectedTradeinVehicle;

  const ymm = `${vehicle.yearId} ${vehicle.makeName} ${vehicle.modelName}`;
  const vehicleTrimName = listingType === VEHICLE_LISTING_TYPE.TRADEIN ? '' : vehicle.trimName || '';
  const tradeInYmm = tradeinVehicle ? `${tradeinVehicle.yearId} ${tradeinVehicle.makeName} ${tradeinVehicle.modelName}` : '';

  return {
    ymm,
    vehicleTrimName,
    tradeInYmm,
    listingType,
  };
};

export const mapDispatchToProps = {
  onClickChangeTradeInVehicleLink: clickChangeTradeInVehicle,
  onClickChangeVehicleLink: clickChangeVehicle,
  onChangeTradeInVehicleSelected: toTradeInVehicleSelector,
  onChangeVehicleSelected: toVehicleSelector,
};
import { useState, useEffect } from 'react';

const usePaginationProvider = ({ dataList, pageLimit = 0, onPageChanged }) => {
  const dataListArray = Object.keys(dataList).reduce((acc, key) => {
    acc.push(dataList[key]);
    return acc;
  }, []);
  const [ displayList, setDisplayItems ] = useState(dataListArray.slice(0, pageLimit));
  useEffect(() => {
    setDisplayItems(dataListArray.slice(0, pageLimit));
  }, [dataList.length]);
  return {
    displayList,
    dataList: dataListArray,
    pageLimit,
    onPageChanged: (data) => {
      setDisplayItems(data.selectedDataSet);
      if(typeof onPageChanged === 'function') {
        onPageChanged(data);
      }
    }
  }
}
export default usePaginationProvider
